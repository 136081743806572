import React from "react";
import styles from "./styles.module.css";
// import BackIcon from "../../../assets/SVG/BackIcon";
import OpenIcon from "../../../assets/SVG/OpenIcon";

export default function MeetingTile({ event }) {
  const startTime = new Date(event?.when?.startTime * 1000);
  const endTime = new Date(event?.when?.endTime * 1000);

  const formattedStartTime = startTime
    .toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .replace(/\s/g, "");

  const formattedEndTime = endTime
    .toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .replace(/\s/g, "");

  console.log(event);

  return (
    <div className={styles.meeting_tile}>
      <div className={styles.meeting_information}>
        <p className={styles.meeting_title}>{event.title}</p>
        <p className={styles.meeting_datestamp}>
          {formatTimestampToReadableDate(event?.when?.startTime)}
        </p>
        <p
          className={styles.meeting_time}
        >{`${formattedStartTime}-${formattedEndTime}`}</p>
        <ActiveSwitch />
        <div className={styles.view_meeting_button}>
          <OpenIcon width={10} height={10} color="rgba(0, 0, 0, 0.7)" />
        </div>
      </div>
    </div>
  );
}

function formatTimestampToReadableDate(timestamp) {
  const date = new Date(timestamp * 1000); // Convert UNIX timestamp to milliseconds
  const options = { weekday: "long", month: "short", day: "numeric" };

  // Get the day with the appropriate suffix (st, nd, rd, th)
  const day = date.getDate();
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", options);

  // Replace the numeric day with day + suffix
  return formattedDate.replace(day, `${day}${suffix}`);
}

function ActiveSwitch({ meeting }) {
  return (
    <div className={styles.checkbox_wrapper_35}>
      <input
        value="private"
        name="switch"
        id="switch"
        type="checkbox"
        className={styles.switch}
      />
      <label htmlFor="switch">
        <span className={styles.switch_x_text}>Invite Archer: </span>
        <span className={styles.switch_x_toggletext}>
          <span className={styles.switch_x_unchecked}>
            <span className={styles.switch_x_hiddenlabel}>Unchecked: </span>No
          </span>
          <span className={styles.switch_x_checked}>
            <span className={styles.switch_x_hiddenlabel}>Checked: </span>Yes
          </span>
        </span>
      </label>
    </div>
  );
}
