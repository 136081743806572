import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./styles.module.css";
import GlobalDatabaseFilters from "./ProspectorFilters";
import contacts_data from "../../../data/contacts_data";
import ListTile from "../Lists/ListTile";
import ListTileLarge from "../Lists/ListTileLarge";
import BackIcon from "../../../assets/SVG/BackIcon";
import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";


export default function Prospector() {
  const tileView = true;

  const quickstartPlaceholder =
    "Example: Find me a list of B2B Prospects in Tech";

  return (
    <div className={styles.main_layout}>
      {/* */}
      <div className={styles.page_layout}>
        <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
        <div className={styles.page_display}>
          <GlobalDatabaseFilters />
          <div className={styles.main_header_layout}>
            <div className={styles.top_header_controls}>
              <div>
                <p className={styles.accounts_found_number}>
                  43.4k people found
                </p>
                <div className={styles.header_buttons}>
                  <div className={styles.header_button}>Create Campaign</div>
                  <div className={styles.header_button}>Export All</div>
                  <div className={styles.header_button}>Save to List</div>
                </div>
              </div>

              <div className={styles.pagination_layout}>
                <div className={styles.arrow_controls}>
                  <div className={styles.arrow}>
                    <BackIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
                  </div>
                  <div
                    className={styles.arrow}
                    style={{ transform: "rotate(180deg)" }}
                  >
                    <BackIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
                  </div>
                </div>
                <p className={styles.pagination_information}>1-100 of 1000</p>
              </div>
            </div>
            <div className={styles.list}>
              {contacts_data.map((contact_item, index) => (
                <ListTileLarge contact_item={contact_item} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
