import React from "react";
import styles from "./styles.module.css";

const ArcherIcon = ({
  width = 14,
  height = 14,
  strokeWidth = 1.1,
  ...props
}) => (
  <div className={styles.animated_gradient_container}>
    <svg
      fill="none"
      height={height}
      viewBox="0 0 64 64"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      id="fi_16800615"
    >
      <defs>
        <linearGradient id="animatedGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#f79533" />
          <stop offset="14%" stopColor="#f37055" />
          <stop offset="28%" stopColor="#ef4e7b" />
          <stop offset="42%" stopColor="#a166ab" />
          <stop offset="57%" stopColor="#5073b8" />
          <stop offset="71%" stopColor="#1098ad" />
          <stop offset="85%" stopColor="#07b39b" />
          <stop offset="100%" stopColor="#6fba82" />
          <animate
            attributeName="x1"
            values="0%;100%;0%"
            dur="3s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="x2"
            values="100%;0%;100%"
            dur="3s"
            repeatCount="indefinite"
          />
        </linearGradient>
      </defs>
      <g fill="url(#animatedGradient)">
        <path
          clipRule="evenodd"
          d="m49.9502 3.55654c-.207-.91047-1.0165-1.55654-1.9502-1.55654s-1.7432.64607-1.9502 1.55654l-1.1066 4.8664c-.5118 2.25086-2.2694 4.00846-4.5203 4.52026l-4.8664 1.1066c-.9104.207-1.5565 1.0165-1.5565 1.9502s.6461 1.7432 1.5565 1.9502l4.8664 1.1066c2.2509.5118 4.0085 2.2694 4.5203 4.5203l1.1066 4.8664c.207.9104 1.0165 1.5565 1.9502 1.5565s1.7432-.6461 1.9502-1.5565l1.1066-4.8664c.5118-2.2509 2.2694-4.0085 4.5203-4.5203l4.8664-1.1066c.9104-.207 1.5565-1.0165 1.5565-1.9502s-.6461-1.7432-1.5565-1.9502l-4.8664-1.1066c-2.2509-.5118-4.0085-2.2694-4.5203-4.52026zm-6.3118 12.44346c1.8758-.9573 3.4043-2.4858 4.3616-4.3616.9573 1.8758 2.4858 3.4043 4.3616 4.3616-1.8758.9573-3.4043 2.4858-4.3616 4.3616-.9573-1.8758-2.4858-3.4043-4.3616-4.3616z"
          fillRule="evenodd"
        ></path>
        <path d="m22 16c1.1046 0 2 .8954 2 2v9c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-9c0-1.1046.8954-2 2-2z"></path>
        <path d="m20 60v-23c0-1.1046.8954-2 2-2s2 .8954 2 2v23c0 1.1046-.8954 2-2 2s-2-.8954-2-2z"></path>
        <path d="m4 21c1.10457 0 2 .8954 2 2v15c0 1.1046-.89543 2-2 2s-2-.8954-2-2v-15c0-1.1046.89543-2 2-2z"></path>
        <path d="m4 46c-1.10457 0-2 .8954-2 2v7c0 1.1046.89543 2 2 2s2-.8954 2-2v-7c0-1.1046-.89543-2-2-2z"></path>
        <path d="m15 27c0-1.1046-.8954-2-2-2s-2 .8954-2 2v24c0 1.1046.8954 2 2 2s2-.8954 2-2z"></path>
        <path d="m31 22c1.1046 0 2 .8954 2 2v30c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-30c0-1.1046.8954-2 2-2z"></path>
        <path d="m40 29c1.1046 0 2 .8954 2 2v16c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-16c0-1.1046.8954-2 2-2z"></path>
      </g>
    </svg>
  </div>
);

export default ArcherIcon;
