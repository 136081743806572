// Layout.js
import React, { useEffect, useState } from "react";
import styles from "./layout.module.css";
import { Sidebar } from "./Sidebar/Sidebar";
import Navbar from "./Navbar/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import main_menu from "./Sidebar/main_menu";

const Layout = () => {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState({
    name: "",
    icon: null,
  });

  useEffect(() => {
    const currentTab = main_menu.find(
      (item) =>
        location.pathname.startsWith(item.url) || // Check if pathname starts with item.url
        item.sub_menu?.some((sub) => location.pathname.startsWith(sub.url)) // Check if pathname starts with any sub menu url
    );

    if (currentTab) {
      const subTab = currentTab.sub_menu?.find((sub) =>
        location.pathname.startsWith(sub.url)
      );

      if (subTab) {
        setActiveTab({
          name: subTab.title,
          icon: subTab.Icon,
        });
      } else {
        setActiveTab({
          name: currentTab.title,
          icon: currentTab.Icon,
        });
      }
    }
  }, [location]);

  return (
    <div className={styles.layout_container}>
      <div className={styles.sidebar}>
        <Sidebar setActiveTab={setActiveTab} activeTab={activeTab} />
      </div>
      <div className={styles.content_container}>
        <Navbar currentPage={activeTab} />
        <main className={styles.main_content}>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
