import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBAenay6axvrmJ2pZGqkHjO6cLTnG4NQw0",
  authDomain: "ask-archer.firebaseapp.com",
  projectId: "ask-archer",
  storageBucket: "ask-archer.firebasestorage.app",
  messagingSenderId: "448887848098",
  appId: "1:448887848098:web:182a3b44a05b44a4c7abfb",
  measurementId: "G-6ZQR5DZBZX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
// const artemisDatabase = getFirestore(app, "artemis-brain");
const storage = getStorage(app);

export { app, auth, db, storage };
