import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.css";
import SliderHeaderMenu from "../../Common/SliderHeaderMenu/SliderHeaderMenu";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";
import Table from "./Table/Table";
import Pipelines from "../Pipelines/Pipelines";

import BlueprintsIcon from "assets/SVG/SliderHeaderIcons/BlueprintsIcon";
import TemplateIcon from "assets/SVG/NewSidebarIcons/TemplateIcon";
import AnalyticsIcon from "assets/SVG/SliderHeaderIcons/AnalyticsIcon";

import QuickstartBar from "components/Common/QuickstartBar/QuickstartBar";

export default function WebVisitors() {
  const navigate = useNavigate();

  const quickstartPlaceholder =
    "Example: Find website visitors most like my current acconuts";

  return (
    <div className={styles.main_layout}>
      <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <div className={styles.page_layout}>
        <div className={styles.table_layout}>
          <Table
            columns={columns}
            data={data}
            statusColorMap={statusColorMap}
          />
        </div>
      </div>
    </div>
  );
}

const columns = [
  { name: "Company", uid: "company" },
  { name: "Employee Range", uid: "employee_range" },
  { name: "Revenue", uid: "revenue" },
  { name: "Buying Intent", uid: "buying_intent" },
];

const data = [
  {
    id: 1,
    company: "NDA Corp",
    employee_range: "10-50",
    revenue: "$1M - $5M",
    buying_intent: "High",
  },
  {
    id: 2,
    company: "LeaseWorks",
    employee_range: "51-200",
    revenue: "$10M - $50M",
    buying_intent: "Medium",
  },
  {
    id: 3,
    company: "W2 Solutions",
    employee_range: "201-500",
    revenue: "$50M - $100M",
    buying_intent: "Low",
  },
  {
    id: 4,
    company: "Consulting Pros",
    employee_range: "10-50",
    revenue: "$500K - $1M",
    buying_intent: "High",
  },
  {
    id: 5,
    company: "Shareholders United",
    employee_range: "501-1000",
    revenue: "$100M+",
    buying_intent: "Medium",
  },
  {
    id: 6,
    company: "Equity Partners",
    employee_range: "1-10",
    revenue: "$100K - $500K",
    buying_intent: "Low",
  },
];

const statusColorMap = {
  Pending: {
    background: "rgba(204, 153, 0, 0.1)", // Dark yellow background at 0.1 opacity
    text: "rgba(204, 153, 0, 1)", // Dark yellow text at full opacity
  },
  Signed: {
    background: "rgba(0, 77, 36, 0.1)", // Dark green background at 0.1 opacity
    text: "rgba(0, 77, 36, 1)", // Dark green text at full opacity
  },
  Expired: {
    background: "rgba(200, 0, 0, 0.1)", // Red background at 0.1 opacity
    text: "rgba(200, 0, 0, 1)", // Red text at full opacity
  },
};
