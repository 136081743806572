// Navbar.js
import React from "react";
import styles from "./navbar.module.css";

import CommentsIcon from "../../../assets/SVG/NewSidebarIcons/CommentsIcon";
import NotificationsIcon from "../../../assets/SVG/NewSidebarIcons/NotificationsIcon";
import HelpIcon from "../../../assets/SVG/NewSidebarIcons/HelpIcon";

// import ThemeSwitch from "../../components/Portal/UniversalComponents/ThemeSwitch/ThemeSwitch";
import { getCSSVariable } from "../../../utils/getCSSVariable";

export default function Navbar({ currentPage }) {
  return (
    <div className={styles.portal_navbar}>
      <div className={styles.portal_navbar_left}>
        {currentPage.icon && (
          <currentPage.icon
            width={12}
            height={15}
            color={getCSSVariable("--icon-color")}
          />
        )}
        <p className={styles.portal_current_page_text}>{currentPage.name}</p>
      </div>
      <div className={styles.portal_navbar_right}>
        {/* <ThemeSwitch /> */}
        <div className={styles.navbar_button}>
          <CommentsIcon
            width={15}
            height={15}
            color={getCSSVariable("--icon-color")}
          />
        </div>
        <div className={styles.navbar_button}>
          <NotificationsIcon
            width={15}
            height={15}
            color={getCSSVariable("--icon-color")}
          />
        </div>
        <div className={styles.navbar_button}>
          <HelpIcon
            width={15}
            height={15}
            color={getCSSVariable("--icon-color")}
          />
        </div>
      </div>
    </div>
  );
}
