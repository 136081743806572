export const meetings_data = [
  {
    id: "1",
    title: "Justin & Thomas",
    subtitle: "3:00pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "Demo Call with Ashley",
    subtitle: "3:00pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "30 min Recruitment Call with James",
    subtitle: "3:00pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "Justin & Katilin",
    subtitle: "3:00pm - 4:30pm",
    icon: "i",
    complete: false,
  },
  {
    id: "1",
    title: "30 min Recruitment Call with Bruce",
    subtitle: "3:00pm - 4:30pm",
    icon: "i",
    complete: false,
  },
];
