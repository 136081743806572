import React from "react";

const ProductivityIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_6796157"
    width={width}
    height={height}
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 2"
  >
    <path d="m33 22.07s0 0 0-.07v-11h3a1 1 0 0 0 .75-1.66l-7-8a1 1 0 0 0 -1.5 0l-7 8a1 1 0 0 0 .75 1.66h3v11.08a8.09 8.09 0 0 0 -3 3.06v-11.14a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v16h-3v-10a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v10h-1a1 1 0 0 0 0 2h19.59a8 8 0 1 0 11.41-9.93zm-7-13.07h-1.8l4.8-5.48 4.8 5.48h-1.8a1 1 0 0 0 -1 1v11.26a7.82 7.82 0 0 0 -4 0v-11.26a1 1 0 0 0 -1-1zm-10 6h4v15h-4zm-11 6h4v9h-4zm24 14a6 6 0 1 1 6-6 6 6 0 0 1 -6 6z"></path>
    <path d="m32 28h-2v-3a1 1 0 0 0 -2 0v4a1 1 0 0 0 1 1h3a1 1 0 0 0 0-2z"></path>
  </svg>
);

export default ProductivityIcon;
