import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.css";
import SliderHeaderMenu from "../../Common/SliderHeaderMenu/SliderHeaderMenu";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";
import Table from "./Table/Table";
import Pipelines from "../Pipelines/Pipelines";

import BlueprintsIcon from "assets/SVG/SliderHeaderIcons/BlueprintsIcon";
import TemplateIcon from "assets/SVG/NewSidebarIcons/TemplateIcon";
import AnalyticsIcon from "assets/SVG/SliderHeaderIcons/AnalyticsIcon";

import QuickstartBar from "components/Common/QuickstartBar/QuickstartBar";
import AddIcon from "assets/SVG/AddIcon";
import TicketsIcon from "assets/SVG/NewSidebarIcons/TicketsIcon";
import AccountsIcon from "assets/SVG/NewSidebarIcons/AccountsIcon";
import PipelinesIcon from "assets/SVG/NewSidebarIcons/PipelinesIcon";
import ContactsIcon from "assets/SVG/NewSidebarIcons/ContactsIcon";

export default function Accounts() {
  const navigate = useNavigate();

  const menuItems = [
    {
      icon: <ContactsIcon width={12} height={12} />,
      label: "Donors",
    },
    {
      icon: <AccountsIcon width={12} height={12} />,
      label: "Donations",
    },
    {
      icon: <ContactsIcon width={12} height={12} />,
      label: "Fivers",
    },
    {
      icon: <ContactsIcon width={12} height={12} />,
      label: "YOD Participants",
    },
    // {
    //   icon: <PipelinesIcon width={12} height={12} />,
    //   label: "Deals",
    // },
    // {
    //   icon: <TicketsIcon width={12} height={12} />,
    //   label: "Tickets",
    // },
    // {
    //   icon: <AddIcon width={12} height={12} color="rgba(0, 0, 0, 1)" />,
    //   label: "Add Record Type",
    // },
    // {
    //   icon: <AnalyticsIcon width={12} height={12} />,
    //   label: "Analytics",
    // },
  ];

  const [activeTab, setActiveTab] = useState(menuItems[0]);

  const renderTabContent = () => {
    switch (activeTab.label) {
      case "Deals":
        return <Deals />;
      case "Pipelines":
        return <Pipelines />;
      case "Analytics":
        return <div>Analytics content goes here.</div>; // Replace with the Analytics component
      default:
        return null;
    }
  };

  const quickstartPlaceholder =
    "Example: Find my accounts with the highest LTV";

  return (
    <div className={styles.main_layout}>
      <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {/* <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} /> */}
      {/* <div className={styles.tab_layout}>{renderTabContent()}</div> */}
      <Deals />
    </div>
  );
}

function Deals() {
  return (
    <>
      <ToolbarExposed feature="Record" />
      <div className={styles.page_layout}>
        <div className={styles.table_layout}>
          <Table
            columns={columns}
            data={data}
            statusColorMap={statusColorMap}
          />
        </div>
      </div>
    </>
  );
}

const columns = [
  { name: "Person", uid: "person" },
  { name: "Job Title", uid: "job_title" },
  { name: "Last Contact", uid: "last_contact" },
  { name: "Status", uid: "status" },
  { name: "Industry", uid: "industry" },
  { name: "Buying Intent", uid: "buying_intent" },
  { name: "Lead Source", uid: "lead_source" },
  { name: "Contact Owner", uid: "contact_owner" },
];

const data = [
  {
    id: 1,
    person: "john.doe@uber.com",
    job_title: "Manager",
    last_contact: "2024-11-20",
    status: "Active",
    industry: "Transport",
    buying_intent: "High",
    lead_source: "Referral",
    contact_owner: "John Doe",
  },
  {
    id: 2,
    person: "jane.smith@openai.com",
    job_title: "AI Researcher",
    last_contact: "2024-11-18",
    status: "Prospect",
    industry: "AI Research",
    buying_intent: "Medium",
    lead_source: "Website",
    contact_owner: "Jane Smith",
  },
  {
    id: 3,
    person: "Mark Johnson",
    job_title: "Marketing Specialist",
    last_contact: "2024-11-15",
    status: "Lead",
    industry: "Marketing",
    buying_intent: "Low",
    lead_source: "LinkedIn",
    contact_owner: "Mark Johnson",
  },
  {
    id: 4,
    person: "sarah.lee@peyton.com",
    job_title: "Tech Lead",
    last_contact: "2024-11-22",
    status: "Active",
    industry: "Tech",
    buying_intent: "High",
    lead_source: "Event",
    contact_owner: "Sarah Lee",
  },
  {
    id: 5,
    person: "Mike Brown",
    job_title: "Music Producer",
    last_contact: "2024-11-19",
    status: "Active",
    industry: "Music",
    buying_intent: "Medium",
    lead_source: "Ad Campaign",
    contact_owner: "Mike Brown",
  },
  {
    id: 6,
    person: "emma.davis@buffer.com",
    job_title: "Content Strategist",
    last_contact: "2024-11-17",
    status: "Inactive",
    industry: "Social Media",
    buying_intent: "Low",
    lead_source: "Cold Outreach",
    contact_owner: "Emma Davis",
  },
  {
    id: 3,
    person: "Mark Johnson",
    job_title: "Marketing Specialist",
    last_contact: "2024-11-15",
    status: "Lead",
    industry: "Marketing",
    buying_intent: "Low",
    lead_source: "LinkedIn",
    contact_owner: "Mark Johnson",
  },
  {
    id: 4,
    person: "sarah.lee@peyton.com",
    job_title: "Tech Lead",
    last_contact: "2024-11-22",
    status: "Active",
    industry: "Tech",
    buying_intent: "High",
    lead_source: "Event",
    contact_owner: "Sarah Lee",
  },
  {
    id: 5,
    person: "Mike Brown",
    job_title: "Music Producer",
    last_contact: "2024-11-19",
    status: "Active",
    industry: "Music",
    buying_intent: "Medium",
    lead_source: "Ad Campaign",
    contact_owner: "Mike Brown",
  },
  {
    id: 6,
    person: "emma.davis@buffer.com",
    job_title: "Content Strategist",
    last_contact: "2024-11-17",
    status: "Inactive",
    industry: "Social Media",
    buying_intent: "Low",
    lead_source: "Cold Outreach",
    contact_owner: "Emma Davis",
  },
  {
    id: 5,
    person: "Mike Brown",
    job_title: "Music Producer",
    last_contact: "2024-11-19",
    status: "Active",
    industry: "Music",
    buying_intent: "Medium",
    lead_source: "Ad Campaign",
    contact_owner: "Mike Brown",
  },
];

const statusColorMap = {
  Pending: {
    background: "rgba(204, 153, 0, 0.1)", // Dark yellow background at 0.1 opacity
    text: "rgba(204, 153, 0, 1)", // Dark yellow text at full opacity
  },
  Signed: {
    background: "rgba(0, 77, 36, 0.1)", // Dark green background at 0.1 opacity
    text: "rgba(0, 77, 36, 1)", // Dark green text at full opacity
  },
  Expired: {
    background: "rgba(200, 0, 0, 0.1)", // Red background at 0.1 opacity
    text: "rgba(200, 0, 0, 1)", // Red text at full opacity
  },
};
