import React from "react";

const SchedulingLinkIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_1436743"
  >
    <path d="m448.800781 257.066406h-3.53125c1.632813-10.953125 2.464844-22.0625 2.464844-33.199218 0-123.441407-100.425781-223.867188-223.867187-223.867188-123.441407 0-223.867188 100.425781-223.867188 223.867188 0 123.4375 100.425781 223.867187 223.867188 223.867187 11.136718 0 22.246093-.832031 33.199218-2.464844v51.730469c0 7.972656 6.425782 15 15 15 2.910156 0 5.8125-.847656 8.320313-2.519531l44.421875-29.613281h123.992187c34.847657 0 63.199219-28.351563 63.199219-63.199219v-96.402344c0-34.847656-28.351562-63.199219-63.199219-63.199219zm-224.933593 160.667969c-106.898438 0-193.867188-86.96875-193.867188-193.867187 0-106.898438 86.96875-193.867188 193.867188-193.867188 106.898437 0 193.867187 86.96875 193.867187 193.867188 0 11.164062-.960937 22.285156-2.84375 33.199218h-94.625c-34.90625 0-63.199219 28.242188-63.199219 63.199219v94.625c-10.914062 1.882813-22.035156 2.84375-33.199218 2.84375zm258.132812-1.066406c0 18.304687-14.894531 33.199219-33.199219 33.199219h-128.535156c-2.960937 0-5.855469.875-8.320313 2.519531l-24.878906 16.585937v-148.707031c0-18.347656 14.839844-33.199219 33.199219-33.199219h128.535156c18.304688 0 33.199219 14.894532 33.199219 33.199219zm0 0"></path>
    <path d="m432.734375 321.332031h-96.402344c-8.28125 0-15 6.71875-15 15 0 8.285157 6.71875 15 15 15h96.402344c8.28125 0 15-6.714843 15-15 0-8.28125-6.714844-15-15-15zm0 0"></path>
    <path d="m384.53125 385.601562h-48.199219c-8.28125 0-15 6.714844-15 15 0 8.28125 6.71875 15 15 15h48.199219c8.285156 0 15-6.71875 15-15 0-8.285156-6.714844-15-15-15zm0 0"></path>
    <path d="m223.867188 64.265625c-8.285157 0-15 6.71875-15 15v129.601563h-81.398438c-8.285156 0-15 6.714843-15 15 0 8.285156 6.714844 15 15 15h96.398438c8.28125 0 15-6.714844 15-15v-144.601563c0-8.285156-6.71875-15-15-15zm0 0"></path>
  </svg>
);

export default SchedulingLinkIcon;
