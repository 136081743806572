import React from "react";

const AskArcherIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 64 64"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_16800615"
  >
    <g fill={color}>
      <path
        fill={color}
        clipRule="evenodd"
        d="m49.9502 3.55654c-.207-.91047-1.0165-1.55654-1.9502-1.55654s-1.7432.64607-1.9502 1.55654l-1.1066 4.8664c-.5118 2.25086-2.2694 4.00846-4.5203 4.52026l-4.8664 1.1066c-.9104.207-1.5565 1.0165-1.5565 1.9502s.6461 1.7432 1.5565 1.9502l4.8664 1.1066c2.2509.5118 4.0085 2.2694 4.5203 4.5203l1.1066 4.8664c.207.9104 1.0165 1.5565 1.9502 1.5565s1.7432-.6461 1.9502-1.5565l1.1066-4.8664c.5118-2.2509 2.2694-4.0085 4.5203-4.5203l4.8664-1.1066c.9104-.207 1.5565-1.0165 1.5565-1.9502s-.6461-1.7432-1.5565-1.9502l-4.8664-1.1066c-2.2509-.5118-4.0085-2.2694-4.5203-4.52026zm-6.3118 12.44346c1.8758-.9573 3.4043-2.4858 4.3616-4.3616.9573 1.8758 2.4858 3.4043 4.3616 4.3616-1.8758.9573-3.4043 2.4858-4.3616 4.3616-.9573-1.8758-2.4858-3.4043-4.3616-4.3616z"
        fillRule="evenodd"
      ></path>
      <path
        fill={color}
        d="m22 16c1.1046 0 2 .8954 2 2v9c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-9c0-1.1046.8954-2 2-2z"
      ></path>
      <path
        fill={color}
        d="m20 60v-23c0-1.1046.8954-2 2-2s2 .8954 2 2v23c0 1.1046-.8954 2-2 2s-2-.8954-2-2z"
      ></path>
      <path
        fill={color}
        d="m4 21c1.10457 0 2 .8954 2 2v15c0 1.1046-.89543 2-2 2s-2-.8954-2-2v-15c0-1.1046.89543-2 2-2z"
      ></path>
      <path
        fill={color}
        d="m4 46c-1.10457 0-2 .8954-2 2v7c0 1.1046.89543 2 2 2s2-.8954 2-2v-7c0-1.1046-.89543-2-2-2z"
      ></path>
      <path
        fill={color}
        d="m15 27c0-1.1046-.8954-2-2-2s-2 .8954-2 2v24c0 1.1046.8954 2 2 2s2-.8954 2-2z"
      ></path>
      <path
        fill={color}
        d="m31 22c1.1046 0 2 .8954 2 2v30c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-30c0-1.1046.8954-2 2-2z"
      ></path>
      <path
        fill={color}
        d="m40 29c1.1046 0 2 .8954 2 2v16c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-16c0-1.1046.8954-2 2-2z"
      ></path>
    </g>
  </svg>
);

export default AskArcherIcon;
