import React from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

import { useNavigate } from "react-router-dom";

export default function Tickets() {
  const navigate = useNavigate();

  function handleNewEditor() {
    console.log("Navigating to funnel editor");
    navigate("/whiteboard/editor");
  }

  return (
    <div className={styles.main_layout}>
      <ToolbarExposed feature="Ticket" createFunction={handleNewEditor} />
      <div className={styles.page_layout}>
        <div className={styles.tile_layout}>
          <Tile />
        </div>
      </div>
    </div>
  );
}

function Tile() {
  return (
    <div className={styles.tile}>
      <div className={styles.tile_header}>
        <p className={styles.tile_title}>#05242 - Development Issue</p>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body}>
        {/* <p className={styles.tile_subject}>Justin x LongJump VC</p> */}
        <p className={styles.tile_preview}>
          Sign out button not working correctly on user account
        </p>
      </div>
      <div className={styles.tile_footer}>
        <AccountTag />
        <p className={styles.last_updated}>Updated Today</p>
      </div>
    </div>
  );
}
