import React from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";
import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";
import CreateTemplate from "../../Modals/CreateTemplate/CreateTemplate";

export default function Templates() {
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const openCreateTemplateModal = (task = null) => {
    console.log(task);
    setSelectedTemplate(task);
    setIsCreateTemplateOpen(true);
  };

  const closeCreateTemplateModal = () => {
    setIsCreateTemplateOpen(false);
    setSelectedTemplate(null);
  };

  const quickstartPlaceholder =
    "Example: Create an new email template for a sales outreach";
  return (
    <div className={styles.main_layout}>
      <CreateTemplate
        isOpen={isCreateTemplateOpen}
        onClose={closeCreateTemplateModal}
        task={selectedTemplate}
      />
      <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <ToolbarExposed
        feature="Template"
        createFunction={() => openCreateTemplateModal()}
      />
      <div className={styles.page_layout}>
        <div className={styles.tile_layout}>
          <Tile />
        </div>
      </div>
    </div>
  );
}

function Tile() {
  return (
    <div className={styles.tile}>
      <div className={styles.tile_header}>
        <p className={styles.tile_title}>Venture Capital Intro's</p>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body}>
        <p className={styles.tile_subject}>Justin x LongJump VC</p>
        <p className={styles.tile_preview}>
          Hey [First Name], I'm thrilled about the opportunity to connect. Let's
          explore potential synergies between Archer and LongJump VC. Looking
          forward to discussing how we can bring value together! Let's stay in
          touch and talk soon!
        </p>
      </div>
      <div className={styles.tile_footer}>
        <AccountTag />
        <p className={styles.last_updated}>Updated Today</p>
      </div>
    </div>
  );
}
