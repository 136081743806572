import React, { useState, useEffect, useRef } from "react";
import { Calendar } from "@nextui-org/calendar";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/popover";
import styles from "./styles.module.css";
import { useTasksContext } from "../../../context/TasksContext";
import { useAccountContext } from "../../../context/AccountContext";

import { parseDate, toCalendarDate } from "@internationalized/date";

// Define the utility function before using it
const convertToParseDate = (isoDate) => {
  if (!isoDate) return null;
  const dateString = isoDate.split("T")[0];
  return parseDate(dateString);
};

const getTomorrowDateValue = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const isoDate = tomorrow.toISOString().split("T")[0];
  return parseDate(isoDate);
};

const CreateTask = ({ isOpen, onClose, task }) => {
  const [taskName, setTaskName] = useState(task?.title || "");
  const [date, setDate] = useState(
    task?.due_date ? convertToParseDate(task.due_date) : getTomorrowDateValue()
  );
  const [isCalendarOpen, setCalendarOpen] = useState(false);

  const { createTask, updateTask } = useTasksContext();
  const { profile } = useAccountContext();

  const modalRef = useRef(null);

  useEffect(() => {
    if (task) {
      setTaskName(task.title || "");
      setDate(task.due_date ? convertToParseDate(task.due_date) : null);
    } else {
      setTaskName("");
      setDate(getTomorrowDateValue());
    }
  }, [task]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleSubmit = async () => {
    if (!taskName.trim()) {
      alert("Task name is required");
      return;
    }

    const payload = {
      title: taskName,
      due_date: date ? new Date(date.toString()).toISOString() : null,
      record: "",
      assigned: "",
      created_by: profile.account._id,
    };

    try {
      if (task) {
        await updateTask({ id: task._id, updatedFields: payload });
      } else {
        await createTask(payload);
      }
      setTaskName("");
      setDate(getTomorrowDateValue());
      onClose();
    } catch (error) {
      console.error("Failed to save task:", error);
      onClose();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const formatDate = (dateValue) => {
    if (!dateValue) return "Set Due Date";
    const jsDate = new Date(dateValue.toString());
    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      dateStyle: "medium",
    });
    return formatter.format(jsDate);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal} ref={modalRef}>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "5px",
            width: "100%",
            height: "100%",
            boxShadow: "0 0px 4px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ display: "flex", padding: "20px" }}>
            <p className={styles.modal_title}>
              {task ? "Edit Task" : "Create New Task"}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              padding: "20px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            <input
              className={styles.input}
              placeholder="Enter task name"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <Popover
              isOpen={isCalendarOpen}
              onOpenChange={() => setCalendarOpen(!isCalendarOpen)}
              classNames={{
                content: styles.customPopoverContent,
              }}
            >
              <PopoverTrigger>
                <div className={styles.button}>
                  {date ? formatDate(date) : "Set Due Date"}
                </div>
              </PopoverTrigger>
              <PopoverContent>
                <Calendar
                  value={date}
                  onChange={(selectedDate) => {
                    handleDateChange(selectedDate);
                    setCalendarOpen(false);
                  }}
                  visibleMonths={1}
                  selectionMode="single"
                />
              </PopoverContent>
            </Popover>
            <div className={styles.footer_layout_right}>
              <div className={styles.button} onClick={onClose}>
                Cancel
              </div>
              <div className={styles.button} onClick={handleSubmit}>
                {task ? "Update Task" : "Create Task"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTask;
