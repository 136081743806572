import React from "react";

const ClearIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox="0 0 97 96"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_9742093"
  >
    <g fill="rgb(0,0,0)">
      <path d="m22.5205 37.9006c2.0803 0 3.7667-1.6864 3.7667-3.7667 0-2.0802-1.6864-3.7666-3.7667-3.7666-2.0802 0-3.7666 1.6864-3.7666 3.7666 0 2.0803 1.6864 3.7667 3.7666 3.7667z"></path>
      <path d="m12.2666 49.4788c2.0803 0 3.7667-1.6864 3.7667-3.7667 0-2.0802-1.6864-3.7666-3.7667-3.7666-2.0802 0-3.7666 1.6864-3.7666 3.7666 0 2.0803 1.6864 3.7667 3.7666 3.7667z"></path>
      <path
        clip-rule="evenodd"
        d="m77.4111 9.42852c-4.2634-2.2083-9.5098-.54229-11.7181 3.72108l-9.645 18.621c-.0517.0998-.0963.2015-.134.3043-4.7244-.162-9.4044 1.925-12.5607 5.619-3.7188 4.3522-8.294 9.1017-12.2036 11.527-5.5739 3.4578-10.1819 4.704-13.0435 5.1463-1.6736.2587-3.032 1.3362-3.6937 2.7335-.6912 1.4595-.6391 3.3721.7041 4.8522 2.92 3.2177 8.613 8.4884 19.055 15.1294 7.2324 4.5999 13.7166 7.1439 17.2064 8.3225 2.6745.9034 5.5327-.1297 7.0673-2.4281 1.9401-2.9057 5.3476-8.3855 8.2732-15.0533 2.1644-4.9329 4.0289-10.017 5.8134-15.0925 1.5054-4.2815.8283-8.7979-1.4604-12.3676l10.0516-19.2993c2.2215-4.2652.5582-9.5237-3.712-11.73548zm-10.1304 27.05608 9.2305-17.7227c.8927-1.714.2243-3.8272-1.4918-4.716-1.7133-.8874-3.8216-.2179-4.709 1.4954l-9.1625 17.6893c.3068.1302.6112.2716.9129.4246l3.3481 1.6975c.6593.3343 1.2841.7134 1.8718 1.1319zm.3456 14.6215c-.3705 1.0537-.761 2.1497-1.1659 3.2657l-21.1015-11.0736c.6793-.7574 1.331-1.5045 1.9477-2.2263 3.1408-3.6757 8.296-4.861 12.4027-2.7788l3.348 1.6976c4.125 2.0914 6.0755 6.8307 4.569 11.1154zm-33.7354 2.5336c2.5658-1.5916 5.2432-3.9177 7.7555-6.4175l.0337.018 22.9484 12.0428c-.8829 2.2953-1.7899 4.5411-2.6718 6.5511-2.7626 6.2962-5.9966 11.5003-7.836 14.2551-.2621.3925-.6879.5211-1.0788.389-1.5347-.5183-3.7274-1.3338-6.3412-2.5167 1.2132-1.2791 2.9729-3.3701 4.6592-6.2433.7287-1.2415.3076-2.8174-.9406-3.52s-2.8508-.2657-3.5794.9758c-1.3555 2.3092-3.0111 4.5839-5.0411 6.3582-1.5482-.8356-3.1711-1.7783-4.8366-2.8376-1.3542-.8613-2.6224-1.6965-3.8095-2.504.6177-.4154 1.295-.9247 2.0221-1.5483 1.0936-.9378 1.2022-2.5589.2426-3.6209s-2.6239-1.1626-3.7174-.2248c-1.6095 1.3803-2.7335 1.9056-3.1866 2.0827-4.1402-3.1108-6.9141-5.6648-8.7093-7.5271 3.478-.647 8.391-2.1791 14.0868-5.7125z"
        fill-rule="evenodd"
      ></path>
    </g>
  </svg>
);

export default ClearIcon;
