import React from "react";
import styles from "./page.module.css";
import BackIcon from "../../../assets/SVG/BackIcon";

function PageControl({ handlePreviousPage, handleNextPage }) {
  return (
    <div className={styles.page_controls}>
      <div className={styles.page_control} onClick={handlePreviousPage}>
        <BackIcon width={13} height={13} color="rgba(0, 88, 220, 0.85)" />
        Prev Page
      </div>
      <div className={styles.page_control} onClick={handleNextPage}>
        Next Page
        <div style={{ transform: "rotate(180deg) translateY(1px)" }}>
          <BackIcon width={13} height={13} color="rgba(0, 88, 220, 0.85)" />
        </div>
      </div>
    </div>
  );
}

export default PageControl;
