import React from "react";

const MoreIconVertical = ({
  width = 12,
  height = 12,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    version="1.1"
    id="fi_512222"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 384 384"
  >
    <g>
      <g>
        <circle fill={color} cx="192" cy="42.667" r="42.667"></circle>
      </g>
    </g>
    <g>
      <g>
        <circle fill={color} cx="192" cy="192" r="42.667"></circle>
      </g>
    </g>
    <g>
      <g>
        <circle fill={color} cx="192" cy="341.333" r="42.667"></circle>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default MoreIconVertical;
