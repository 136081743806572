import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./socials.module.css";
import { AuthContext } from "../../../../../context/AuthContext";
import axios from "axios";

// import LinkMailboxPopUp from "../../../../PopUpComponents/LinkMailboxPopup/LinkMailboxPopUp";

export default function SocialSettings() {
  const { profile } = useContext(AuthContext);

  const [linkingMailbox, setLinkingMailbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New loading state

  const social_options = [
    {
      id: "INSTAGRAM",
      title: "Instagram",
      subtitle: "Hotmail, Live, MSN",
      icon: "/SocialAccounts/instagram.png",
    },
    // {
    //   id: "LINKEDIN",
    //   title: "Linkedin",
    //   subtitle: "Gmail, GSuite, Workspace",
    //   icon: "/SocialAccounts/linkedin.png",
    // },
    {
      id: "TWITTER",
      title: "Twitter",
      subtitle: "Any Provider, IMAP",
      icon: "/SocialAccounts/twitter.png",
    },
    // {
    //   id: "MESSENGER",
    //   title: "Messenger",
    //   subtitle: "Any Provider, IMAP",
    //   icon: "/EmailProviders/mail.png",
    // },
    // {
    //   id: "TELEGRAM",
    //   title: "Messenger",
    //   subtitle: "Any Provider, IMAP",
    //   icon: "/EmailProviders/mail.png",
    // },
    // {
    //   id: "WHATSAPP",
    //   title: "Messenger",
    //   subtitle: "Any Provider, IMAP",
    //   icon: "/EmailProviders/mail.png",
    // },
  ];

  function handleProviderSelect(provider) {
    connectAccount(provider);
  }

  async function connectAccount(provider) {
    setIsLoading(true);
    try {
      const baseUrl = window.location.origin;

      const response = await axios.post(
        "https://artemis--ai.uc.r.appspot.com/unipile/generate-auth-link",
        {
          userId: profile.uid,
          provider: provider,
          baseUrl: baseUrl,
        }
      );

      const data = response.data;
      console.log(data);
      if (response.status === 200) {
        window.location.href = data.url;
      } else {
        alert("Failed to connect account");
      }
    } catch (error) {
      console.error("Error connecting account:", error);
      alert("Error connecting account. Please try again.");
    }
    setIsLoading(false);
  }

  return (
    <div className={styles.main_layout}>
      {social_options.map((option) => (
        <div className={styles.social_account}>
          <img
            className={styles.social_icon}
            src={option.icon}
            alt={`${option.title} icon`}
          />
          <p className={styles.social_title}>{option.title}</p>
          <div className={styles.right_side}>
            <div
              className={styles.connect_button}
              onClick={() => connectAccount(option.id)}
            >
              Connect account
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
