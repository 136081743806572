import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "../settings.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import { toast } from "react-toastify";
// import { PhyllloContext } from "../../../../context/PhylloContext";

export default function AccountSettings(props) {
  const { updateAccountProfilePicture, updateUserProfile } =
    useContext(AuthContext);
  const [selectedProfilePicture, setSelectedProfilePicture] = useState(null);
  const fileInputRef = useRef(null);
  const { profile } = props;
  const [editProfile, setEditProfile] = useState({ ...profile });
  const [isEditModePersonal, setIsEditModePersonal] = useState(false);
  const [isEditModeCompany, setIsEditModeCompany] = useState(false);

  useEffect(() => {
    setEditProfile({ ...profile });
  }, [profile]);

  const handleEditProfileChange = (e) => {
    const { name, value } = e.target;
    setEditProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedProfilePicture(URL.createObjectURL(file));
      updateAccountProfilePicture(profile.id, file);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // function handleEditProfile() {
  //   setIsEditMode(true);
  // }

  // function handleEditProfile() {
  //   setIsEditMode(true);
  // }

  async function handleSaveProfile() {
    try {
      await updateUserProfile(profile.uid, editProfile);
      setEditProfile(editProfile);
      toast.success("Account Updated");
      setIsEditModePersonal(false);
      setIsEditModeCompany(false);
    } catch (error) {
      setIsEditModePersonal(false);
      setIsEditModeCompany(false);
      toast.error("Update Failed");
      console.error("Error saving profile:", error);
    }
  }

  return (
    <div className={styles.main_layout}>
      {profile && (
        <>
          <div className={styles.section}>
            <p className={styles.top_section_title}>Profile</p>

            <div className={styles.profile_edit_section}>
              <div className={styles.profile_top_section}>
                <div className={styles.proflie_picture_section}>
                  <div className={styles.profile_picture_bg}>
                    <img
                      alt="logo"
                      src={
                        selectedProfilePicture ||
                        profile.profilePicture ||
                        "/DefaultImage.png"
                      }
                      className={styles.profile_picture}
                    ></img>
                  </div>
                  <div className={styles.proflie_top_information}>
                    <p className={styles.proflie_top_information_text_1}>
                      {editProfile.firstName} {editProfile.lastName}
                    </p>
                    <p className={styles.proflie_top_information_text_2}>
                      {editProfile.position}
                    </p>
                    <p className={styles.proflie_top_information_text_3}>
                      {editProfile.location}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={styles.profile_pic_button}
                onClick={handleUploadButtonClick}
              >
                Change Profile Picture
                <input
                  type="file"
                  accept="image/*"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleProfilePictureChange}
                />
              </div>
              {/* {isEditMode ? (
                <div
                  className={styles.save_profile_button}
                  onClick={handleSaveProfile}
                >
                  Save
                </div>
              ) : (
                <div
                  className={styles.edit_profile_button}
                  onClick={handleEditProfile}
                >
                  Edit Profile
                </div>
              )} */}
            </div>
            <div className={styles.profile_section}>
              {isEditModePersonal ? (
                <div className={styles.save_button} onClick={handleSaveProfile}>
                  Save
                </div>
              ) : (
                <div
                  className={styles.edit_button}
                  onClick={() => setIsEditModePersonal(true)}
                >
                  Edit Profile
                </div>
              )}

              <p className={styles.information_section_title}>
                Personal Information
              </p>
              <div className={styles.profile_fields}>
                <div className={styles.profile_picture_section_left}>
                  <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>First Name</p>
                    <AccountInput
                      isEditMode={isEditModePersonal}
                      value={editProfile.firstName}
                      name="firstName"
                      onChange={handleEditProfileChange}
                    />
                  </div>
                  <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Position</p>
                    <AccountInput
                      isEditMode={isEditModePersonal}
                      value={editProfile.position}
                      name="position"
                      onChange={handleEditProfileChange}
                    />
                  </div>
                  <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Location</p>
                    <SearchLocationInput
                      isEditMode={isEditModePersonal}
                      value={editProfile.location}
                      name="location"
                      onChange={handleEditProfileChange}
                    />
                  </div>
                </div>
                <div className={styles.profile_picture_section_right}>
                  <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Last Name</p>
                    <AccountInput
                      isEditMode={isEditModePersonal}
                      value={editProfile.lastName}
                      name="lastName"
                      onChange={handleEditProfileChange}
                    />
                  </div>
                  <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Phone Number</p>
                    <AccountInput
                      isEditMode={isEditModePersonal}
                      value={editProfile.phoneNumber}
                      name="phoneNumber"
                      onChange={handleEditProfileChange}
                    />
                  </div>
                  {/* <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Company Website</p>
                    <AccountInput
                      isEditMode={isEditMode}
                      value={editProfile.companyWebsite}
                      name="companyWebsite"
                      onChange={handleEditProfileChange}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className={styles.profile_section}>
              {isEditModeCompany ? (
                <div className={styles.save_button} onClick={handleSaveProfile}>
                  Save
                </div>
              ) : (
                <div
                  className={styles.edit_button}
                  onClick={() => setIsEditModeCompany(true)}
                >
                  Edit Profile
                </div>
              )}
              <p className={styles.information_section_title}>
                Company Information
              </p>
              <div className={styles.profile_fields}>
                <div className={styles.profile_picture_section_left}>
                  <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Company Name</p>
                    <AccountInput
                      isEditMode={isEditModeCompany}
                      value={editProfile.companyName}
                      name="companyName"
                      onChange={handleEditProfileChange}
                    />
                  </div>
                  {/* <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Company Industry</p>
                    <AccountInput
                      isEditMode={isEditModeCompany}
                      value={editProfile.firstName}
                      name="firstName"
                      onChange={handleEditProfileChange}
                    />
                  </div> */}
                </div>
                <div className={styles.profile_picture_section_right}>
                  {/* <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Company Size</p>
                    <AccountInput
                      isEditMode={isEditModeCompany}
                      value={editProfile.company_size}
                      name="company_size"
                      onChange={handleEditProfileChange}
                    />
                  </div> */}

                  <div className={styles.profile_edit_field}>
                    <p className={styles.input_title}>Company Website</p>
                    <AccountInput
                      isEditMode={isEditModeCompany}
                      value={editProfile.companyWebsite}
                      name="companyWebsite"
                      onChange={handleEditProfileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div />
          </div>
        </>
      )}
    </div>
  );
}

function SearchLocationInput({ isEditMode, value, name, onChange }) {
  // const { searchCollection } = useContext(PhyllloContext);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(value);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const handleSearch = async (searchInput) => {
    // const normalizedInput = searchInput?.trim().toLowerCase();
    // if (normalizedInput === "") {
    //   setOptions([]);
    //   setIsDropdownVisible(false);
    //   return;
    // }
    // try {
    //   const values = await searchCollection(normalizedInput, "locations");
    //   setOptions(values);
    //   setIsDropdownVisible(true);
    // } catch (error) {
    //   console.error("Error during location search:", error);
    //   setOptions([]);
    // }
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      handleSearch(inputValue);
    }, 300);
    return () => clearTimeout(delayDebounce);
  }, [inputValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {isEditMode ? (
        <div className={styles.dropdown_container}>
          <input
            ref={inputRef}
            type="text"
            placeholder="Search Locations"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onFocus={() => {
              setIsDropdownVisible(true);

              inputRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }}
            className={styles.single_line_input_editable}
          />
          {isDropdownVisible && options.length > 0 && (
            <div
              ref={dropdownRef}
              className={styles.custom_dropdown}
              style={{ position: "absolute", width: "100%" }}
            >
              {options.length > 0 ? (
                options.map((option) => (
                  <div
                    key={option.id}
                    className={styles.dropdown_item}
                    onClick={() => {
                      onChange({
                        target: {
                          name: name,
                          value: option.display_name,
                        },
                      });
                      setInputValue(option.display_name);
                      setIsDropdownVisible(false);
                    }}
                  >
                    {option.display_name}
                  </div>
                ))
              ) : (
                <div className={styles.dropdown_item}>No results found</div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.single_line_input}>{value || "---"}</div>
      )}
    </>
  );
}

function AccountInput({ isEditMode, value, name, onChange }) {
  const handleInput = (e) => {
    if (name === "phoneNumber") {
      // Allow only digits
      const nonDigits = /[^0-9]/g;
      if (nonDigits.test(e.data)) {
        e.target.value = e.target.value.replace(nonDigits, "");
      }
    }
  };

  return (
    <>
      {isEditMode ? (
        <input
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          onInput={name === "phoneNumber" ? handleInput : null}
          className={styles.single_line_input_editable}
        />
      ) : (
        <div className={styles.single_line_input}>{value || "---"}</div>
      )}
    </>
  );
}
