import React, { useState } from "react";
import styles from "./generation.module.css";
import axios from "axios";

const AdminMediaGeneration = () => {
  const [loading, setLoading] = useState(false);
  const [videoURL, setVideoURL] = useState(null);

  const handleGenerateVideo = async () => {
    setLoading(true);
    setVideoURL(null);

    try {
      // Call the backend to generate the video
      const response = await axios.get(
        // "https://media-generator-dot-artemis--ai.uc.r.appspot.com/video/generate",
        "http://localhost:8080/video/generate",
        { responseType: "blob" } // Expecting a binary response
      );

      // Create a URL for the video blob so it can be played and downloaded
      const videoBlob = new Blob([response.data], { type: "video/mp4" });
      const videoURL = URL.createObjectURL(videoBlob);
      setVideoURL(videoURL);
    } catch (error) {
      console.error("Error generating video:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Media Generation</p>
      <div className={styles.enter_phrase}>
        <button
          className={`${styles.embed_button} ${
            loading ? styles.disabled_button : ""
          }`}
          onClick={!loading ? handleGenerateVideo : undefined}
          disabled={loading}
        >
          {loading ? "Loading..." : "Create Video"}
        </button>
      </div>

      <div className={styles.embed_result}>
        {videoURL && (
          <>
            {/* Video player */}
            <video controls className={styles.video_player}>
              <source src={videoURL} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {/* Download link */}
            <a
              href={videoURL}
              download="generated_video.mp4"
              className={styles.download_link}
            >
              Download Video
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminMediaGeneration;
