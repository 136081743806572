import React, { useState, useContext } from "react";
import styles from "./styles.module.css";
import { EmailContext } from "../../../context/EmailContext";
import AiButton from "../../Common/AiButton/AiButton";
import ConfigurationIcon from "../../../assets/SVG/ConfigurationIcon";

export default function InboxSidebar({
  inboxFilters,
  currentInboxView,
  setCurrentInboxView,
  inboxDisplays,
  setCurrentInboxDisplays,
}) {
  const [activeSwitches, setActiveSwitches] = useState(
    inboxDisplays.map(() => true)
  );

  const handleToggle = (index) => {
    setActiveSwitches((prev) =>
      prev.map((state, idx) => (idx === index ? !state : state))
    );
  };

  return (
    <div className={styles.inboxes_sidebar}>
      {/* <div className={styles.inbox_filters_header}>
        <p className={styles.inbox_title}>Your Inboxes</p>
        <div className={styles.inbox_settings_button}>
          <ConfigurationIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
        </div>
      </div> */}
      <div className={styles.inbox_filter_content}>
        {inboxFilters.map((tab, index) => (
          <div
            key={index}
            className={
              currentInboxView.name === tab.name
                ? styles.inbox_tile_selected
                : styles.inbox_tile
            }
            onClick={() => setCurrentInboxView(tab)}
          >
            <div className={styles.inbox_type_left}>
              <div className={styles.icon_box}>
                <tab.icon
                  width={12}
                  height={12}
                  color={
                    currentInboxView.name === tab.name
                      ? "rgba(0, 0, 0, 0.9)"
                      : "rgba(0, 0, 0, 0.9)"
                  }
                />
              </div>
              <p
                className={
                  currentInboxView.name === tab.name
                    ? styles.inbox_type_text_selected
                    : styles.inbox_type_text
                }
              >
                {tab.name}
              </p>
            </div>
            <div className={styles.count_box}>{tab.count}</div>
          </div>
        ))}
      </div>
      {/* <div className={styles.inbox_channel_content}>
        <p>Show Channels</p>
        <div className={styles.inbox_filter_content}>
          {inboxDisplays.map((tab, index) => (
            <div
              key={index}
              className={
                currentInboxView.name === tab.name
                  ? styles.display_tile_selected
                  : styles.display_tile
              }
              onClick={() => setCurrentInboxDisplays(tab)}
            >
              <div className={styles.inbox_type_left}>
                <div className={styles.icon_box}>
                  <tab.icon
                    width={14}
                    height={14}
                    color="rgba(0, 0, 0, 0.67)"
                  />
                </div>
                <p className={styles.inbox_type_text}>{tab.name}</p>
              </div>
              <ActiveSwitch
                isActive={activeSwitches[index]}
                onToggle={() => handleToggle(index)}
                id={`switch-${index}`}
              />
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}

function ActiveSwitch({ isActive, onToggle, id }) {
  return (
    <div className={styles.checkbox_wrapper_35}>
      <input
        checked={isActive}
        onChange={onToggle}
        name={`switch-${id}`}
        id={id}
        type="checkbox"
        className={styles.switch}
      />
      <label htmlFor={id}></label>
    </div>
  );
}
