import React, { useState, useContext, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import { EmailContext } from "../../../context/EmailContext";
import AiButton from "../../Common/AiButton/AiButton";
import ConfigurationIcon from "../../../assets/SVG/ConfigurationIcon";
// import GlobalInboxMessageInput from "./GlobalInboxMessageInput";
import { getIconByName } from "../../../helpers/getIcon";
import UserIcon from "../../../assets/SVG/UserIcon copy";
import AddIcon from "../../../assets/SVG/AddIcon";

export default function TrailEmail({ selectedTrail }) {
  console.log(selectedTrail);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };
  console.log(selectedTrail);
  useEffect(scrollToBottom, [selectedTrail]);

  return (
    <div className={styles.chat_container}>
      <div className={styles.chat_layout_content_info}>
        <div className={styles.chat_header}>
          <p className={styles.subjectLine}>
            {/* Subject: {selectedTrail.emails[0].subject} */}
            Touching Base
          </p>
        </div>
        <div className={styles.chat_messages}>
          {/* {selectedTrail.emails.map((trail) => (
            <MessageComponent key={trail.id} selectedChat={trail} />
          ))} */}
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  );
}

const MessageComponent = ({ selectedChat }) => {
  const bodyRef = useRef(null);

  console.log(selectedChat.body);

  useEffect(() => {
    const fetchMail = async () => {
      const content = { body: selectedChat.body };

      if (bodyRef.current) {
        const shadowRoot = bodyRef.current.attachShadow({ mode: "open" });
        const mailBody = document.createElement("div");
        mailBody.id = "mail-body";
        mailBody.style.display = "table";
        mailBody.style.width = "100%";
        mailBody.style.position = "relative";
        mailBody.style.color = "black";
        mailBody.style.fontSize = "16px";
        mailBody.style.fontWeight = "300";
        mailBody.style.textAlign = "left";
        mailBody.style.margin = "0px";
        const normalizedContent = normalizeEmailContent(content.body);
        mailBody.innerHTML = normalizedContent;

        shadowRoot.appendChild(mailBody);
      }
    };

    fetchMail();
  }, [selectedChat]);

  return (
    <div className={styles.message_wrapper}>
      <div className={styles.message_background}>
        <div className={styles.message_header}>
          <div className={styles.account_info}>
            <div className={styles.account_image}>
              <UserIcon color="rgba(0, 88, 220)" />
            </div>
            <div className={styles.account_text}>
              <p className={styles.account_name}>
                {selectedChat.from_attendee.display_name}
              </p>
              <p className={styles.account_email}>
                {selectedChat.from_attendee.identifier}
              </p>
            </div>
          </div>
          <div className={styles.actions}>
            <div
              className={styles.action_button}
              // onClick={() => replyToEmail(selectedChat)}
            >
              {/* <Reply /> */}
              <p className={styles.action_text}>Reply</p>
            </div>
            {/* <div
                className={styles.action_button}
                onClick={() => replyToEmail(selectedChat)}
              >
                <ReplyAll />
                <p className={styles.action_text}>Reply All</p>
              </div> */}
            {/* <div className={styles.action_button}>
                <ForwardIcon />
                <p className={styles.action_text}>Forward</p>
              </div> */}
          </div>
        </div>
        <div className={styles.message_divider}></div>
        <div ref={bodyRef} />

        {/* <div className={styles.message_divider}></div> */}
        <p className={styles.time_stamp_email}>
          {formatIsoDateToDate(selectedChat.date)}
        </p>
      </div>
    </div>
  );
};

function normalizeEmailContent(htmlContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  // Function to remove elements with specific classes and the div after divRplyFwdMsg
  function removeSpecificElements() {
    const divRplyFwdMsg = doc.querySelector("#divRplyFwdMsg");
    if (divRplyFwdMsg) {
      let nextDiv = divRplyFwdMsg.nextElementSibling;
      if (nextDiv && nextDiv.tagName === "DIV" && !nextDiv.id) {
        nextDiv.remove();
      }
    }

    doc.querySelectorAll(".gmail_attr, .gmail_quote").forEach((el) => {
      el.parentNode.removeChild(el);
    });

    doc
      .querySelectorAll(
        "script, style, link, #divRplyFwdMsg, #appendonsend, hr"
      )
      .forEach((el) => {
        el.remove();
      });
  }

  // Function to remove all styles and classes
  function removeAttributes() {
    doc.querySelectorAll("*").forEach((el) => {
      el.removeAttribute("class");
      el.removeAttribute("style");
    });
  }

  // Function to remove quoted text
  function removeQuotedText(node) {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent;
      const index = textContent.indexOf(">");
      if (index !== -1) {
        node.textContent = textContent.substring(0, index);
      }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const childNodes = Array.from(node.childNodes);
      childNodes.forEach(removeQuotedText);
    }
  }

  // Function to remove two preceding <br> tags before elements with class "gmail_quote"
  function removePrecedingBrTags() {
    const quotes = doc.querySelectorAll(".gmail_quote");
    quotes.forEach((quote) => {
      let prevSibling = quote.previousSibling;
      let brCount = 0;
      while (prevSibling && brCount < 2) {
        // Check if the previous sibling is a <br> tag
        if (
          prevSibling.nodeType === Node.ELEMENT_NODE &&
          prevSibling.tagName === "BR"
        ) {
          let toRemove = prevSibling;
          prevSibling = prevSibling.previousSibling;
          toRemove.remove();
          brCount++;
        } else if (
          prevSibling.nodeType === Node.TEXT_NODE &&
          !prevSibling.textContent.trim()
        ) {
          // If it's an empty text node, just move to the previous sibling
          prevSibling = prevSibling.previousSibling;
        } else {
          break;
        }
      }
    });
  }

  // Call the functions to clean up the HTML
  removeSpecificElements();
  removeAttributes();
  removeQuotedText(doc.body);
  removePrecedingBrTags();

  return doc.body.innerHTML;
}

function formatIsoDateToDate(isoDateString) {
  try {
    const date = new Date(isoDateString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return date.toLocaleDateString(undefined, options);
  } catch (error) {
    console.error("Error in formatting ISO date string:", error);
    return "";
  }
}
