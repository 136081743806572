import React from "react";
import { useState, useContext, useEffect } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import CircularLoader from "../../Common/CircularLoader/CircularLoader";

const GeneralSignUp = ({ onNext, onBack, handleInputChange, formData }) => {
  const { databaseRegister, signInWithGoogle } = useContext(AuthContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  function handleSignIn() {
    navigate("/sign-in");
  }

  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    };

    if (validateEmail(formData.email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  }, [formData.email]);

  const canContinue =
    formData.firstName && formData.lastName && formData.email && isValidEmail;

  async function handleRegisterAccount() {
    setLoading(true);
    try {
      const register = await databaseRegister(formData);

      if (register.success) {
        handleInputChange("register_id", register.register_id);
        onNext();
      } else {
        setError(register.message);
      }
    } catch (error) {
      console.error("Registration error:", error);
      setError("An error occurred during registration.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.sidebar}>
      <img
        alt="logo"
        src="/BrandLinkLogoBlue.png"
        className={styles.logo_icon}
      ></img>
      <p className={styles.page_title}>Welcome to Brandlink 👋</p>
      <p className={styles.page_subtitle}>Let's get started!</p>

      <div className={styles.name_inputs}>
        <div className={styles.whole_input_field}>
          <p className={styles.login_input_titles}>First Name</p>
          <div className={styles.sign_up_input}>
            <input
              value={formData.firstName}
              onChange={(event) =>
                handleInputChange("firstName", event.target.value)
              }
              className={styles.input}
            ></input>
          </div>
        </div>
        <div className={styles.whole_input_field}>
          <p className={styles.login_input_titles}>Last Name</p>
          <div className={styles.sign_up_input}>
            <input
              value={formData.lastName}
              onChange={(event) =>
                handleInputChange("lastName", event.target.value)
              }
              className={styles.input}
            ></input>
          </div>
        </div>
      </div>
      <p className={styles.login_input_titles}>Email Address</p>
      <div className={styles.sign_up_input}>
        <input
          value={formData.email}
          onChange={(event) => handleInputChange("email", event.target.value)}
          className={styles.input}
        ></input>
      </div>

      <div className={styles.spacer}></div>

      {canContinue ? (
        <div className={styles.page_button} onClick={handleRegisterAccount}>
          {loading ? (
            <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
          ) : (
            <p className={styles.join_button_text}>Continue</p>
          )}
        </div>
      ) : (
        <div className={styles.page_button_inactive}>
          <p className={styles.join_button_text}>Continue</p>
        </div>
      )}
      {error && <p className={styles.error_text}>{error}</p>}
      {/* <div className={styles.page_break_line}>
        <div className={styles.line_half}></div>
        <p className={styles.or_text}>Or</p>
        <div className={styles.line_half}></div>
      </div>

      <div className={styles.auth_options_3rd}>
        <div className={styles.sign_in_with_google} onClick={signInWithGoogle}>
          <img
            className={styles.google_icon}
            alt="password"
            src="/AuthProviders/google.png"
          ></img>
          <p className={styles.login_with_auth_text}>Continue with Google</p>
        </div>
        <div className={styles.sign_in_with_google}>
          <img
            className={styles.google_icon}
            alt="password"
            src="/AuthProviders/microsoft.png"
          ></img>
          <p className={styles.login_with_auth_text}>Continue with Microsoft</p>
        </div>
      </div> */}

      <div className={styles.prompt_texts}>
        <p className={styles.note}>
          Already have an account?{" "}
          <span className={styles.clickable_text} onClick={handleSignIn}>
            Sign In
          </span>
        </p>
      </div>
    </div>
  );
};

export default GeneralSignUp;
