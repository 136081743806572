import React, { useState } from "react";
import trigger_options from "./WorkflowData/trigger_options";
import styles from "./styles.module.css";
import WorkflowIcon from "../../../../assets/SVG/NewSidebarIcons/WorkflowIcon";
import TriggerIcon from "../../../../assets/SVG/BlueprintIcons/TriggerIcon";
import DocumentationIcon from "../../../../assets/SVG/BlueprintIcons/DocumentationIcon";
import TemplatesIcon from "../../../../assets/SVG/BlueprintIcons/TemplatesIcon";
import FullBackArrow from "../../../../assets/SVG/FullBackArrow";

export default function EditorSidebar({ view, setView }) {
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar_content}>
        <SelectElement />
        {/* {view === "blueprintProperties" && (
          <BlueprintProperties
            showTriggerSelection={() => setView("selectTrigger")}
          />
        )}
        {view === "selectTrigger" && <SelectTrigger />} */}
      </div>
    </div>
  );
}

function BlueprintProperties({ showTriggerSelection }) {
  return (
    <div className={styles.node_properties_layout}>
      {/* <div className={styles.node_properties_header_section}></div> */}
    </div>
  );
}

function SelectElement({ handleTriggerSelect }) {
  return (
    <div className={styles.select_trigger_layout}>
      <div className={styles.select_trigger_titles}>
        <p className={styles.select_trigger_title}>Form Elements</p>
      </div>
      <input
        className={styles.select_trigger_search}
        placeholder="Search Elements..."
      />
      <div className={styles.option_scroll_view}>
        {formBuilderComponents.map((section) => (
          <div key={section.section} className={styles.section}>
            <div className={styles.section_title}>{section.sectionTitle}</div>

            <div className={styles.action_list}>
              {section.options.map((action) => (
                <ActionOption
                  key={action.title}
                  option={action}
                  onSelect={handleTriggerSelect}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function ActionOption({ option, onSelect }) {
  return (
    <div className={styles.option_tile} onClick={() => onSelect(option)}>
      <div className={styles.option_tile_left}>
        <div className={styles.icon_box}>
          {/* <option.icon color="var(--icon-color)" width={15} height={15} /> */}
        </div>
        {option.label}
      </div>
    </div>
  );
}

function SidebarFooter() {
  return (
    <div className={styles.sidebar_footer}>
      <div className={styles.footer_tile}>
        <div className={styles.footer_tile_top}>
          <div className={styles.footer_tile_icon}>
            <DocumentationIcon width={12} height={12} />
          </div>
          <p className={styles.footer_tile_title}>Documentation</p>
        </div>
        <p className={styles.footer_tile_desc}>
          Learn about how to best set up blueprints
        </p>
      </div>
      <div className={styles.footer_tile}>
        <div className={styles.footer_tile_top}>
          <div className={styles.footer_tile_icon}>
            <TemplatesIcon width={12} height={12} />
          </div>
          <p className={styles.footer_tile_title}>Templates</p>
        </div>
        <p className={styles.footer_tile_desc}>
          Get started with ready blueprint templates
        </p>
      </div>
    </div>
  );
}

const formBuilderComponents = [
  {
    sectionTitle: "Text Elements",
    options: [
      { id: "singleLineText", label: "Single Line Text", type: "text" },
      { id: "multiLineText", label: "Multi-line Text", type: "textarea" },
      { id: "richTextEditor", label: "Rich Text Editor", type: "richText" },
      { id: "placeholderText", label: "Placeholder Text", type: "placeholder" },
      {
        id: "preformattedContent",
        label: "Preformatted Content",
        type: "staticText",
      },
    ],
  },
  {
    sectionTitle: "Media Elements",
    options: [
      { id: "image", label: "Image", type: "image" },
      { id: "video", label: "Video", type: "video" },
      { id: "audio", label: "Audio", type: "audio" },
      { id: "slider", label: "Slider/Carousel", type: "slider" },
      // { id: "background", label: "Background Options", type: "background" },
    ],
  },
  // {
  //   sectionTitle: "Form Input Elements",
  //   options: [
  //     { id: "shortTextInput", label: "Short Text Input", type: "text" },
  //     { id: "longTextInput", label: "Long Text Input", type: "textarea" },
  //     { id: "numberInput", label: "Number Input", type: "number" },
  //     { id: "datePicker", label: "Date Picker", type: "date" },
  //     { id: "dateTimePicker", label: "Date & Time Picker", type: "datetime" },
  //     { id: "dropdownMenu", label: "Dropdown Menu", type: "dropdown" },
  //     { id: "checkbox", label: "Checkbox", type: "checkbox" },
  //     { id: "radioButtons", label: "Radio Buttons", type: "radio" },
  //     { id: "yesNoToggle", label: "Yes/No Toggle", type: "toggle" },
  //     { id: "sliderInput", label: "Slider Input", type: "slider" },
  //     { id: "fileUpload", label: "File Upload", type: "file" },
  //     { id: "attachments", label: "Attachments", type: "attachment" },
  //   ],
  // },
  // {
  //   sectionTitle: "Quiz Elements",
  //   options: [
  //     { id: "multipleChoice", label: "Multiple Choice", type: "quiz" },
  //     { id: "trueFalse", label: "True/False", type: "quiz" },
  //     { id: "ranking", label: "Ranking", type: "quiz" },
  //     { id: "matrixQuestions", label: "Matrix Questions", type: "matrix" },
  //     { id: "imageChoices", label: "Image Choices", type: "quiz" },
  //     { id: "nps", label: "Net Promoter Score", type: "quiz" },
  //     { id: "ratingScales", label: "Rating Scales", type: "quiz" },
  //     { id: "opinionScale", label: "Opinion Scale", type: "quiz" },
  //   ],
  // },
  // {
  //   sectionTitle: "Interactive Elements",
  //   options: [
  //     { id: "progressTracker", label: "Progress Tracker", type: "interactive" },
  //     { id: "conditionalLogic", label: "Conditional Logic", type: "logic" },
  //     { id: "tabsSections", label: "Tabs/Sections", type: "layout" },
  //     { id: "dynamicFields", label: "Dynamic Fields", type: "interactive" },
  //     { id: "timer", label: "Timer", type: "interactive" },
  //   ],
  // },
  // {
  //   sectionTitle: "Layout Elements",
  //   options: [
  //     { id: "sections", label: "Sections", type: "layout" },
  //     { id: "tables", label: "Tables", type: "layout" },
  //     { id: "columns", label: "Columns", type: "layout" },
  //     { id: "cards", label: "Cards", type: "layout" },
  //     {
  //       id: "customBackgrounds",
  //       label: "Customizable Backgrounds",
  //       type: "layout",
  //     },
  //   ],
  // },
  // {
  //   sectionTitle: "AI Elements",
  //   options: [
  //     {
  //       id: "generateQuestions",
  //       label: "Generate Questions with AI",
  //       type: "ai",
  //     },
  //     { id: "clarifyInputs", label: "Clarify Inputs with AI", type: "ai" },
  //     { id: "summarizeResponses", label: "Summarize Responses", type: "ai" },
  //     { id: "autoComplete", label: "Auto-Complete", type: "ai" },
  //     {
  //       id: "smartRecommendations",
  //       label: "Smart Recommendations",
  //       type: "ai",
  //     },
  //   ],
  // },
  // {
  //   sectionTitle: "Action Elements",
  //   options: [
  //     { id: "redirectUrl", label: "Redirect to URL", type: "action" },
  //     {
  //       id: "thankYouPage",
  //       label: "Customizable Thank You Pages",
  //       type: "action",
  //     },
  //     { id: "partialSubmit", label: "Partial Submit", type: "action" },
  //     {
  //       id: "confirmation",
  //       label: "Form Submission Confirmation",
  //       type: "action",
  //     },
  //     { id: "triggerWebhook", label: "Trigger Webhooks", type: "integration" },
  //     { id: "apiConnections", label: "API Connections", type: "integration" },
  //   ],
  // },
  // {
  //   sectionTitle: "Contact Integration Elements",
  //   options: [
  //     { id: "connectCRM", label: "Connect CRM", type: "integration" },
  //     {
  //       id: "calendlyIntegration",
  //       label: "Calendly Integration",
  //       type: "integration",
  //     },
  //     { id: "paymentFields", label: "Payment Fields", type: "integration" },
  //     {
  //       id: "googleDriveSync",
  //       label: "Google Drive/File Sync",
  //       type: "integration",
  //     },
  //   ],
  // },
  // {
  //   sectionTitle: "Utility Elements",
  //   options: [
  //     { id: "welcomeScreen", label: "Welcome Screen", type: "utility" },
  //     { id: "endScreen", label: "End Screen", type: "utility" },
  //     { id: "questionGroups", label: "Question Groups", type: "utility" },
  //     {
  //       id: "multiQuestionPages",
  //       label: "Multi-Question Pages",
  //       type: "utility",
  //     },
  //     {
  //       id: "saveAndContinue",
  //       label: "Save & Continue Later",
  //       type: "utility",
  //     },
  //     { id: "dynamicTooltips", label: "Dynamic Tooltips", type: "utility" },
  //   ],
  // },
];
