import React from "react";

const TaskCreatedIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_7647398"
  >
    <path
      d="m22 5.18-11.41 11.42-4.24-4.24 1.41-1.41 2.83 2.83 10-10zm-10 14.82c-4.41 0-8-3.59-8-8s3.59-8 8-8c1.57 0 3.04.46 4.28 1.25l1.45-1.45c-1.63-1.13-3.6-1.8-5.73-1.8-5.52 0-10 4.48-10 10s4.48 10 10 10c1.73 0 3.36-.44 4.78-1.22l-1.5-1.5c-1 .46-2.11.72-3.28.72zm7-5h-3v2h3v3h2v-3h3v-2h-3v-3h-2z"
      fill={color}
    ></path>
  </svg>
);

export default TaskCreatedIcon;
