import { useState, useContext, useEffect } from "react";
import React from "react";
import styles from "./campaigns.module.css";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import { CampaignContext } from "../../../context/CampaignContext";
import CircularLoader from "../../Common/CircularLoader/CircularLoader";

import CheckmarkIcon from "../../../assets/SVG/CheckmarkIcon";
import CancelIcon from "../../../assets/SVG/CancelIcon";

export default function CampaignContact({ contact }) {
  // const firstMessage = contact?.message_sequence[0];
  console.log(contact);
  const firstMessage = true;
  return (
    <div className={styles.campaign_contact}>
      <img
        className={styles.campaign_contact_image}
        src={`/${contact.contact_image}`}
      ></img>
      <div className={styles.campaign_cell}>{contact.name}</div>
      <div className={styles.email_cell}>
        {contact.platform_username}@gmail.com
      </div>
      <div className={styles.campaign_cell}>
        {firstMessage.status === "pending" && (
          <div className={styles.contact_status_pending}>Pending</div>
        )}
        {firstMessage.status === "sent" && (
          <div className={styles.contact_status_sent}>Sent</div>
        )}
      </div>
      <div className={styles.campaign_cell}>
        {contact.replied ? (
          <div className={styles.reply_status_true}>
            <CheckmarkIcon width={15} height={15} color="rgba(0, 131, 10, 1)" />
          </div>
        ) : (
          <div className={styles.reply_status_false}>
            <CancelIcon width={15} height={15} color="rgba(0, 0, 0, 0.75)" />
          </div>
        )}
      </div>
      <div className={styles.campaign_cell}></div>
    </div>
  );
}
