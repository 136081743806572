import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.css";
import TableControls from "../../Common/TableControls/TableControls";
import SliderHeaderMenu from "../../Common/SliderHeaderMenu/SliderHeaderMenu";
import Table from "./Table/Table";
import Toolbar from "../../Common/Toolbar/Toolbar";

export default function Broadcasts() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Drip");

  const menuItems = ["Drip", "Templates", "Drafts"];
  return (
    <div className={styles.main_layout}>
      {/* <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      /> */}
      <Toolbar feature="Broadcast" />
      <div className={styles.page_layout}>
        <div className={styles.table_layout}>
          {/* <TasksTable /> */}
          <Table
            columns={columns}
            data={data}
            statusColorMap={statusColorMap}
          />
        </div>
      </div>
    </div>
  );
}

const columns = [
  { name: "Title", uid: "title" },
  // { name: "Text Preview", uid: "textPreview" },
  { name: "Last Sent", uid: "lastSent" },
  { name: "Open Rate", uid: "openRate" },
  { name: "Click Rate", uid: "clickRate" },
  // { name: "Status", uid: "status" },
];

const data = [
  {
    id: 1,
    title: "Welcome to Our Newsletter!",
    textPreview: "Thanks for joining! Here’s what to expect...",
    lastSent: "November 1, 2024",
    openRate: "58%",
    clickRate: "12%",
    status: "Sent",
  },
  {
    id: 2,
    title: "October Updates & News",
    textPreview: "Check out what’s new this month...",
    lastSent: "October 22, 2024",
    openRate: "47%",
    clickRate: "8%",
    status: "Sent",
  },
  {
    id: 3,
    title: "Upcoming Webinar Reminder",
    textPreview: "Don’t miss out on our live session...",
    lastSent: "October 20, 2024",
    openRate: "65%",
    clickRate: "15%",
    status: "Scheduled",
  },
  // {
  //   id: 4,
  //   title: "Special Offer Inside!",
  //   textPreview: "Exclusive deals just for our subscribers...",
  //   lastSent: "October 18, 2024",
  //   openRate: "74%",
  //   clickRate: "21%",
  //   status: "Sent",
  // },
  // {
  //   id: 5,
  //   title: "Product Update - New Features",
  //   textPreview: "We’ve rolled out exciting new features...",
  //   lastSent: "October 15, 2024",
  //   openRate: "53%",
  //   clickRate: "10%",
  //   status: "Draft",
  // },
  // {
  //   id: 6,
  //   title: "Survey: We Want Your Feedback",
  //   textPreview: "Help us improve by taking this quick survey...",
  //   lastSent: "October 12, 2024",
  //   openRate: "49%",
  //   clickRate: "6%",
  //   status: "Sent",
  // },
];

const statusColorMap = {
  Pending: {
    background: "rgba(204, 153, 0, 0.1)", // Dark yellow background at 0.1 opacity
    text: "rgba(204, 153, 0, 1)", // Dark yellow text at full opacity
  },
  Signed: {
    background: "rgba(0, 77, 36, 0.1)", // Dark green background at 0.1 opacity
    text: "rgba(0, 77, 36, 1)", // Dark green text at full opacity
  },
  Expired: {
    background: "rgba(200, 0, 0, 0.1)", // Red background at 0.1 opacity
    text: "rgba(200, 0, 0, 1)", // Red text at full opacity
  },
};
