import React from "react";

const InvoiceIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    enable-background="new 0 0 24 24"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_3359085"
  >
    <path d="m17.5 18h-.08c-.276 0-.5-.224-.5-.5s.224-.5.5-.5.54.224.54.5-.184.5-.46.5z"></path>
    <path d="m9.5 17h-7c-1.378 0-2.5-1.121-2.5-2.5v-11c0-1.379 1.122-2.5 2.5-2.5h19c1.378 0 2.5 1.121 2.5 2.5v8c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-8c0-.827-.673-1.5-1.5-1.5h-19c-.827 0-1.5.673-1.5 1.5v11c0 .827.673 1.5 1.5 1.5h7c.276 0 .5.224.5.5s-.224.5-.5.5z"></path>
    <path d="m12 12c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"></path>
    <path d="m17.5 18c-3.706 0-6.5-1.29-6.5-3s2.794-3 6.5-3 6.5 1.29 6.5 3-2.794 3-6.5 3zm0-5c-3.148 0-5.5 1.056-5.5 2s2.352 2 5.5 2 5.5-1.056 5.5-2-2.352-2-5.5-2z"></path>
    <path d="m17.5 21c-3.706 0-6.5-1.29-6.5-3 0-.276.224-.5.5-.5s.5.224.5.5c0 .944 2.352 2 5.5 2s5.5-1.056 5.5-2c0-.276.224-.5.5-.5s.5.224.5.5c0 1.71-2.794 3-6.5 3z"></path>
    <path d="m17.5 24c-3.706 0-6.5-1.29-6.5-3 0-.276.224-.5.5-.5s.5.224.5.5c0 .944 2.352 2 5.5 2s5.5-1.056 5.5-2c0-.276.224-.5.5-.5s.5.224.5.5c0 1.71-2.794 3-6.5 3z"></path>
    <path d="m11.5 21.5c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5s.5.224.5.5v6c0 .276-.224.5-.5.5z"></path>
    <path d="m23.5 21.5c-.276 0-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5s.5.224.5.5v6c0 .276-.224.5-.5.5z"></path>
    <path d="m5.5 5h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5s-.224.5-.5.5z"></path>
  </svg>
);

export default InvoiceIcon;
