import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

import { artemisDatabase } from "../services/firebase";
import { storage } from "../services/firebase";

import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  deleteDoc,
  runTransaction,
  limit,
  addDoc,
} from "firebase/firestore";

export const SocialAccountsContext = createContext();

export const SocialAccountsProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  async function fetchMessages(accountId) {
    // const queryParams = new URLSearchParams({
    //   accountId: accountId,
    // });
    // const url = `https://artemis--ai.uc.r.appspot.com/unipile/fetch-messages?${queryParams.toString()}`;
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    // try {
    //   const response = await axios.get(url, config);
    //   setMessages(response.data);
    // } catch (error) {
    //   console.error("Error fetching emails:", error);
    //   throw error;
    // }
  }

  useEffect(() => {
    // fetchMessages("BXrpj8efSgSvrq7iN0c0Pw");
  }, []);

  return (
    <SocialAccountsContext.Provider
      value={{
        messages,
        setMessages,
      }}
    >
      {children}
    </SocialAccountsContext.Provider>
  );
};
