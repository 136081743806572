import React from "react";

const PreviewIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3596215"
    enable-background="new 0 0 24 24"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m.5 7c-.276 0-.5-.224-.5-.5v-2c0-1.378 1.121-2.5 2.5-2.5h2c.276 0 .5.224.5.5s-.224.5-.5.5h-2c-.827 0-1.5.673-1.5 1.5v2c0 .276-.224.5-.5.5z"></path>
    </g>
    <g>
      <path d="m23.5 7c-.276 0-.5-.224-.5-.5v-2c0-.827-.673-1.5-1.5-1.5h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c1.379 0 2.5 1.122 2.5 2.5v2c0 .276-.224.5-.5.5z"></path>
    </g>
    <g>
      <path d="m4.5 22h-2c-1.379 0-2.5-1.122-2.5-2.5v-2c0-.276.224-.5.5-.5s.5.224.5.5v2c0 .827.673 1.5 1.5 1.5h2c.276 0 .5.224.5.5s-.224.5-.5.5z"></path>
    </g>
    <g>
      <path d="m21.5 22h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.827 0 1.5-.673 1.5-1.5v-2c0-.276.224-.5.5-.5s.5.224.5.5v2c0 1.378-1.121 2.5-2.5 2.5z"></path>
    </g>
    <g>
      <path d="m12 18c-3.853 0-7.136-2.418-7.985-5.881-.02-.078-.02-.16 0-.238.849-3.463 4.132-5.881 7.985-5.881s7.136 2.418 7.985 5.881c.02.078.02.16 0 .238-.849 3.463-4.132 5.881-7.985 5.881zm-6.984-6c.786 2.95 3.639 5 6.984 5s6.198-2.05 6.984-5c-.786-2.95-3.638-5-6.984-5s-6.198 2.05-6.984 5z"></path>
    </g>
    <g>
      <path d="m12 15c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"></path>
    </g>
  </svg>
);

export default PreviewIcon;
