import styles from "./styles.module.css";
import BackIcon from "../../../assets/SVG/BackIcon";
import AddIcon from "assets/SVG/AddIcon";

export default function ViewTabControls({
  currentPage,
  totalRows,
  rowsPerPage,
  onPageChange,
}) {
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <div className={styles.pagination}>
      <div className={styles.pagination_left}>
        <div className={styles.view_tab_selected}>All Contacts</div>
        <div className={styles.view_tab}>Starred</div>
        <div className={styles.add_button}>
          <AddIcon color="rgba(0, 0, 0, 1)" />
          Create View
        </div>
      </div>

      {/* <div className={styles.pagination_middle}>
        <span className={styles.pageInfo}>
          Page {currentPage} of {totalPages}
        </span>
      </div> */}
      {/* <div className={styles.pagination_right}>
        <div className={styles.page_button}>
          <BackIcon width={13} height={13} color="rgba(0, 88, 220, 0.85)" />
          Previous
        </div>
        <div className={styles.page_button}>
          Next
          <div style={{ transform: "rotate(180deg) translateY(1px)" }}>
            <BackIcon width={13} height={13} color="rgba(0, 88, 220, 0.85)" />
          </div>
        </div>
      </div> */}
    </div>
  );
}
