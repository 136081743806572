import React from "react";
// import SignUpComponent from "../../components/AuthComponents/SignUpComponentOld";
import SignInComponent from "../../components/Auth/SignInComponent";
import SignUpComponent from "../../components/Auth/SignUpComponent";

const SignUpPage = () => {
  return (
    <div>
      <SignUpComponent />
    </div>
  );
};

export default SignUpPage;
