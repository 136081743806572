import React from "react";

const TemplatesIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",

  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_5001094"
  >
    <g id="Burst_Mode" data-name="Burst Mode">
      <path
        fill={color}
        d="m44 17h-3v-3a3.0033 3.0033 0 0 0 -3-3h-3v-3a3.0033 3.0033 0 0 0 -3-3h-28a3.0033 3.0033 0 0 0 -3 3v20a3.0033 3.0033 0 0 0 3 3h3v3a3.0033 3.0033 0 0 0 3 3h3v3a3.0033 3.0033 0 0 0 3 3h28a3.0033 3.0033 0 0 0 3-3v-20a3.0033 3.0033 0 0 0 -3-3zm-41 11v-20a1.001 1.001 0 0 1 1-1h28a1.001 1.001 0 0 1 1 1v20a1.001 1.001 0 0 1 -1 1h-28a1.001 1.001 0 0 1 -1-1zm6 6v-3h23a3.0033 3.0033 0 0 0 3-3v-15h3a1.001 1.001 0 0 1 1 1v20a1.001 1.001 0 0 1 -1 1h-28a1.001 1.001 0 0 1 -1-1zm36 6a1.001 1.001 0 0 1 -1 1h-28a1.001 1.001 0 0 1 -1-1v-3h23a3.0033 3.0033 0 0 0 3-3v-15h3a1.001 1.001 0 0 1 1 1zm-40-27v-2a2.0023 2.0023 0 0 1 2-2h2a1 1 0 0 1 0 2h-2v2a1 1 0 0 1 -2 0zm21-3a1 1 0 0 1 1-1h2a2.0023 2.0023 0 0 1 2 2v2a1 1 0 0 1 -2 0v-2h-2a1 1 0 0 1 -1-1zm-17 17h-2a2.0023 2.0023 0 0 1 -2-2v-2a1 1 0 0 1 2 0v2h2a1 1 0 0 1 0 2zm17-1a1 1 0 0 1 1-1h2v-2a1 1 0 0 1 2 0v2a2.0023 2.0023 0 0 1 -2 2h-2a1 1 0 0 1 -1-1zm-8-4a4 4 0 1 0 -4-4 4.0043 4.0043 0 0 0 4 4zm0-6a2 2 0 1 1 -2 2 2.0023 2.0023 0 0 1 2-2z"
      ></path>
    </g>
  </svg>
);

export default TemplatesIcon;
