import styles from "./styles.module.css";
import BackIcon from "../../../assets/SVG/BackIcon";

export default function PaginationControls({
  currentPage,
  totalRows,
  rowsPerPage,
  onPageChange,
}) {
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <div className={styles.pagination}>
      <div className={styles.pagination_left}>
        <div className={styles.page_result_count}>10 Tasks</div>
      </div>
      <div className={styles.pagination_middle}>
        <span className={styles.pageInfo}>
          Page {currentPage} of {totalPages}
        </span>
      </div>
      <div className={styles.pagination_right}>
        <div className={styles.page_button}>
          <BackIcon width={13} height={13} color="rgba(0, 88, 220, 0.85)" />
          Previous
        </div>
        <div className={styles.page_button}>
          Next
          <div style={{ transform: "rotate(180deg) translateY(1px)" }}>
            <BackIcon width={13} height={13} color="rgba(0, 88, 220, 0.85)" />
          </div>
        </div>
        {/* <button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          className={
            (styles.paginationButton,
            currentPage === 1 && styles.paginationButtonDisabled)
          }
        >
          Previous
        </button>
        <button
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
          className={
            (styles.paginationButton,
            currentPage === totalPages && styles.paginationButtonDisabled)
          }
        >
          Next
        </button> */}
      </div>
    </div>
  );
}
