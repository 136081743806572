import { useState, useContext, useEffect } from "react";
import React from "react";
import styles from "./campaigns.module.css";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { CampaignContext } from "../../../context/CampaignContext";
import CircularLoader from "../../Common/CircularLoader/CircularLoader";
import AddIcon from "../../../assets/SVG/AddIcon";

import PlayIcon from "../../../assets/SVG/PlayIcon";
import PauseIcon from "../../../assets/SVG/PauseIcon";

import CampaignContact from "./CampaignContact";

import CampaignChart from "./CampaignChart";

import { toast } from "react-toastify";

import SearchIcon from "../../../assets/SVG/SearchIcon";

// import UpdateCampaign

import BackIcon from "../../../assets/SVG/BackIcon";
import contacts_data from "../../../data/contacts_data";

export default function ManageCampaignComponents() {
  const { id } = useParams();

  const [campaign, setCampaign] = useState();
  const [textPercentComplete, setTextPercentComplete] = useState(0);
  const [widthPercentComplete, setWidthPercentComplete] = useState(0);

  const [updatingCampaign, setUpdatingCampaign] = useState(false);

  const [isLoadingControl, setLoadingControl] = useState(false);

  const navigate = useNavigate();

  function openPopup() {
    setUpdatingCampaign(true);
  }

  function closePopup() {
    setUpdatingCampaign(false);
  }

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const { fetchCampaignById, pauseCampaign, resumeCampaign } =
    useContext(CampaignContext);

  async function handlePauseClick(event) {
    event.stopPropagation();
    setLoadingControl(true);
    try {
      await pauseCampaign(campaign.id);
      setCampaign((prev) => ({ ...prev, status: "paused" }));
    } catch (error) {
      toast.success("Failed to resume");
      console.error("Failed to pause the campaign:", error);
    } finally {
      setLoadingControl(false);
      toast.success("Campaign Paused");
    }
  }

  async function handleResumeClick(event) {
    event.stopPropagation();
    setLoadingControl(true);
    try {
      await resumeCampaign(campaign.id);
      setCampaign((prev) => ({ ...prev, status: "active" }));
    } catch (error) {
      toast.success("Failed to pause");
      console.error("Failed to resume the campaign:", error);
    } finally {
      setLoadingControl(false);
      toast.success("Campaign Resumed");
    }
  }

  useEffect(() => {
    async function loadCampaign() {
      try {
        const fetchedCampaign = await fetchCampaignById(id);
        setCampaign(fetchedCampaign);

        // const targetPercentComplete =
        //   fetchedCampaign?.messages_sent && fetchedCampaign.contact_count
        //     ? Math.round(
        //         (fetchedCampaign.messages_sent /
        //           fetchedCampaign.contact_count) *
        //           100
        //       )
        //     : 0;

        const targetPercentComplete = 64;

        setTimeout(() => {
          setWidthPercentComplete(targetPercentComplete);
        }, 100);

        let currentTextPercent = 0;

        const incrementTextPercent = () => {
          if (currentTextPercent < targetPercentComplete) {
            currentTextPercent++;
            setTextPercentComplete(currentTextPercent);
            setTimeout(incrementTextPercent, 20); // Adjust the timeout for speed of count up
          } else {
            setTextPercentComplete(targetPercentComplete);
          }
        };

        incrementTextPercent();
      } catch (error) {
        console.error("Failed to fetch campaign:", error);
      }
    }

    if (id) {
      loadCampaign();
    }
  }, [id, fetchCampaignById]);

  if (!campaign) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularLoader color="rgba(0, 88, 220, 1)" size="10%" />
      </div>
    );
  }

  function CampaignStats() {
    return (
      <div className={styles.campaign_stats}>
        {/* <UpdateCampaignPopup
          isOpen={updatingCampaign}
          onClose={closePopup}
          campaign={campaign}
        /> */}
        <div className={styles.stat}>
          <div className={styles.stat_title}>Delivered</div>
          <div className={styles.stat_value}>63</div>
        </div>
        <div className={styles.stat}>
          <div className={styles.stat_title}>Opened</div>
          <div className={styles.stat_value}>32</div>
        </div>
        <div className={styles.stat}>
          <div className={styles.stat_title}>Replied</div>
          <div className={styles.stat_value}>12</div>
        </div>
      </div>
    );
  }

  function goBack() {
    navigate(-1);
  }

  return (
    <div className={styles.main_layout}>
      <div className={styles.page_header}>
        <div className={styles.top_controls}>
          {campaign.status === "active" && (
            <div className={styles.campaign_tile_top_right}>
              <div className={styles.control_button} onClick={handlePauseClick}>
                {isLoadingControl ? (
                  <CircularLoader size="50%" color="rgba(0, 88, 220, 1)" />
                ) : (
                  <PauseIcon />
                )}
              </div>
              <div className={styles.status_active}>
                {capitalizeFirstLetter(campaign.status)}
                <div
                  className={styles.status_dot}
                  style={{ backgroundColor: "rgba(0, 131, 10, 0.8)" }}
                ></div>
              </div>
            </div>
          )}
          {campaign.status === "paused" && (
            <div className={styles.campaign_tile_top_right}>
              <div
                className={styles.control_button}
                onClick={handleResumeClick}
              >
                {isLoadingControl ? (
                  <CircularLoader size="50%" color="rgba(0, 88, 220, 1)" />
                ) : (
                  <PlayIcon />
                )}
              </div>
              <div className={styles.status_paused}>
                {capitalizeFirstLetter(campaign.status)}
                <div
                  className={styles.status_dot}
                  style={{ backgroundColor: "rgba(200, 0, 0, 0.8)" }}
                ></div>
              </div>
            </div>
          )}
          {campaign.status === "completed" && (
            <div className={styles.campaign_tile_top_right}>
              <div className={styles.control_button} style={{ opacity: 0 }}>
                {/* <CircularLoader size="50%" color="rgba(0, 0, 0, 1)" /> */}
              </div>
              <div className={styles.status_completed}>
                {capitalizeFirstLetter(campaign.status)}
              </div>
            </div>
          )}
          <div className={styles.update_campaign_button} onClick={openPopup}>
            Edit Campaign
          </div>
        </div>

        <div className={styles.page_header_left}>
          <div className={styles.campaign_title_back}>
            <div className={styles.back_button} onClick={goBack}>
              <BackIcon />
            </div>
            <p className={styles.campaign_title}>{campaign.campaign_name}</p>
          </div>
          <p className={styles.campaign_description}>
            {campaign.campaign_description}
          </p>
          <p className={styles.percent_complete_text}>
            <span className={styles.percent_complete_text_span}>
              {textPercentComplete}%
            </span>
            percent complete
          </p>
          <div className={styles.progress_line_background}>
            <div
              className={styles.progress_line}
              style={{ width: `${widthPercentComplete}%` }}
            ></div>
          </div>
          {/* <CampaignStats /> */}
        </div>
        {/* <div className={styles.page_header_right}>
          <CampaignChart />
        </div> */}
      </div>
      <div className={styles.campaign_controls}>
        <div className={styles.search_container}>
          <SearchIcon width={20} height={20} />
          <input
            className={styles.search_container_input}
            placeholder="Search contacts by name or email..."
          ></input>
        </div>
        <div className={styles.right_controls}>
          {/* <div className={styles.export_button}>Export</div> */}
          {campaign.status !== "completed" && (
            <div className={styles.add_contacts_button}>
              Add Contacts <AddIcon color="rgba(0, 88, 220, 1)" />
            </div>
          )}
        </div>
      </div>
      <div className={styles.campaign_contact_cell_header}>
        <div className={styles.campaign_cell_first}>Name</div>
        <div className={styles.email_cell_header}>Email</div>
        <div className={styles.campaign_cell}>Status</div>
        <div className={styles.campaign_cell}>Replied</div>
        <div className={styles.campaign_cell}>Sequence</div>
      </div>

      <div className={styles.campaign_contact_list}>
        {contacts_data.map((contact, index) => (
          <CampaignContact key={index} contact={contact} />
        ))}
      </div>
    </div>
  );
}
