import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./campaigns.module.css";
import CampaignTile from "./CampaignTile";
import { useNavigate } from "react-router-dom";
import campaigns_data from "../../../data/campaigns_data";
import RefreshIcon from "../../../assets/SVG/RefreshIcon";
import AddIcon from "../../../assets/SVG/AddIcon";
import SearchIcon from "../../../assets/SVG/SearchIcon";
import { CampaignContext } from "../../../context/CampaignContext";

import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";

import TableControls from "../../Common/TableControls/TableControls";
import FilterIcon from "../../../assets/SVG/FilterIcon";

import Toolbar from "../../Common/Toolbar/Toolbar";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

export default function Campaigns() {
  const [activeTab, setActiveTab] = useState("All");
  const { fetchUserCampaigns } = useContext(CampaignContext);

  // const tabs = ["Account", "Payment", "Manage Subscription"];
  const tabs = ["All", "Active", "Paused", "Completed"];
  const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    async function loadCampaigns() {
      try {
        const campaignsData = await fetchUserCampaigns();
        setCampaigns(campaignsData);
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    }

    loadCampaigns();
  }, [activeTab]);

  function handleCreate() {
    navigate(`/create-campaign`);
  }

  const [hover, setHover] = useState(false);

  const quickstartPlaceholder =
    "Example: Create a campaign for B2B founders in tech";

  return (
    <div className={styles.main_layout}>
      <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <ToolbarExposed feature="Campaign" />

      <div className={styles.page_layout}>
        {campaigns_data.map((campaign, index) => (
          <CampaignTile initialCampaign={campaign} key={index} />
        ))}
      </div>
    </div>
  );
}
