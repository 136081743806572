import React from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

import { useNavigate } from "react-router-dom";

export default function Whiteboards() {
  const navigate = useNavigate();

  function handleNewEditor() {
    console.log("Navigating to funnel editor");
    navigate("/whiteboard/editor");
  }

  return (
    <div className={styles.main_layout}>
      <ToolbarExposed feature="Whiteboard" createFunction={handleNewEditor} />
      <div className={styles.page_layout}>
        <div className={styles.tile_layout}>
          <Tile />
        </div>
      </div>
    </div>
  );
}

function Tile() {
  return (
    <div className={styles.tile}>
      <div className={styles.tile_header}>
        <p className={styles.tile_title}>GTM Strategy</p>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body}>
        <img
          className={styles.placeholder_image}
          src="./WhiteboardImage.png"
        ></img>
        {/* <p className={styles.tile_preview}>
          Meeting Agenda: Introduction and Discovery (5 mins) Recap pain points
          Taylor mentioned in previous communications. Briefly share Archer's
          mission: to streamline influencer outreach and partnership growth.
          Product Demonstration (15 mins) Blueprints: Showcase an automated
          sequence to reach out to new influencers with personalized messages
          and follow-ups. Inboxes: Show how all emails, messages, and chats are
          centralized for easier tracking and response management. Analytics
          Dashboard: Demonstrate campaign performance tracking and report
          generation. Q&A and Objection Handling (10 mins) Address any questions
          about data security, ease of integration, and customization options.
          Onboarding Plan (5 mins) Outline a clear onboarding process to set up
          their team and build initial campaigns. Discuss dedicated support
          during the first month to optimize Archer for their needs. Next Steps
          (5 mins) Offer a 2-week free trial with priority onboarding. Set a
          follow-up meeting after the trial to discuss implementation progress
          and answer additional questions.
        </p> */}
      </div>
      <div className={styles.tile_footer}>
        <AccountTag />
        <p className={styles.last_updated}>Updated Today</p>
      </div>
    </div>
  );
}
