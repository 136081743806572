export const statusColorMap = {
  Pending: {
    background: "rgba(204, 153, 0, 0.1)",
    text: "rgba(204, 153, 0, 1)",
  },
  Signed: {
    background: "rgba(0, 77, 36, 0.1)",
    text: "rgba(0, 77, 36, 1)",
  },
  Expired: {
    background: "rgba(200, 0, 0, 0.1)",
    text: "rgba(200, 0, 0, 1)",
  },
};
