import React from "react";

const SavedIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_9511664"
  >
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="m5 21.75a1.81 1.81 0 0 1 -.78-.18 1.74 1.74 0 0 1 -1-1.57v-17a1.76 1.76 0 0 1 1.78-1.75h14a1.76 1.76 0 0 1 1.75 1.75v17a1.75 1.75 0 0 1 -2.75 1.4l-5.8-4.35a.24.24 0 0 0 -.3 0l-5.85 4.35a1.77 1.77 0 0 1 -1.05.35zm0-19a.25.25 0 0 0 -.25.25v17a.24.24 0 0 0 .14.22.23.23 0 0 0 .26 0l5.85-4.37a1.77 1.77 0 0 1 2.1 0l5.8 4.35a.23.23 0 0 0 .26 0 .24.24 0 0 0 .14-.22v-16.98a.25.25 0 0 0 -.3-.25z"
        fill={color}
      ></path>
      <path
        d="m15 7.75h-6a.75.75 0 0 1 0-1.5h6a.75.75 0 0 1 0 1.5z"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default SavedIcon;
