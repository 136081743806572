import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./styles.module.css";

import CircleAddIcon from "../../../assets/SVG/ActionIcons/CircleAddIcon";
import ImageIcon from "../../../assets/SVG/ActionIcons/ImageIcon";

export default function MessageInput({
  userInput,
  setUserInput,
  handleKeyPress,
  handleSendMessage,
}) {
  return (
    <div className={styles.input_box_layout}>
      <div className={styles.input_box}>
        <textarea
          className={styles.ask_artemis_input}
          placeholder="Type your message..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onKeyDown={handleKeyPress}
        ></textarea>
        <div className={styles.ask_artemis_bottom_controls}>
          <div className={styles.ask_artemis_left_controls}>
            <div className={styles.control_item}>
              <CircleAddIcon width={12} height={12} color="rgba(0, 0, 0, 1)" />
              <p className={styles.control_item_text}>Add Attatchment</p>
            </div>
            <div className={styles.control_item}>
              <ImageIcon width={12} height={12} color="rgba(0, 0, 0, 1)" />
              <p className={styles.control_item_text}>Use Image</p>
            </div>
          </div>
          <div className={styles.ask_artemis_right_controls}>
            <div
              className={styles.ask_artemis_button}
              onClick={handleSendMessage}
            >
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
