import React from "react";

const ConversationsIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 -52 511.99959 511"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_1358039"
  >
    <path d="m511.335938 387.789062-27.652344-91.183593c15.523437-29.042969 23.699218-61.679688 23.699218-94.820313 0-110.988281-90.296874-201.285156-201.285156-201.285156-67.210937 0-126.820312 33.117188-163.398437 83.882812-78.394531 10.324219-139.105469 77.570313-139.105469 158.738282 0 25.882812 6.273438 51.382812 18.191406 74.171875l-21.121094 69.640625c-1.652343 5.445312-.167968 11.355468 3.851563 15.378906 2.9375 2.9375 6.878906 4.519531 10.90625 4.519531 1.496094 0 3.003906-.21875 4.476563-.664062l69.640624-21.121094c22.789063 11.917969 48.285157 18.191406 74.171876 18.191406 23.960937 0 46.703124-5.300781 67.132812-14.773437 23.257812 9.410156 48.660156 14.605468 75.253906 14.605468 33.140625 0 65.777344-8.175781 94.820313-23.699218l91.183593 27.652344c1.472657.445312 2.980469.664062 4.476563.664062 4.027344 0 7.96875-1.582031 10.902344-4.519531 4.023437-4.019531 5.507812-9.933594 3.855469-15.378907zm-107.722657-39.832031c-4.09375-1.238281-8.511719-.726562-12.207031 1.414063-25.808594 14.953125-55.308594 22.855468-85.308594 22.855468-93.980468 0-170.441406-76.460937-170.441406-170.441406 0-93.984375 76.460938-170.441406 170.441406-170.441406 93.980469 0 170.441406 76.460938 170.441406 170.441406 0 30-7.902343 59.5-22.855468 85.308594-2.140625 3.695312-2.652344 8.117188-1.414063 12.207031l21.179688 69.835938zm-239.902343 24.4375c-22.753907 0-45.128907-5.996093-64.699219-17.332031-2.375-1.375-5.042969-2.078125-7.734375-2.078125-1.5 0-3.007813.21875-4.472656.664063l-48.253907 14.632812 14.632813-48.253906c1.238281-4.089844.726562-8.507813-1.414063-12.207032-11.339843-19.570312-17.335937-41.945312-17.335937-64.699218 0-56.632813 36.609375-104.871094 87.402344-122.308594-10.9375 24.789062-17.027344 52.183594-17.027344 80.972656 0 69.484375 35.390625 130.855469 89.09375 167.039063-9.6875 2.328125-19.796875 3.570312-30.191406 3.570312zm0 0"></path>
    <path d="m329.253906 243.230469h-93.378906c-8.515625 0-15.421875 6.902343-15.421875 15.421875 0 8.515625 6.90625 15.421875 15.421875 15.421875h93.378906c8.515625 0 15.417969-6.90625 15.417969-15.421875 0-8.519532-6.902344-15.421875-15.417969-15.421875zm0 0"></path>
    <path d="m376.320312 186.367188h-140.445312c-8.515625 0-15.421875 6.90625-15.421875 15.421874 0 8.519532 6.90625 15.421876 15.421875 15.421876h140.445312c8.515626 0 15.421876-6.902344 15.421876-15.421876 0-8.515624-6.90625-15.421874-15.421876-15.421874zm0 0"></path>
    <path d="m376.320312 129.507812h-140.445312c-8.515625 0-15.421875 6.90625-15.421875 15.421876 0 8.515624 6.90625 15.421874 15.421875 15.421874h140.445312c8.515626 0 15.421876-6.90625 15.421876-15.421874 0-8.515626-6.90625-15.421876-15.421876-15.421876zm0 0"></path>
  </svg>
);

export default ConversationsIcon;
