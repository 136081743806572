import React from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";

import { useNavigate } from "react-router-dom";

import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

export default function Blasts() {
  const navigate = useNavigate();
  function handleEditor() {
    console.log("Navigating to funnel editor");
    navigate("/broadcasts/editor");
  }

  const quickstartPlaceholder =
    "Example: Create an email template for a new funnel submission";
  return (
    <div className={styles.main_layout}>
      <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <ToolbarExposed feature="Blast" createFunction={handleEditor} />
      <div className={styles.page_layout}>
        <div className={styles.tile_layout}>
          <Tile />
        </div>
      </div>
    </div>
  );
}

function Tile() {
  return (
    <div className={styles.tile}>
      <div className={styles.tile_header}>
        <p className={styles.tile_title}>New Customer Email</p>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body}>
      <img
          className={styles.placeholder_image}
          src="./BroadcastPlaceholedrImage.png"
        ></img>
      </div>
      <div className={styles.tile_footer}>
        <AccountTag />
        <p className={styles.last_updated}>Updated Today</p>
      </div>
    </div>
  );
}
