import React, { createContext, useContext } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccountContext } from "./AccountContext"; // Use AccountContext

export const TasksContext = createContext();

export const TasksProvider = ({ children }) => {
  const { user_id, profileLoading } = useAccountContext(); // Get user_id and profileLoading
  const queryClient = useQueryClient();
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const fetchUserTasks = async () => {
    console.log("User ID:", user_id);
    if (!user_id) return [];

    const response = await axios.get(`${API_BASE_URL}/tasks`, {
      params: { account_id: user_id },
    });

    if (response.status !== 200) {
      throw new Error(response.data?.error || "Failed to fetch tasks");
    }
    console.log("Tasks data:", response.data);
    return response.data.tasks;
  };

  const {
    data: tasks,
    isLoading: tasksLoading,
    error: tasksError,
  } = useQuery({
    queryKey: ["userTasks", user_id],
    queryFn: fetchUserTasks,
    enabled: !!user_id && !profileLoading,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const createTask = useMutation({
    mutationFn: async (newTask) => {
      const response = await axios.post(`${API_BASE_URL}/tasks`, newTask);

      if (response.status !== 201) {
        throw new Error(response.data?.error || "Failed to create task");
      }

      return response.data.task;
    },
    onMutate: async (newTask) => {
      await queryClient.cancelQueries(["userTasks", user_id]);
      const previousTasks = queryClient.getQueryData(["userTasks", user_id]);
      queryClient.setQueryData(["userTasks", user_id], (old) => [
        ...(old || []),
        { ...newTask, id: Date.now() },
      ]);
      return { previousTasks };
    },
    onError: (err, newTask, context) => {
      queryClient.setQueryData(["userTasks", user_id], context.previousTasks);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userTasks", user_id]);
    },
  });

  const updateTask = useMutation({
    mutationFn: async ({ id, updatedFields }) => {
      const response = await axios.put(
        `${API_BASE_URL}/tasks/${id}`,
        updatedFields
      );

      if (response.status !== 200) {
        throw new Error(response.data?.error || "Failed to update task");
      }

      return response.data.task;
    },
    onMutate: async ({ id, updatedFields }) => {
      await queryClient.cancelQueries(["userTasks", user_id]);
      const previousTasks = queryClient.getQueryData(["userTasks", user_id]);
      queryClient.setQueryData(["userTasks", user_id], (old) =>
        old.map((task) =>
          task.id === id ? { ...task, ...updatedFields } : task
        )
      );
      return { previousTasks };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(["userTasks", user_id], context.previousTasks);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userTasks", user_id]);
    },
  });

  const deleteTask = useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(`${API_BASE_URL}/tasks/${id}`);

      if (response.status !== 200) {
        throw new Error(response.data?.error || "Failed to delete task");
      }

      return id;
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries(["userTasks", user_id]);
      const previousTasks = queryClient.getQueryData(["userTasks", user_id]);
      queryClient.setQueryData(["userTasks", user_id], (old) =>
        old.filter((task) => task.id !== id)
      );
      return { previousTasks };
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(["userTasks", user_id], context.previousTasks);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userTasks", user_id]);
    },
  });

  return (
    <TasksContext.Provider
      value={{
        tasks: tasks || [],
        tasksLoading,
        tasksError,
        createTask: createTask.mutateAsync,
        updateTask: updateTask.mutateAsync,
        deleteTask: deleteTask.mutateAsync,
      }}
    >
      {children}
    </TasksContext.Provider>
  );
};

export const useTasksContext = () => useContext(TasksContext);
