import React from "react";
import { AccountProvider } from "../context/AccountContext";
import { AuthProvider } from "../context/AuthContext";
import { AdminProvider } from "../context/AdminContext";
import { ArtemisProvider } from "../context/ArtemisContext";
import { PaymentProvider } from "../context/PaymentContext";
import { EmailProvider } from "../context/EmailContext";
import { CampaignProvider } from "../context/CampaignContext";
import { CalendarProvider } from "../context/CalendarContext";
import { SocialAccountsProvider } from "../context/SocialAccountsContext";
import { NextUIProvider } from "@nextui-org/system";

import { ContactsProvider } from "../context/ContactsContext";

import { TasksProvider } from "../context/TasksContext";

// const queryClient = new QueryClient();

const Providers = ({ children }) => {
  return (
    // <QueryClientProvider client={queryClient}>
    <AccountProvider>
      <AuthProvider>
        <ContactsProvider>
          <NextUIProvider>
            <AdminProvider>
              <ArtemisProvider>
                <PaymentProvider>
                  <CalendarProvider>
                    <EmailProvider>
                      <TasksProvider>
                        <SocialAccountsProvider>
                          <CampaignProvider>{children}</CampaignProvider>
                        </SocialAccountsProvider>
                      </TasksProvider>
                    </EmailProvider>
                  </CalendarProvider>
                </PaymentProvider>
              </ArtemisProvider>
            </AdminProvider>
          </NextUIProvider>
        </ContactsProvider>
      </AuthProvider>
    </AccountProvider>
    // </QueryClientProvider>
  );
};

export default Providers;
