import React from "react";
import styles from "./styles.module.css";
import BlockEditor from "./RichEditor/components/BlockEditor/BlockEditor";
import SliderHeaderMenu from "components/Common/SliderHeaderMenu/SliderHeaderMenu";

export default function BroadcastEditorHeader() {
  return (
    <div className={styles.broadcast_header}>
      <div className={styles.broadcast_details}>
        <BroadcastProperty title="From" value="(justin@askarcher.ai)" />
        <div className={styles.divider} />
        <BroadcastProperty title="To" />
        <div className={styles.divider} />
        <BroadcastProperty title="Subject" />
        <div className={styles.divider} />
      </div>
    </div>
  );
}

function BroadcastProperty({ title, value }) {
  return (
    <div className={styles.property}>
      <div className={styles.info_left}>
        <p className={styles.info_title}>{title}</p>
        <p className={styles.info_value}>{value}</p>
      </div>
      <p></p>
    </div>
  );
}
