import React from "react";

const TasksIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_2874790"
    height={width}
    viewBox="0 0 512 512"
    width={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="m141.255 228h-106.51c-19.159 0-34.745-15.586-34.745-34.745v-106.51c0-19.159 15.586-34.745 34.745-34.745h106.51c19.159 0 34.745 15.586 34.745 34.745v106.51c0 19.159-15.586 34.745-34.745 34.745zm-106.51-144c-1.514 0-2.745 1.231-2.745 2.745v106.51c0 1.514 1.231 2.745 2.745 2.745h106.51c1.514 0 2.745-1.231 2.745-2.745v-106.51c0-1.514-1.231-2.745-2.745-2.745z"
    ></path>
    <path
      fill={color}
      d="m141.255 460h-106.51c-19.159 0-34.745-15.586-34.745-34.745v-106.51c0-19.159 15.586-34.745 34.745-34.745h106.51c19.159 0 34.745 15.586 34.745 34.745v106.51c0 19.159-15.586 34.745-34.745 34.745zm-106.51-144c-1.514 0-2.745 1.231-2.745 2.745v106.51c0 1.514 1.231 2.745 2.745 2.745h106.51c1.514 0 2.745-1.231 2.745-2.745v-106.51c0-1.514-1.231-2.745-2.745-2.745z"
    ></path>
    <path
      fill={color}
      d="m496 132h-256c-8.836 0-16-7.164-16-16s7.164-16 16-16h256c8.836 0 16 7.164 16 16s-7.164 16-16 16z"
    ></path>
    <path
      fill={color}
      d="m408 192h-168c-8.836 0-16-7.164-16-16s7.164-16 16-16h168c8.836 0 16 7.164 16 16s-7.164 16-16 16z"
    ></path>
    <path
      fill={color}
      d="m496 360h-256c-8.836 0-16-7.164-16-16s7.164-16 16-16h256c8.836 0 16 7.164 16 16s-7.164 16-16 16z"
    ></path>
    <path
      fill={color}
      d="m408 424h-168c-8.836 0-16-7.164-16-16s7.164-16 16-16h168c8.836 0 16 7.164 16 16s-7.164 16-16 16z"
    ></path>
  </svg>
);

export default TasksIcon;
