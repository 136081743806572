import React, { useState } from "react";
import classNames from "classnames";
import styles from "./table.module.css";
import NewCheckbox from "../../../Common/NewCheckbox/NewCheckbox";
import PaginationControls from "../../../Common/PaginationControls/PaginationControls";
import More from "../../../Common/More/More";
import AccountTag from "../../../Common/AccountTag/AccountTag";

export default function Table({ columns, data, onRowClick, more_menu }) {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 30;

  const currentRows = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleRowSelect = (id) => {
    setSelectedRows((prev) => {
      const updated = new Set(prev);
      updated.has(id) ? updated.delete(id) : updated.add(id);
      return updated;
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.size === currentRows.length) {
      setSelectedRows(new Set());
    } else {
      const allIds = new Set(currentRows.map((doc) => doc.id));
      setSelectedRows(allIds);
    }
  };

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <div className={styles.tableContainer}>
      <TasksTableHeader
        columns={columns}
        currentRows={currentRows}
        selectedRows={selectedRows}
        onSelectAll={handleSelectAll}
      />
      <TasksTableBody
        currentRows={currentRows}
        columns={columns}
        selectedRows={selectedRows}
        onRowSelect={handleRowSelect}
        onRowClick={onRowClick}
        more_menu={more_menu}
      />
      <div className={styles.tableFooter}>
        <PaginationControls
          currentPage={currentPage}
          totalRows={data.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

const TasksTableHeader = ({ columns }) => (
  <div className={styles.tableHeaderRow}>
    <div className={styles.headerCheckbox}></div>
    {columns.map((column) => (
      <div key={column.uid} className={styles.tableHead}>
        {column.name}
      </div>
    ))}
    <div className={styles.moreCell}></div>
  </div>
);

const TasksTableBody = ({
  currentRows,
  columns,
  selectedRows,
  onRowSelect,
  onRowClick,
  more_menu,
}) => (
  <div className={styles.tableBody}>
    {currentRows.map((document) => (
      <TasksTableRow
        key={document._id}
        document={document}
        columns={columns}
        isSelected={selectedRows.has(document.id)}
        onRowSelect={() => onRowSelect(document.id)}
        onRowClick={onRowClick}
        more_menu={more_menu}
      />
    ))}
  </div>
);

const TasksTableRow = ({
  document,
  columns,
  isSelected,
  onRowSelect,
  onRowClick,
  more_menu,
}) => (
  <div
    className={classNames(
      styles.tableRow,
      styles.tableRowHover,
      isSelected && styles.selectedRow
    )}
  >
    <div className={styles.checkboxCell}>
      <NewCheckbox checked={isSelected} onChange={onRowSelect} />
    </div>
    {columns.map((column) => (
      <div
        key={column.uid}
        className={styles.cell}
        onClick={() => onRowClick(document)}
      >
        {renderCell(document, column.uid)}
      </div>
    ))}
    <div className={styles.moreCell}>
      <More more_menu={more_menu} item={document} />
    </div>
  </div>
);

const renderCell = (document, columnKey) => {
  const cellValue = document[columnKey];
  switch (columnKey) {
    case "name":
      return <span>{cellValue}</span>;

    case "due_date":
      return <span>{formatToMonthDay(cellValue)}</span>;

    case "assigned":
      return (
        <div className={styles.account_tag}>
          <AccountTag />
        </div>
      );
    default:
      return cellValue;
  }
};

function formatToMonthDay(dateString) {
  const date = new Date(dateString);

  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
  });

  return formatter.format(date);
}
