import React, { useState } from "react";
import trigger_options from "./WorkflowData/trigger_options";
import action_options from "./WorkflowData/action_options";
import styles from "./styles.module.css";
import WorkflowIcon from "../../../../assets/SVG/NewSidebarIcons/WorkflowIcon";
import TriggerIcon from "../../../../assets/SVG/BlueprintIcons/TriggerIcon";
import DocumentationIcon from "../../../../assets/SVG/BlueprintIcons/DocumentationIcon";
import TemplatesIcon from "../../../../assets/SVG/BlueprintIcons/TemplatesIcon";
import FullBackArrow from "../../../../assets/SVG/FullBackArrow";

export default function EditorSidebar({
  selectedNode,

  setNodes,
  setEdges,
  setSelectedNode,
  view,
  setView,
  handleNewNodeSelect,
}) {
  const showBlueprintProperties = () => setView("blueprintProperties");

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar_content}>
        {view === "blueprintProperties" && (
          <BlueprintProperties
            showTriggerSelection={() => setView("selectTrigger")}
          />
        )}
        {view === "selectTrigger" && (
          <SelectTrigger handleNewNodeSelect={handleNewNodeSelect} />
        )}
        {view === "nodeProperties" && selectedNode && (
          <NodeProperties
            selectedNode={selectedNode}
            showBlueprintProperties={showBlueprintProperties}
          />
        )}
      </div>
    </div>
  );
}

function BlueprintProperties({ showTriggerSelection }) {
  return (
    <div className={styles.node_properties_layout}>
      <div className={styles.node_properties_content}>
        <div className={styles.node_properties_header_section}>
          <div className={styles.node_properties_header_top}>
            <div className={styles.option_tile_left}>
              <div className={styles.workflow_properties_icon_box}>
                <WorkflowIcon color="white" />
              </div>
              Untitled Workflow
            </div>
          </div>
          <div className={styles.node_properties_header_bottom}>
            <textarea
              className={styles.node_property_desc_text_area}
              placeholder="Add description..."
            ></textarea>
          </div>
        </div>
      </div>
      <SidebarFooter />
    </div>
  );
}

function SelectTrigger({ handleNewNodeSelect }) {
  return (
    <div className={styles.select_trigger_layout}>
      <div className={styles.select_trigger_titles}>
        <p className={styles.select_trigger_title}>Select a Trigger</p>
        <p className={styles.select_trigger_subtitle}>
          Choose an event to activate this workflow
        </p>
      </div>
      <input
        className={styles.select_trigger_search}
        placeholder="Search Triggers..."
      />
      <div className={styles.option_scroll_view}>
        {trigger_options.map((option) => (
          <TriggerOption
            key={option.title}
            option={option}
            onSelect={handleNewNodeSelect}
          />
        ))}
      </div>
    </div>
  );
}

function TriggerOption({ option, onSelect }) {
  return (
    <div className={styles.option_tile} onClick={() => onSelect(option)}>
      <div className={styles.option_tile_left}>
        <div className={styles.icon_box}>
          <option.icon color="var(--icon-color)" width={15} height={15} />
        </div>
        {option.title}
      </div>
    </div>
  );
}

function NodeProperties({
  selectedNode,

  showBlueprintProperties,
}) {
  return (
    <div className={styles.node_properties_layout}>
      <div className={styles.node_nav_controls}>
        <div className={styles.back_arrow} onClick={showBlueprintProperties}>
          <FullBackArrow width={16} height={16} />
        </div>
      </div>
      {selectedNode?.data.title ? (
        <div className={styles.node_properties_header_section}>
          <div className={styles.node_properties_header_top}>
            <div className={styles.option_tile_left}>
              <div className={styles.node_properties_icon_box}>
                {/* Uncomment if selectedNode.icon is available */}
                {/* <selectedNode.icon color="var(--icon-color)" width={15} height={15} /> */}
              </div>
              {selectedNode.data.title}
            </div>
          </div>
          <div className={styles.node_properties_header_bottom}>
            <textarea
              className={styles.node_property_desc_text_area}
              placeholder="Add description..."
            ></textarea>
          </div>
        </div>
      ) : (
        <SelectAction />
      )}
    </div>
  );
}

function SelectAction({ handleNewNodeSelect }) {
  return (
    <div className={styles.select_trigger_layout}>
      <div className={styles.select_trigger_titles}>
        <p className={styles.select_trigger_title}>Select an action</p>
        <p className={styles.select_trigger_subtitle}>
          Choose an action to occur
        </p>
      </div>
      <input
        className={styles.select_trigger_search}
        placeholder="Search Actions..."
      />
     <div className={styles.option_scroll_view}>
        {action_options.map((section) => (
          <div key={section.section} className={styles.section}>
            <div className={styles.section_title}>{section.section}</div>
            <div className={styles.action_list}>
              {section.actions.map((action) => (
                <ActionOption
                  key={action.title}
                  option={action}
                  onSelect={handleNewNodeSelect}
                />
              ))}
            </div>
          </div>
        ))} 
      </div>
    </div>
  );
}

function ActionOption({ option, onSelect }) {
  return (
    <div className={styles.option_tile} onClick={() => onSelect(option)}>
      <div className={styles.option_tile_left}>
        <div className={styles.icon_box}>
          <option.icon color="var(--icon-color)" width={15} height={15} />
        </div>
        {option.title}
      </div>
    </div>
  );
}

function SidebarFooter() {
  return (
    <div className={styles.sidebar_footer}>
      <div className={styles.footer_tile}>
        <div className={styles.footer_tile_top}>
          <div className={styles.footer_tile_icon}>
            <DocumentationIcon width={12} height={12} />
          </div>
          <p className={styles.footer_tile_title}>Documentation</p>
        </div>
        <p className={styles.footer_tile_desc}>
          Learn about how to best set up blueprints
        </p>
      </div>
      <div className={styles.footer_tile}>
        <div className={styles.footer_tile_top}>
          <div className={styles.footer_tile_icon}>
            <TemplatesIcon width={12} height={12} />
          </div>
          <p className={styles.footer_tile_title}>Templates</p>
        </div>
        <p className={styles.footer_tile_desc}>
          Get started with ready blueprint templates
        </p>
      </div>
    </div>
  );
}
