import React, { createContext, useEffect, useContext, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { supabase } from "../services/supabase";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("authUser")) || null
  );

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        const user = session?.user ?? null;
        setCurrentUser(user);

        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));
        } else {
          localStorage.removeItem("authUser");
        }
      }
    );

    return () => {
      authListener?.unsubscribe();
    };
  }, []);

  const fetchUserProfile = async (userId) => {
    const { data, error } = await supabase
      .from("accounts")
      .select("*")
      .eq("user_id", userId)
      .single();
    if (error) throw error;
    return data;
  };

  const {
    data: profile,
    error: profileError,
    isLoading: profileLoading,
  } = useQuery({
    queryKey: ["userProfile", currentUser?.id],
    queryFn: () => fetchUserProfile(currentUser.id),
    enabled: !!currentUser,
    onError: (err) => {
      console.error("Profile query error:", err);
    },
  });

  const signInMutation = useMutation({
    mutationFn: async ({ email, password }) => {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw new Error(error.message);

      setCurrentUser(data.user);
      localStorage.setItem("authUser", JSON.stringify(data.user));
      return data.user;
    },
    onError: (err) => {
      console.error("Sign-in mutation error:", err);
    },
    onSuccess: (data) => {
      console.log("User signed in successfully:", data);
    },
  });

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        profile,
        profileLoading,
        profileError,
        signIn: signInMutation.mutateAsync,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
