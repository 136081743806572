import React from "react";

const MoreIconHorizontal = ({
  width = 12,
  height = 12,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    version="1.1"
    id="fi_512142"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 426.667 426.667"
  >
    <g>
      <g>
        <circle fill={color} cx="42.667" cy="213.333" r="42.667"></circle>
      </g>
    </g>
    <g>
      <g>
        <circle fill={color} cx="213.333" cy="213.333" r="42.667"></circle>
      </g>
    </g>
    <g>
      <g>
        <circle fill={color} cx="384" cy="213.333" r="42.667"></circle>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default MoreIconHorizontal;
