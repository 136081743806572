import React from "react";

const LanguageIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3269817"
    enable-background="new 0 0 512 512"
    height={height}
    viewBox="0 0 512 512"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="XMLID_59_">
      <g id="XMLID_969_">
        <path
          id="XMLID_1003_"
          d="m267 87.5c-5.522 0-10 4.477-10 10s4.478 10 10 10h32.877c.068 23.807 7.794 46.044 20.954 64.195-13.635 13.29-31.496 22.362-51.575 24.883-5.48.688-9.365 5.688-8.677 11.167.635 5.057 4.941 8.755 9.909 8.755.415 0 .836-.026 1.258-.079 24.154-3.032 45.695-13.808 62.255-29.635 16.56 15.827 38.101 26.603 62.255 29.635.423.053.842.079 1.258.079 4.967 0 9.274-3.699 9.909-8.755.688-5.48-3.196-10.48-8.677-11.167-20.079-2.521-37.941-11.592-51.575-24.883 13.159-18.152 20.886-40.388 20.954-64.195h32.875c5.522 0 10-4.477 10-10s-4.478-10-10-10h-57v-26c0-5.523-4.478-10-10-10s-10 4.477-10 10v26zm67 68.003c-8.901-13.956-14.059-30.452-14.12-48.003h28.239c-.06 17.551-5.218 34.047-14.119 48.003z"
        ></path>
        <path
          id="XMLID_1023_"
          d="m458.962 0h-79.962c-5.522 0-10 4.477-10 10s4.478 10 10 10h79.962c18.218 0 33.038 14.821 33.038 33.039v161.923c0 18.217-14.821 33.038-33.039 33.038-5.522 0-10 4.477-10 10v42.313l-58.468-49.918c-1.81-1.546-4.112-2.395-6.493-2.395h-174.962c-18.218 0-33.038-14.821-33.038-33.039v-161.922c0-18.218 14.82-33.039 33.038-33.039h79.962c5.522 0 10-4.477 10-10s-4.478-10-10-10h-79.962c-29.245 0-53.038 23.793-53.038 53.039v126.961h-102.962c-29.245 0-53.038 23.793-53.038 53.039v161.923c0 25.827 18.558 47.402 43.038 52.093v54.945c0 3.902 2.27 7.447 5.813 9.081 1.339.617 2.767.919 4.186.919 2.337 0 4.648-.818 6.495-2.395l72.156-61.605h171.273c29.246 0 53.039-23.793 53.039-53.039v-126.961h24.312l72.156 61.605c1.847 1.577 4.158 2.396 6.495 2.395 1.419 0 2.847-.302 4.186-.919 3.543-1.634 5.813-5.179 5.813-9.081l.001-54.945c24.48-4.691 43.038-26.266 43.038-52.093v-161.923c-.001-29.246-23.794-53.039-53.039-53.039zm-280.799 279.506 19.812 52.496h-39.805zm157.837 115.455c0 18.218-14.82 33.039-33.038 33.039h-174.962c-2.381 0-4.683.849-6.493 2.395l-58.469 49.919v-42.314c0-5.523-4.478-10-10-10-18.218 0-33.038-14.821-33.038-33.039v-161.922c0-18.218 14.82-33.039 33.038-33.039h102.962v14.961c0 13.348 4.967 25.55 13.135 34.883-1.083 1.126-1.969 2.464-2.587 3.974-.031.076-.062.152-.09.229l-42.804 112.388c-1.966 5.161.624 10.938 5.785 12.904 5.161 1.965 10.938-.625 12.904-5.786l8.208-21.55h54.97l8.122 21.521c1.511 4.005 5.315 6.472 9.357 6.472 1.173 0 2.366-.208 3.529-.647 5.167-1.95 7.774-7.72 5.824-12.887l-37.938-100.528c4.648 1.335 9.549 2.066 14.62 2.066h126.965z"
        ></path>
        <path
          id="XMLID_1025_"
          d="m334 20c2.63 0 5.21-1.07 7.069-2.93 1.861-1.86 2.931-4.44 2.931-7.07s-1.07-5.21-2.931-7.07c-1.859-1.86-4.439-2.93-7.069-2.93s-5.21 1.07-7.07 2.93c-1.861 1.86-2.93 4.44-2.93 7.07s1.069 5.21 2.93 7.07 4.44 2.93 7.07 2.93z"
        ></path>
      </g>
    </g>
  </svg>
);

export default LanguageIcon;
