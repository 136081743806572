import React from "react";

const MobileIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_10595861"
    height={height}
    viewBox="0 0 33.866666 33.866668"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="layer1"
      fill="#3b3c3d"
      fill-rule="evenodd"
      transform="translate(0 -263.133)"
    >
      <path
        id="path28439-0-0"
        d="m17.860999 263.13328c-1.383347 0-2.513541 1.1302-2.513541 2.51354v.88212h.330729v.19844h-.330729v1.18907a.13230489.13230489 0 0 0 -.0026 0 .13230489.13230489 0 0 0 -.130228.13384v.79375a.13230489.13230489 0 0 0 .132292.13385v1.18752a.13230489.13230489 0 0 0 -.0026 0 .13230489.13230489 0 0 0 -.130228.13384v2.11667a.13230489.13230489 0 0 0 .132292.13384v.79065a.13230489.13230489 0 0 0 -.0026 0 .13230489.13230489 0 0 0 -.130228.13384v1.98438a.13230489.13230489 0 0 0 .132291.13384v17.79168h.33073v.19844h-.33073v.90382c0 1.38334 1.130195 2.51354 2.513542 2.51354h11.773958c1.383348 0 2.513542-1.1302 2.513542-2.51354v-.90382h-.330729v-.19844h.330729v-18.44177a.13230489.13230489 0 0 0 .132292-.13384v-3.70417a.13230489.13230489 0 0 0 -.132292-.13384v-4.24315h-.330729v-.19844h.330729v-.88212c0-1.38334-1.130194-2.51354-2.513542-2.51354zm0 1.05834h1.584907a.26458333.26458333 0 0 1 .264584.26458v.13229a.79374999.79374999 0 0 0 .79375.79375h6.548437a.79374999.79374999 0 0 0 .79375-.79375v-.13229a.26458333.26458333 0 0 1 .264583-.26458h1.523948c.81534 0 1.455208.63986 1.455208 1.4552v28.83959c0 .81534-.639868 1.4552-1.455208 1.4552h-11.773959c-.81534 0-1.455208-.63986-1.455208-1.4552v-28.83959c0-.81534.639868-1.4552 1.455208-1.4552z"
        font-variant-ligatures="normal"
        font-variant-position="normal"
        font-variant-caps="normal"
        font-variant-numeric="normal"
        font-variant-alternates="normal"
        font-feature-settings="normal"
        text-indent="0"
        text-align="start"
        text-decoration-line="none"
        text-decoration-style="solid"
        text-decoration-color="#000000"
        text-transform="none"
        text-orientation="mixed"
        white-space="normal"
        shape-padding="0"
        isolation="auto"
        mix-blend-mode="normal"
        solid-color="#000000"
        solid-opacity="1"
      ></path>
      <path
        id="rect18714-7-5-3"
        d="m4.1011605 263.13336c-1.3833475 0-2.5135417 1.1302-2.5135417 2.51354v.88212h.3307292v.19844h-.3307292v26.65677h.3307292v.19844h-.3307292v.90382c0 1.38334 1.1301942 2.51354 2.5135417 2.51354h11.7739585c.158019 0 .312398-.0161.462505-.0444-.648608-.12208-1.209493-.49541-1.580267-1.01389h-10.6561965c-.8153374 0-1.4552083-.63986-1.4552083-1.4552v-28.83964c0-.81534.6398709-1.45521 1.4552083-1.45521h10.6561965c.370869-.51861.931963-.89189 1.580782-1.01389-.150264-.0282-.304821-.0444-.46302-.0444z"
        font-variant-ligatures="normal"
        font-variant-position="normal"
        font-variant-caps="normal"
        font-variant-numeric="normal"
        font-variant-alternates="normal"
        font-feature-settings="normal"
        text-indent="0"
        text-align="start"
        text-decoration-line="none"
        text-decoration-style="solid"
        text-decoration-color="#000000"
        text-transform="none"
        text-orientation="mixed"
        white-space="normal"
        shape-padding="0"
        isolation="auto"
        mix-blend-mode="normal"
        solid-color="#000000"
        solid-opacity="1"
      ></path>
      <path
        id="path13760-7-1-8-7"
        d="m9.5607661 272.084c-.6702822 0-1.2138369.54322-1.2138369 1.21304 0 .66977.4994513 1.94492 1.1697335 1.94492.1487963 0 .3208311-.0647.4712308-.13628.077732-.037.2003905-.0374.2781275 0 .1504.0715.322437.13644.471233.13668.446855 0 .813562-.56679 1.011198-1.1431a.91544287.91544287 0 0 1 -.553476-.84035.91544287.91544287 0 0 1 .40885-.76168c-.222417-.25262-.547505-.41283-.910678-.41283-.156101 0-.320527.10417-.464473.15735-.06361.0236-.160475.0236-.224094 0-.1439468-.0531-.2877161-.15711-.4438149-.15735z"
      ></path>
      <path
        id="path27861-2-8-03"
        d="m10.725404 271.71361c-.233564.26869-.592305.29377-.592305.29377s-.02508-.35875.208476-.62744c.233559-.26865.592302-.29376.592302-.29376s.02508.35875-.208473.62743z"
      ></path>
      <path
        id="rect27961-5-3"
        d="m4.9608102 265.2501c-.696251 0-1.2567708.59003-1.2567708 1.32292v3.175c0 .7329.5605198 1.32292 1.2567708 1.32292.6962511 0 1.2567709-.59002 1.2567709-1.32292v-3.175c0-.73289-.5605198-1.32292-1.2567709-1.32292zm0 .72708c.3264324 0 .5953125.2689.5953125.59531 0 .32644-.2688801.59531-.5953125.59531-.3264323 0-.5953125-.26887-.5953125-.59531 0-.32641.2688802-.59531.5953125-.59531zm0 .39687c-.1119452 0-.1984375.0865-.1984375.19844 0 .11195.086492.19844.1984375.19844.1119452 0 .1984375-.0865.1984375-.19844s-.086492-.19844-.1984375-.19844zm0 1.3896a.396875.396875 0 0 1 .396875.39687.396875.396875 0 0 1 -.396875.39688.396875.396875 0 0 1 -.396875-.39688.396875.396875 0 0 1 .396875-.39687zm0 1.38906c.3264324 0 .5953125.26887.5953125.59531 0 .32642-.2688801.59531-.5953125.59531-.3264323 0-.5953125-.26889-.5953125-.59531 0-.32644.2688802-.59531.5953125-.59531zm0 .39687c-.1119452 0-.1984375.0865-.1984375.19844 0 .11195.086492.19844.1984375.19844.1119452 0 .1984375-.0865.1984375-.19844s-.086492-.19844-.1984375-.19844z"
      ></path>
    </g>
  </svg>
);

export default MobileIcon;
