import React from "react";
import styles from "./styles.module.css";

import ScratchIcon from "assets/SVG/FunnelsIcons/ScratchIcon";
import ImportIcon from "assets/SVG/FunnelsIcons/ImportIcon";
import AskArcherIcon from "assets/SVG/NewSidebarIcons/AskArcherIcon";

export default function FunnelNew() {
  return (
    <div className={styles.new_funnel}>
      <p className={styles.new_funnel_title}>
        How would you like to create your funnel?
      </p>
      <div className={styles.new_funnel_type_options}>
        <div className={styles.new_funnel_option}>
          <AskArcherIcon width={40} height={40} />
          <p className={styles.create_title}>Ask Archer</p>
          <p className={styles.create_subtitle}>
            Ask Archer to help you generate your form!
          </p>
        </div>
        <div className={styles.new_funnel_option}>
          <ScratchIcon width={40} height={40} />
          <p className={styles.create_title}>Start from scratch</p>
          <p className={styles.create_subtitle}>
            Create your own funnel from our beautiful pre made elements!
          </p>
        </div>
        <div className={styles.new_funnel_option}>
          <ImportIcon width={40} height={40} />
          <p className={styles.create_title}>Import questions</p>
          <p className={styles.create_subtitle}>
            Copy and paste questions and we'll create a beautiful funnel!
          </p>
        </div>
      </div>
    </div>
  );
}
