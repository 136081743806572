import React from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";

import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";

import { useNavigate } from "react-router-dom";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

import Table from "./Table/Table";

export default function LeadForms() {
  const quickstartPlaceholder =
    "Example: Create a contact form for my newsletter";

  const navigate = useNavigate();

  function handleFunnelEditor() {
    console.log("Navigating to funnel editor");
    navigate("/funnels/editor");
  }

  return (
    <div className={styles.main_layout}>
      <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <ToolbarExposed feature="Lead Form" createFunction={handleFunnelEditor} />
      <div className={styles.page_layout}>
        <div className={styles.table_layout}>
          <Table
            columns={columns}
            data={data}
            statusColorMap={statusColorMap}
          />
        </div>
      </div>
    </div>
  );
}

function Tile() {
  return (
    <div className={styles.tile}>
      <div className={styles.tile_header}>
        <p className={styles.tile_title}>Website Lead Gen</p>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body}>
        {/* <p className={styles.tile_subject}>Justin x LongJump VC</p>
        <p className={styles.tile_preview}>
          Hey [First Name], I'm thrilled about the opportunity to connect. Let's
          explore potential synergies between Archer and LongJump VC. Looking
          forward to discussing how we can bring value together! Let's stay in
          touch and talk soon!
        </p> */}
      </div>
      <div className={styles.tile_footer}>
        <AccountTag />
        <p className={styles.last_updated}>Updated Today</p>
      </div>
    </div>
  );
}

const columns = [
  { name: "Funnel", uid: "funnel" },
  { name: "Visits", uid: "visits" },
  { name: "Completions", uid: "completions" },
  { name: "Conversions", uid: "conversions" },
];

const data = [
  {
    id: 1,
    funnel: "Website Typeform Replacement",
    visits: "532",
    completions: "498",
    conversions: "High",
  },
  {
    id: 2,
    funnel: "Lead Qualifer Quiz",
    visits: "2925",
    completions: "2329",
    conversions: "High",
  },
];

const statusColorMap = {
  Pending: {
    background: "rgba(204, 153, 0, 0.1)", // Dark yellow background at 0.1 opacity
    text: "rgba(204, 153, 0, 1)", // Dark yellow text at full opacity
  },
  Signed: {
    background: "rgba(0, 77, 36, 0.1)", // Dark green background at 0.1 opacity
    text: "rgba(0, 77, 36, 1)", // Dark green text at full opacity
  },
  Expired: {
    background: "rgba(200, 0, 0, 0.1)", // Red background at 0.1 opacity
    text: "rgba(200, 0, 0, 1)", // Red text at full opacity
  },
};
