import React from "react";

const InboxIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    id="fi_14478193"
    width={width}
    height={height}
  >
    <g id="Layer_10" data-name="Layer 10">
      <path
        fill={color}
        d="M15.50977,16.57617a1.00223,1.00223,0,0,0,.97949.001L29,9.53973V9a3.00328,3.00328,0,0,0-3-3H6A3.00328,3.00328,0,0,0,3,9v.53961Z"
      ></path>
      <path
        fill={color}
        d="M17.4707,18.32031a3.015,3.015,0,0,1-2.94238-.001L3,11.83441V23a3.00328,3.00328,0,0,0,3,3H26a3.00328,3.00328,0,0,0,3-3V11.83453Z"
      ></path>
    </g>
  </svg>
);

export default InboxIcon;
