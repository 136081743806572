import ManuallyTriggerIcon from "../../../../../assets/SVG/BlueprintIcons/ManuallyTriggerIcon";
import RecurringScheduleIcon from "../../../../../assets/SVG/BlueprintIcons/RecurringScheduleIcon";
import WebhookIcon from "../../../../../assets/SVG/BlueprintIcons/WebhookIcon";
import FormSubmissionIcon from "../../../../../assets/SVG/BlueprintIcons/FormSubmissionIcon";
import TaskCreatedIcon from "../../../../../assets/SVG/BlueprintIcons/TaskCreatedIcon";

const trigger_options = [
  {
    title: "Manually Trigger",
    icon: ManuallyTriggerIcon,
    configFields: [], // No extra fields required
    dataProvided: ["contactRecord"],
  },
  {
    title: "Recurring Schedule",
    icon: RecurringScheduleIcon,
    configFields: [
      {
        name: "frequency",
        type: "select",
        options: ["Daily", "Weekly", "Monthly", "Custom"],
      },
      { name: "startDate", type: "date" },
      { name: "endDate", type: "date", optional: true },
      { name: "interval", type: "number", unit: "days", optional: true },
    ],
    dataProvided: ["scheduledTime", "recurrenceDetails"],
  },
  {
    title: "Webhook Hit",
    icon: RecurringScheduleIcon,
    configFields: [
      { name: "webhookURL", type: "text", readOnly: true }, // Pre-generated URL
      { name: "expectedPayload", type: "jsonEditor" },
      { name: "testPayload", type: "button", action: "sendTestPayload" },
    ],
    dataProvided: ["payloadData"],
  },
  {
    title: "Form Submission",
    icon: FormSubmissionIcon,
    configFields: [
      { name: "formSelection", type: "select", options: "userForms" }, // Dynamically populated options
      { name: "fieldMapping", type: "mappingEditor" },
    ],
    dataProvided: ["formData"],
  },
  {
    title: "Task Created",
    icon: TaskCreatedIcon,
    configFields: [
      {
        name: "taskType",
        type: "select",
        options: ["Meeting", "Call", "Email"],
      },
      {
        name: "associatedRecord",
        type: "select",
        options: ["All", "Specific Contacts"],
      },
    ],
    dataProvided: ["taskDetails", "contactRecord"],
  },
  // Additional triggers
  //   {
  //     title: "Email Received",
  //     icon: EmailReceivedIcon,
  //     configFields: [
  //       {
  //         name: "filterType",
  //         type: "select",
  //         options: ["Sender", "Subject", "Email Type"],
  //       },
  //       { name: "filterValue", type: "text" },
  //     ],
  //     dataProvided: ["emailContent", "contactRecord"],
  //   },
  // ... other triggers
];

export default trigger_options;
