import React from "react";

const WorkspaceSettingsIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_9512315"
    enable-background="new 0 0 511.876 511.876"
    width={width}
    height={height}
    viewBox="0 0 511.876 511.876"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m460.452 481.876v-367.829h-77.563v-114.047l-253.9 71.188v101.95h-77.564v308.738h-31.503v30h472.033v-30zm-379.027 0v-278.738h125.992v278.738zm155.992 0v-308.738h-78.429v-79.204l193.899-54.365-.433 442.308h-115.037zm145.471 0v-337.829h47.563v337.829z"></path>
      <path d="m129.421 250.516h30v60.341h-30z"></path>
      <path d="m129.421 360.718h30v60.341h-30z"></path>
      <path d="m274.459 250.516h30v60.341h-30z"></path>
      <path d="m274.459 140.314h30v60.341h-30z"></path>
      <path d="m274.459 360.718h30v60.341h-30z"></path>
    </g>
  </svg>
);

export default WorkspaceSettingsIcon;
