import { useState, useRef, useContext } from "react";
import React from "react";
import styles from "./lists.module.css";
import { useNavigate } from "react-router-dom";

import PlayIcon from "../../../assets/SVG/PlayIcon";
import PauseIcon from "../../../assets/SVG/PauseIcon";

import CircularLoader from "../../Common/CircularLoader/CircularLoader";

import { CampaignContext } from "../../../context/CampaignContext";

import MorePopup from "../../Common/MorePopup/MorePopUp";

import { toast } from "react-toastify";

import LocationIcon from "../../../assets/SVG/ContactIcons/LocationIcon";
import EmailIcon from "../../../assets/SVG/ContactIcons/EmailIcon";
import PhoneIcon from "../../../assets/SVG/ContactIcons/PhoneIcon";

import AddContactIcon from "../../../assets/SVG/AddContactIcon";

import LinkedinIcon from "../../../assets/SVG/PlaybookIcons.js/LinkedinIcon";

import CampaignActionIcon from "../../../assets/SVG/ActionIcons/CampaignActionIcon";
import PlayActionIcon from "../../../assets/SVG/ActionIcons/PlayActionIcon.js.js";

export default function ListTileLarge({ contact_item }) {
  const [contact, setContact] = useState(contact_item);
  const [isLoadingControl, setLoadingControl] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglePopup = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { label: "Edit", action: () => console.log("Edit action") },
    { label: "Delete", action: () => console.log("Delete action") },
    // { label: "Archive", action: () => console.log("Archive action") },
  ];

  const keywords = ["Sales", "SaaS", "B2B Sales", "Tech"];

  return (
    <div className={styles.contact_tile_large}>
      <div className={styles.contact_tile_top}>
        <div className={styles.contact_person_information}>
          <img
            className={styles.contact_image_large}
            src={contact.contact_image}
          ></img>
          <div className={styles.contact_person_text}>
            <p className={styles.person_name_large}>
              {contact.name} <LinkedinIcon width={15} height={15} />
            </p>
            <p className={styles.person_subtitle}>{contact.role}</p>
          </div>
        </div>
        <div className={styles.company_information}>
          <img
            className={styles.company_tile_image}
            src={contact.company_image}
          ></img>
          <div className={styles.contact_person_text}>
            <p className={styles.company_tile_name}>{contact.company}</p>
            <p className={styles.company_tile_industry}>{contact.industry}</p>
          </div>
        </div>
        <div className={styles.keyword_information}>
          {keywords.map((keyword, index) => (
            <div className={styles.keyword_tile} key={index}>
              {keyword}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.contact_tile_middle}>
        <div className={styles.contact_tile_info_text}>
          <LocationIcon width={13} height={13} color="rgba(0, 0, 0, 1)" />
          Chicago, IL
        </div>
        <div className={styles.contact_tile_info_text}>
          <EmailIcon width={13} height={13} color="rgba(0, 0, 0, 1)" />3 Emails
        </div>
        <div className={styles.contact_tile_info_text}>
          <PhoneIcon width={13} height={13} color="rgba(0, 0, 0, 1)" />2 Phone
          Numbers
        </div>
      </div>
      <div className={styles.contact_tile_bottom}>
        <div className={styles.contact_button_controls}>
          <div className={styles.contact_button_control_side}>
            <div className={styles.filled_button}>Execute Blueprint</div>
            <div className={styles.unfilled_button}>Create Record</div>
          </div>
          <div className={styles.contact_button_control_side}>
            <div className={styles.send_email_button}>Send Email</div>
            {/* <div className={styles.bottom_right_button}></div>
            <div className={styles.bottom_right_button}>
              <AddContactIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
            </div> */}
            {/* <div className={styles.bottom_right_button}>
              <PlayActionIcon color="rgba(0, 0, 0, 1)" width={12} height={12} />
            </div>
            <div className={styles.bottom_right_button}>
              <CampaignActionIcon
                color="rgba(0, 0, 0, 1)"
                width={10}
                height={10}
              />
            </div> */}
            {/* <div className={styles.unfilled_button}>Message</div> */}
          </div>
        </div>
      </div>

      {/* <div className={styles.table_row}>
        <div className={styles.table_value}>
          <img
            className={styles.contact_image_large}
            src={contact.contact_image}
          ></img>
          <div className={styles.contact_person_text}>
            <p className={styles.person_name}>{contact.name}</p>
            <p className={styles.person_email}>{contact.email}</p>
          </div>
        </div>
        <div className={styles.table_value}>{contact.company}</div>
        <div className={styles.table_value}>{contact.role}</div>
        <div className={styles.table_value}>{contact.location}</div>
        <div className={styles.table_value}>{contact.industry}</div>
      </div> */}
      <MorePopup
        items={menuItems}
        isOpen={isMenuOpen}
        togglePopup={togglePopup}
        closeMenu={() => setIsMenuOpen(false)}
      />
    </div>
  );
}

function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
