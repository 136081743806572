import React from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";

import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";

import { useNavigate } from "react-router-dom";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

import SliderHeaderMenu from "components/Common/SliderHeaderMenu/SliderHeaderMenu";

export default function Blueprints() {
  const quickstartPlaceholder =
    "Example: Send a LinkedIn connection request and create an account when a lead completes a form";

  const navigate = useNavigate();

  function handleEditor() {
    console.log("Navigating to funnel editor");
    navigate("/blueprints/editor");
  }

  const menuItems = [
    {
      icon: "🛠️",
      label: "Blueprints",
    },
    {
      icon: "📈",
      label: "Campaigns",
    },
    {
      icon: "📄",
      label: "Templates",
    },
    {
      icon: "📊",
      label: "Analytics",
    },
  ];

  const [activeTab, setActiveTab] = useState(menuItems[0]);

  return (
    <div className={styles.main_layout}>
      {/* <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      /> */}
      <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <ToolbarExposed feature="Blueprint" createFunction={handleEditor} />
      <div className={styles.page_layout}>
        <div className={styles.tile_layout}>
          <Tile />
        </div>
      </div>
    </div>
  );
}

function Tile() {
  return (
    <div className={styles.tile}>
      <div className={styles.tile_header}>
        <p className={styles.tile_title}>Weekly Prospecting Outreach</p>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body}>
        <img
          className={styles.placeholder_image}
          src="./WorkflowPlaceholderImage.png"
        ></img>
      </div>
      <div className={styles.tile_footer}>
        <AccountTag />
        <p className={styles.last_updated}>Updated Today</p>
      </div>
    </div>
  );
}

// import { useState, useRef, useEffect, useContext } from "react";
// import React from "react";
// import styles from "./styles.module.css";
// import TableControls from "../../Common/TableControls/TableControls";
// import AddIcon from "../../../assets/SVG/AddIcon";
// import funnels_data from "../../../data/funnels_data";
// import BlueprintTile from "./BlueprintTile";

// import { useNavigate } from "react-router-dom";

// import { getCSSVariable } from "../../../utils/getCSSVariable";

// import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";

// import Toolbar from "../../Common/Toolbar/Toolbar";
// import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

// export default function Blueprints() {
//   const navigate = useNavigate();

//   function handleBlueprintEditor() {
//     console.log("Navigating to blueprint editor");
//     navigate("/blueprints/editor");
//   }

//   const quickstartPlaceholder =
//     "Example: When I add a reaction to a Slack message, create a card in Trello";

//   return (
//     <div className={styles.main_layout}>
//       <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
//       <ToolbarExposed
//         feature="Blueprint"
//         createFunction={handleBlueprintEditor}
//       />
//       <div className={styles.integration_tiles}>
//         {funnels_data.map((funnel, index) => {
//           return <BlueprintTile funnel={funnel} key={index} />;
//         })}
//       </div>
//     </div>
//   );
// }
