import React from "react";

const NotesIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_10747281"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      fill={color}
      d="m19.9 5.37c-.39-1.38-1.57-2.48-3.08-2.7l-9.91-1.38c-.99-.14-1.98.12-2.78.72s-1.32 1.48-1.46 2.47l-1.39 9.9c-.14.99.12 1.98.72 2.78s1.48 1.32 2.47 1.46l.77.11v.27c0 2.07 1.68 3.75 3.75 3.75h10c2.07 0 3.75-1.68 3.75-3.75v-10c0-1.76-1.19-3.23-2.85-3.63zm-16.7 10.89c-.36-.48-.52-1.07-.43-1.67l1.39-9.9c.08-.6.39-1.12.87-1.48.39-.3.86-.45 1.35-.45.11 0 .21 0 .32.02l9.9 1.39c1.23.17 2.09 1.31 1.92 2.54l-.87 6.19-5.2-.73c-.41-.05-.79.23-.85.64l-.73 5.2-6.19-.87c-.6-.08-1.12-.39-1.48-.87zm13.19-2.02-3.88 2.93.48-3.41 3.41.48zm4.86 4.77c0 1.24-1.01 2.25-2.25 2.25h-10c-1.24 0-2.25-1.01-2.25-2.25v-.06l4.66.65s.07 0 .1 0c.16 0 .32-.05.45-.15l6.78-5.11c.16-.12.26-.3.29-.49l.96-6.86c.75.36 1.25 1.13 1.25 2.01v10z"
    ></path>
  </svg>
);

export default NotesIcon;
