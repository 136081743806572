import React from "react";
import styles from "./styles.module.css";

export default function AccountTag({
  name = "Justin Cose",
  image = "/JustinImagePlaceholder.jpeg",
}) {
  return (
    <div className={styles.account_tag}>
      <img className={styles.account_image} src={image}></img>
      <p className={styles.account_name}>{name}</p>
    </div>
  );
}
