import React from "react";
import { useState, useContext } from "react";

import styles from "../auth.module.css";

import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import CircularLoader from "../../Common/CircularLoader/CircularLoader";

const CompanyInformation = ({
  onNext,
  onBack,
  handleInputChange,
  formData,
}) => {
  const navigate = useNavigate();

  const { addRegistrationInformation } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const [companySizeDropdown, setCompanySizeDropdown] = useState(false);

  function handleSignIn() {
    navigate("/sign-in");
  }

  const canContinue =
    formData.companyName && formData.companySize && formData.position;

  async function handleAddRegistrationInformation() {
    setLoading(true);
    try {
      const register = await addRegistrationInformation(formData);

      if (register.success) {
        onNext();
      } else {
        setError(register.message);
      }
    } catch (error) {
      console.error("Registration error:", error);
      setError("An error occurred during registration.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.sidebar}>
      <img
        alt="logo"
        src="/BrandLinkLogoBlue.png"
        className={styles.logo_icon}
      ></img>
      <p className={styles.page_title}>Professional Background</p>
      <p className={styles.page_subtitle}>Tell us a bit more about yourself</p>
      <div className={styles.name_inputs}>
        <div className={styles.whole_input_field}>
          <p className={styles.login_input_titles}>Company Name</p>
          <div className={styles.sign_up_input}>
            <input
              value={formData.companyName}
              onChange={(event) =>
                handleInputChange("companyName", event.target.value)
              }
              className={styles.input}
            ></input>
          </div>
        </div>
        <div className={styles.whole_input_field}>
          <p className={styles.login_input_titles}>Company Site (Optional)</p>
          <div className={styles.sign_up_input}>
            <input
              value={formData.companyWebsite}
              onChange={(event) =>
                handleInputChange("companyWebsite", event.target.value)
              }
              className={styles.input}
            ></input>
          </div>
        </div>
      </div>

      <p className={styles.login_input_titles}>Your Role</p>
      <div className={styles.sign_up_input}>
        <input
          value={formData.position}
          onChange={(event) =>
            handleInputChange("position", event.target.value)
          }
          className={styles.input}
        ></input>
      </div>
      <p className={styles.login_input_titles}>Company Size</p>
      <div
        className={styles.sign_up_input}
        onClick={() => setCompanySizeDropdown(!companySizeDropdown)}
      >
        <input
          readonly
          value={formData.companySize}
          onChange={(event) =>
            handleInputChange("companySize", event.target.value)
          }
          className={styles.input}
        ></input>
        <img
          src="/images/general_icons/DownArrowIcon.png"
          alt="down"
          className={`${styles.down_icon} ${
            companySizeDropdown ? styles.rotate_180 : ""
          }`}
        />
        {companySizeDropdown && (
          <div className={styles.dropdown_selection}>
            {companySizes?.map((option, index) => (
              <div
                className={styles.dropdown_selection_options}
                onClick={() => handleInputChange("companySize", option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.spacer}></div>
      {error && <p className={styles.error_text}>{error}</p>}
      {canContinue ? (
        <div
          className={styles.page_button}
          onClick={handleAddRegistrationInformation}
        >
          {loading ? (
            <CircularLoader size="30%" color="rgba(255, 255, 255, 1)" />
          ) : (
            <p className={styles.join_button_text}>Continue</p>
          )}
        </div>
      ) : (
        <div className={styles.page_button_inactive}>
          <p className={styles.join_button_text}>Continue</p>
        </div>
      )}
      <div className={styles.bottom_controls}>
        <p className={styles.previous_step} onClick={onBack}>
          Previous Step
        </p>
        <p className={styles.special_control_next} onClick={onNext}>
          I'm an individual
        </p>
      </div>
    </div>
  );
};

export default CompanyInformation;

const companySizes = [
  "0-1",
  "2-10",
  "11-50",
  "51-100",
  "101-500",
  "501-1000",
  "1000+",
];

const CompanyIndustries = [
  "Accounting",
  "Administration & Office Support",
  "Advertising, Arts & Media",
  "Banking & Financial Services",
  "Call Centre & Customer Service",
  "Community Services & Development",
  "Construction",
  "Consulting & Strategy",
  "Design & Architecture",
  "Education & Training",
  "Engineering",
  "Farming, Animals & Conservation",
  "Government & Defence",
  "Healthcare & Medical",
  "Hospitality & Tourism",
  "Human Resources & Recruitment",
  "Information & Communication Technology",
  "Insurance & Superannuation",
  "Legal",
  "Manufacturing, Transport & Logistics",
  "Marketing & Communications",
  "Mining, Resources & Energy",
  "Music",
  "Real Estate & Property",
  "Retail & Consumer Products",
  "Sales",
  "Science & Technology",
  "Social Media",
  "Sport & Recreation",
  "Trades & Services",
];
