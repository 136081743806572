import React from "react";

const InboxIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_590644"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          d="M511.808,255.008c-0.064-1.056-0.352-2.048-0.64-3.104c-0.288-1.056-0.544-2.048-1.024-3.008
			c-0.16-0.32-0.16-0.64-0.32-0.96l-112-192C394.944,51.008,389.696,48,384,48H128c-5.696,0-10.944,3.008-13.824,7.936l-112,192
			c-0.16,0.288-0.16,0.64-0.32,0.96c-0.48,0.96-0.736,1.952-1.024,3.008c-0.288,1.056-0.576,2.048-0.64,3.104
			C0.192,255.36,0,255.648,0,256v176c0,17.632,14.368,32,32,32h448c17.632,0,32-14.368,32-32V256
			C512,255.648,511.808,255.36,511.808,255.008z M137.184,80h237.632l93.312,160H400c-6.048,0-11.584,3.424-14.304,8.832
			L358.112,304H153.92l-27.584-55.168C123.616,243.424,118.08,240,112,240H43.84L137.184,80z M480,448v-16H32V272h70.112
			l27.584,55.168c2.72,5.408,8.256,8.832,14.304,8.832h224c6.048,0,11.584-3.424,14.304-8.832L409.92,272H480v160c0,0,0,0,0.032,0
			L480,448z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M336,112H176c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16
			C352,119.168,344.832,112,336,112z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          d="M368,176H144c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h224c8.832,0,16-7.168,16-16
			C384,183.168,376.832,176,368,176z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default InboxIcon;
