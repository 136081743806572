import React from "react";
import ForgotPasswordComponent from "../../components/Auth/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <div>
      <ForgotPasswordComponent />
    </div>
  );
};

export default ForgotPasswordPage;
