import React from "react";
import { useContext, useState, useEffect } from "react";
import styles from "./admin_entities.module.css";
import SearchbarHeader from "../../Common/SearchbarHeader/SearchbarHeader";
import { AdminContext } from "../../../context/AdminContext";
import AddIcon from "../../../assets/SVG/AddIcon";

function AdminEntities() {
  const [searchTerm, setSearchTerm] = useState("");

  //   useEffect(() => {
  //     const filtered = allUsers.filter((user) =>
  //       user?.firstname?.toLowerCase().includes(searchTerm.toLowerCase())
  //     );
  //     setFilteredUsers(filtered);
  //   }, [searchTerm, allUsers]);

  const entities = [
    { name: "Job Position" },
    { name: "Industry" },
    { name: "Company" },
    {
      name: "Campaign Type",
      options: ["Recruitment", "Sales", "Fundraising", "Linkedin"],
    },
    { name: "Location" },
    {
      name: "Messaging Platforms",
      options: ["Email, Linkedin, Whats App, Messanger"],
    },
    {
      name: "Business Goal",
      options: ["Get new client, Nuturing Campaign, In bound Leads"],
    },
    // { name: "Location" },
    // { name: "Location" },
    // { name: "Location" },
  ];

  const handleCreateNew = async (event) => {
    event.stopPropagation();
    // setCreatePopup(true);
  };

  return (
    <div className={styles.main_layout}>
      <p className={styles.page_title}>Entities</p>
      <div className={styles.guideline_controls}>
        <SearchbarHeader
          type="EditChatbot"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <div className={styles.guideline_add} onClick={handleCreateNew}>
          <AddIcon color="rgba(0, 0, 0, 1)" width={20} height={20} />
        </div>
      </div>
      <div className={styles.entities_container}>
        {entities.map((entity, index) => {
          return (
            <div className={styles.entity_tile}>
              <div className={styles.entity_tile_left}>
                <p className={styles.entity_tile_title}>{entity.name}</p>
                <div className={styles.vertical_divider} />
                <p className={styles.entity_options}>
                  Options:{" "}
                  {entity.options && entity.options.length > 0
                    ? entity.options.map((option, index) => (
                        <span key={index}>
                          {option}
                          {index < entity.options.length - 1 && ", "}
                        </span>
                      ))
                    : "None"}
                </p>
              </div>
              <div className={styles.entity_tile_right}></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AdminEntities;
