import React from "react";

const CommentsIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    height={height}
    viewBox="0 0 32 32"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_3193015"
  >
    <g id="_x31_4_comment">
      <g>
        <path
          fill={color}
          d="m8 30c-.147 0-.295-.033-.434-.099-.345-.167-.566-.516-.566-.901v-5h-3c-1.654 0-3-1.346-3-3v-16c0-1.654 1.346-3 3-3h24c1.654 0 3 1.346 3 3v16c0 1.654-1.346 3-3 3h-12.149l-7.226 5.781c-.181.145-.402.219-.625.219zm-4-26c-.552 0-1 .449-1 1v16c0 .551.448 1 1 1h4c.553 0 1 .447 1 1v3.92l5.875-4.701c.178-.142.397-.219.625-.219h12.5c.552 0 1-.449 1-1v-16c0-.551-.448-1-1-1z"
        ></path>
      </g>
      <g>
        <path
          fill={color}
          d="m24 12h-16c-.553 0-1-.448-1-1s.447-1 1-1h16c.553 0 1 .448 1 1s-.447 1-1 1z"
        ></path>
      </g>
      <g>
        <path
          fill={color}
          d="m16 16h-8c-.553 0-1-.448-1-1s.447-1 1-1h8c.553 0 1 .448 1 1s-.447 1-1 1z"
        ></path>
      </g>
    </g>
  </svg>
);

export default CommentsIcon;
