import React from "react";

const HistoryIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_2961948"
    enable-background="new 0 0 64 64"
    width={width}
    height={height}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m31.71 34.61 12 9c.884.663 2.137.484 2.8-.4s.484-2.137-.4-2.8l-11.11-8.41v-17c0-1.105-.895-2-2-2s-2 .895-2 2v18c0 .664.278 1.262.71 1.61z"></path>
    <path d="m34 2c-14.639 0-27.14 10.566-29.58 25l-.76-1.13c-.619-.917-1.863-1.159-2.78-.54s-1.159 1.863-.54 2.78l4 6c.329.5.865.827 1.46.89h.2c.53-.002 1.037-.214 1.41-.59l5-5c.781-.781.781-2.049 0-2.83s-2.049-.781-2.83 0l-1.24 1.25c2.303-14.174 15.66-23.796 29.833-21.493s23.797 15.659 21.494 29.833c-2.045 12.586-12.916 21.832-25.667 21.83-8.042.146-15.648-3.648-20.37-10.16-.641-.9-1.89-1.111-2.79-.47s-1.111 1.89-.47 2.79c5.468 7.572 14.292 11.993 23.63 11.84 16.569 0 30-13.431 30-30s-13.431-30-30-30z"></path>
  </svg>
);

export default HistoryIcon;
