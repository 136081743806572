import React from "react";
import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import App from "./App";
import AdminPage from "./pages/Admin/AdminPage";

// import NotFound from "./pages/NotFound";

import SettingsPage from "./pages/Settings/SettingsPage";
import SignInPage from "./pages/Authentication/SignInPage";
import ForgotPasswordPage from "./pages/Authentication/ForgotPassword";
import SignUpPage from "./pages/Authentication/SignUpPage";
import RequestAccessPage from "./pages/Authentication/RequestAccessPage";
import { AuthContext } from "./context/AuthContext";

import PrivacyPolicy from "./components/Features/Legal/PrivacyPolicy";

import Layout from "./components/layout/Layout";

import AskArcher from "./components/Features/AskArcher/AskArcher";
import Dashboard from "./components/Features/Dashboard/Dashboard";
import Inbox from "./components/Features/Inbox/Inbox";

import Tasks from "./components/Features/Tasks/Tasks";
import Documents from "./components/Features/Documents/Documents";
import BlueprintEditor from "./components/Features/Blueprints/BlueprintEditor/BlueprintEditor";

import Meetings from "./components/Features/Meetings/Meetings";
import GlobalDatabase from "./components/Features/GlobalDatabase/GlobalDatabase";
import Campaigns from "./components/Features/Campaigns/Campaigns";
import ManageCampaignComponents from "./components/Features/CampaignManage/ManageCampaignComponents";
import CreateCampaignComponents from "./components/Features/CampaignCreate/CreateCampaignComponents";
import Records from "./components/Features/Records/Records";
import Blueprints from "./components/Features/Blueprints/Blueprints";
import Broadcasts from "./components/Features/Broadcasts/Broadcasts";
// import Funnels from "./components/Features/Forms/Funnels";
import Integrations from "./components/Features/Integrations/integrations";
import Templates from "./components/Features/Templates/Templates";

import Funnels from "./components/Features/Funnels/Funnels";
import Blasts from "./components/Features/Blasts/Blasts";
import FunnelEditor from "./components/Features/Funnels/FunnelEditor/FunnelEditor";
import Prospector from "./components/Features/Prospector/Prospector";

import Contacts from "./components/Features/Contacts/Contacts";
import Pipelines from "./components/Features/Pipelines/Pipelines";
import Approval from "./assets/SVG/NewSidebarIcons/Approval";
import Approvals from "./components/Features/Approvals/Approvals";
import WhiteboardEditor from "./components/Features/Whiteboards/WhiteboardEditor/WhiteboardEditor";
import Whiteboards from "./components/Features/Whiteboards/Whiteboards";
import Projects from "./components/Features/Projects/Projects";
import BroadcastEditor from "./components/Features/Blasts/BroadcastEditor/BroadcastEditor";

import AutoPilot from "components/Features/Autopilot/Autopilot";
import Accounts from "components/Features/Accounts/Accounts";
import Tickets from "components/Features/Tickets/Tickets";
import LeadCapture from "components/Features/LeadCapture/LeadCapture";

function PrivateRoute({ children }) {
  const { currentUser } = useContext(AuthContext);
  const isUserAuthenticated = localStorage.getItem("authUser") || currentUser;
  return isUserAuthenticated ? children : <Navigate to="/sign-in" />;
}

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/joinwaitlist"
            element={<App childComp={<RequestAccessPage />} />}
          />
          <Route path="/sign-in" element={<App childComp={<SignInPage />} />} />
          <Route
            path="/request-access"
            element={<App childComp={<RequestAccessPage />} />}
          />
          <Route
            path="/forgot-password"
            element={<App childComp={<ForgotPasswordPage />} />}
          />
          <Route path="/sign-up" element={<App childComp={<SignUpPage />} />} />
          <Route
            path="/sign-up/step/:stepNum"
            element={<App childComp={<SignUpPage />} />}
          />
          <Route
            path="/privacy-policy"
            element={<App childComp={<PrivacyPolicy />} />}
          />
          <Route
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <App childComp={<Dashboard />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ask-archer"
              element={
                <PrivateRoute>
                  <App childComp={<AskArcher />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <App childComp={<Dashboard />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/inbox"
              element={
                <PrivateRoute>
                  <App childComp={<Inbox />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/tasks"
              element={
                <PrivateRoute>
                  <App childComp={<Tasks />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/whiteboards"
              element={
                <PrivateRoute>
                  <App childComp={<Whiteboards />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/whiteboard/editor"
              element={
                <PrivateRoute>
                  <App childComp={<WhiteboardEditor />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/awaiting-approval"
              element={
                <PrivateRoute>
                  <App childComp={<Approvals />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/prospector"
              element={
                <PrivateRoute>
                  <App childComp={<Prospector />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/tickets"
              element={
                <PrivateRoute>
                  <App childComp={<Tickets />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/accounts"
              element={
                <PrivateRoute>
                  <App childComp={<Accounts />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/contacts"
              element={
                <PrivateRoute>
                  <App childComp={<Contacts />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/pipelines"
              element={
                <PrivateRoute>
                  <App childComp={<Pipelines />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/autopilot"
              element={
                <PrivateRoute>
                  <App childComp={<AutoPilot />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/blueprints"
              element={
                <PrivateRoute>
                  <App childComp={<Blueprints />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/lead-capture"
              element={
                <PrivateRoute>
                  <App childComp={<LeadCapture />} />
                </PrivateRoute>
              }
            />

            <Route
              path="/blueprints/editor"
              element={
                <PrivateRoute>
                  <App childComp={<BlueprintEditor />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/outreach"
              element={
                <PrivateRoute>
                  <App childComp={<Campaigns />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-campaign/:id"
              element={
                <PrivateRoute>
                  <App childComp={<ManageCampaignComponents />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/create-campaign"
              element={
                <PrivateRoute>
                  <App childComp={<CreateCampaignComponents />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/funnels"
              element={
                <PrivateRoute>
                  <App childComp={<Funnels />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/funnels/editor"
              element={
                <PrivateRoute>
                  <App childComp={<FunnelEditor />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/templates"
              element={
                <PrivateRoute>
                  <App childComp={<Templates />} />
                </PrivateRoute>
              }
            />

            <Route
              path="/broadcasts"
              element={
                <PrivateRoute>
                  <App childComp={<Blasts />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/broadcasts/editor"
              element={
                <PrivateRoute>
                  <App childComp={<BroadcastEditor />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <App childComp={<Projects />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/documents"
              element={
                <PrivateRoute>
                  <App childComp={<Documents />} />
                </PrivateRoute>
              }
            />

            <Route
              path="/meetings"
              element={
                <PrivateRoute>
                  <App childComp={<Meetings />} />
                </PrivateRoute>
              }
            />

            <Route
              path="/integrations"
              element={
                <PrivateRoute>
                  <App childComp={<Integrations />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <App childComp={<SettingsPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/account"
              element={
                <PrivateRoute>
                  <App childComp={<SettingsPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/company-profile"
              element={
                <PrivateRoute>
                  <App childComp={<SettingsPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/mailboxes"
              element={
                <PrivateRoute>
                  <App childComp={<SettingsPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/social-settings"
              element={
                <PrivateRoute>
                  <App childComp={<SettingsPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/security"
              element={
                <PrivateRoute>
                  <App childComp={<SettingsPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings/subscription"
              element={
                <PrivateRoute>
                  <App childComp={<SettingsPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <App childComp={<AdminPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRoute>
                  <App childComp={<AdminPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/artemis-testing"
              element={
                <PrivateRoute>
                  <App childComp={<AdminPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/frames"
              element={
                <PrivateRoute>
                  <App childComp={<AdminPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/entities"
              element={
                <PrivateRoute>
                  <App childComp={<AdminPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/embedding"
              element={
                <PrivateRoute>
                  <App childComp={<AdminPage />} />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/media-generation"
              element={
                <PrivateRoute>
                  <App childComp={<AdminPage />} />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;

{
  /* <Route
              path="/social-media"
              element={
                <PrivateRoute>
                  <App childComp={<SocialMediaPage />} />
                </PrivateRoute>
              }
            /> */
}
