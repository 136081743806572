import React from "react";
import styles from "./styles.module.css";

export function MultipleChoiceQuestion() {
  const choices = ["Daily", "Weekly", "Monthly", "Yearly"];

  return (
    <div className={styles.question_template_layout}>
      <p className={styles.question_title}>
        How often would you say that you use tools like ChatGPT?
      </p>

      <div className={styles.radio_input}>
        {choices.map((choice, index) => (
          <label key={index} className={styles.label}>
            <input
              type="radio"
              id={`choice-${index}`}
              name="frequency"
              value={choice}
            />
            <p className={styles.text}>{choice}</p>
          </label>
        ))}
      </div>
    </div>
  );
}
