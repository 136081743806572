import React from "react";

const FullBackArrow = ({
  width = 40,
  height = 40,
  color = "rgba(0, 0, 0, 1)",
}) => (
  <svg
    widthw={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_9344199"
  >
    <g id="Layer_2" data-name="Layer 2">
      <path
        d="m27.063 15h-19.845l7.782-7.786a1 1 0 1 0 -1.41-1.414l-9.266 9.266a1.321 1.321 0 0 0 0 1.868l9.266 9.266a1 1 0 1 0 1.41-1.414l-7.782-7.786h19.845a1 1 0 1 0 0-2z"
        fill={color}
      ></path>
    </g>
  </svg>
);

export default FullBackArrow;
