import { useState, useRef, useContext } from "react";
import React from "react";
import styles from "./campaigns.module.css";
import { useNavigate } from "react-router-dom";

import PlayIcon from "../../../assets/SVG/PlayIcon";
import PauseIcon from "../../../assets/SVG/PauseIcon";

import CircularLoader from "../../Common/CircularLoader/CircularLoader";

import { CampaignContext } from "../../../context/CampaignContext";

import MorePopup from "../../Common/MorePopup/MorePopUp";

import { toast } from "react-toastify";

export default function CampaignTile({ initialCampaign }) {
  const [campaign, setCampaign] = useState(initialCampaign);
  const [isLoadingControl, setLoadingControl] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglePopup = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    { label: "Edit", action: () => console.log("Edit action") },
    { label: "Delete", action: () => console.log("Delete action") },
    // { label: "Archive", action: () => console.log("Archive action") },
  ];

  const percentComplete =
    campaign.messages_sent && campaign.fake_contact_count
      ? Math.round((campaign.messages_sent / campaign.fake_contact_count) * 100)
      : 0;

  const { pauseCampaign, resumeCampaign } = useContext(CampaignContext);

  async function handlePauseClick(event) {
    event.stopPropagation();
    setLoadingControl(true);
    try {
      await pauseCampaign(campaign.id);
      setCampaign((prev) => ({ ...prev, status: "paused" }));
    } catch (error) {
      toast.success("Failed to resume");
      console.error("Failed to pause the campaign:", error);
    } finally {
      setLoadingControl(false);
      toast.success("Campaign Paused");
    }
  }

  async function handleResumeClick(event) {
    event.stopPropagation();
    setLoadingControl(true);
    try {
      await resumeCampaign(campaign.id);
      setCampaign((prev) => ({ ...prev, status: "active" }));
    } catch (error) {
      toast.success("Failed to pause");
      console.error("Failed to resume the campaign:", error);
    } finally {
      setLoadingControl(false);
      toast.success("Campaign Resumed");
    }
  }

  const navigate = useNavigate();

  function goToCampaign() {
    navigate(`/manage-campaign/${campaign.id}`);
  }

  function getRandomNumber() {
    const min = 100;
    const max = 500;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <div className={styles.campaign_tile} onClick={goToCampaign}>
      <div className={styles.campaign_tile_left}>
        <div className={styles.campaign_tile_titles}>
          <p className={styles.campaign_tile_title}>{campaign.campaign_name}</p>
          <p className={styles.campaign_tile_subtitle}>
            {campaign.campaign_description}
          </p>
        </div>
        <div className={styles.progress_section}>
          <p className={styles.percent_complete_text}>
            {percentComplete}% percent complete
          </p>
          <div className={styles.progress_line_background}>
            <div
              className={styles.progress_line}
              style={{ width: `${percentComplete}%` }}
            ></div>
          </div>
        </div>
      </div>
      <div className={styles.campaign_tile_middle}>
        <div className={styles.stat}>
          <p className={styles.value}>{getRandomNumber()}</p>
          <p className={styles.value_title}>Contacts</p>
        </div>
        <div className={styles.divider_line}></div>
        <div className={styles.stat}>
          <p className={styles.value}>{campaign.messages_sent || 0}</p>
          <p className={styles.value_title}>Delivered</p>
        </div>
        <div className={styles.divider_line}></div>
        <div className={styles.stat}>
          <p className={styles.value}>{getRandomNumber() || 0}</p>
          <p className={styles.value_title}>Opened</p>
        </div>
        <div className={styles.divider_line}></div>
        <div className={styles.stat}>
          <p className={styles.value}>{getRandomNumber() || 0}</p>
          <p className={styles.value_title}>Replied</p>
        </div>
      </div>
      <div className={styles.campaign_tile_right}>
        {campaign.status === "active" && (
          <div className={styles.campaign_tile_top_right}>
            <div className={styles.status_active}>
              {capitalizeFirstLetter(campaign.status)}
              <div
                className={styles.status_dot}
                style={{ backgroundColor: "rgba(0, 77, 36, 0.8)" }}
              ></div>
            </div>
            <div className={styles.control_button} onClick={handlePauseClick}>
              {isLoadingControl ? (
                <CircularLoader size="50%" color="rgba(0, 88, 220, 1)" />
              ) : (
                <PauseIcon />
              )}
            </div>
          </div>
        )}
        {campaign.status === "paused" && (
          <div className={styles.campaign_tile_top_right}>
            <div className={styles.status_paused}>
              {capitalizeFirstLetter(campaign.status)}
              <div
                className={styles.status_dot}
                style={{ backgroundColor: "rgba(200, 0, 0, 0.8)" }}
              ></div>
            </div>
            <div className={styles.control_button} onClick={handleResumeClick}>
              {isLoadingControl ? (
                <CircularLoader size="50%" color="rgba(0, 88, 220, 1)" />
              ) : (
                <PlayIcon />
              )}
            </div>
          </div>
        )}
        {campaign.status === "completed" && (
          <div className={styles.campaign_tile_top_right}>
            <div className={styles.control_button} style={{ opacity: 0 }}>
              {/* <CircularLoader size="50%" color="rgba(0, 0, 0, 1)" /> */}
            </div>
            <div className={styles.status_completed}>
              {capitalizeFirstLetter(campaign.status)}
            </div>
          </div>
        )}

        <div className={styles.more_button} onClick={(e) => togglePopup(e)}>
          <MorePopup
            items={menuItems}
            isOpen={isMenuOpen}
            togglePopup={togglePopup}
            closeMenu={() => setIsMenuOpen(false)}
          />
          <div className={styles.more_dot}></div>
          <div className={styles.more_dot}></div>
          <div className={styles.more_dot}></div>
        </div>
        {/* <div className={styles.counter_weight}></div> */}
      </div>
    </div>
  );
}

function capitalizeFirstLetter(string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}
