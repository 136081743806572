import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.css";
import TableControls from "../../Common/TableControls/TableControls";
import SliderHeaderMenu from "../../Common/SliderHeaderMenu/SliderHeaderMenu";
// import Table from "./Table/Table";
import Toolbar from "../../Common/Toolbar/Toolbar";
import MeetingTile from "./MeetingTile";

import { useCalendarContext } from "../../../context/CalendarContext";
import PastMeetingTile from "./PastMeetingTile";

import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";

import BlueprintsIcon from "assets/SVG/SliderHeaderIcons/BlueprintsIcon";
import TemplateIcon from "assets/SVG/NewSidebarIcons/TemplateIcon";
import AnalyticsIcon from "assets/SVG/SliderHeaderIcons/AnalyticsIcon";

import ConversationsIcon from "assets/SVG/SliderHeaderIcons/ConversationsIcon";
import SchedulingLinkIcon from "assets/SVG/SliderHeaderIcons/SchedulingLinkIcon";
import SettingsIcon from "assets/SVG/SliderHeaderIcons/SettingsIcon";

export default function Meetings() {
  // const menuItems = ["Meetings", "Scheduling Pages", "Scheduling Settings"];
  const menuItems = [
    {
      icon: <ConversationsIcon width={12} height={12} />,
      label: "Conversations",
    },
    {
      icon: <SchedulingLinkIcon width={12} height={12} />,
      label: "Scheduling Pages",
    },
    {
      icon: <SettingsIcon width={12} height={12} />,
      label: "Scheduling Settings",
    },
  ];

  const [activeTab, setActiveTab] = useState(menuItems[0]);

  const quickstartPlaceholder =
    "Example: Find me a meeting where the prospect didn't have any objections";

  const { events } = useCalendarContext();
  console.log(events);
  return (
    <div className={styles.main_layout}>
      <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <ToolbarExposed feature="Meeting" />
      <div className={styles.page_layout}>
        {/* <MeetingActions /> */}
        <UpcomingMeetings events={events} />
        {/* <MeetingHistory events={events} /> */}
      </div>
    </div>
  );
}

function MeetingActions() {
  return (
    <div className={styles.four_row}>
      <div className={styles.row_tile}></div>
      <div className={styles.row_tile}></div>
      <div className={styles.row_tile}></div>
      <div className={styles.row_tile}></div>
    </div>
  );
}

function UpcomingMeetings({ events }) {
  return (
    <>
      <p className={styles.section_title}>Upcoming Meetings</p>
      <div className={styles.horizontal_scroll}>
        {events?.map((event) => (
          <MeetingTile key={event.id} event={event} />
        ))}
      </div>
    </>
  );
}

function MeetingHistory({ events }) {
  return (
    <>
      <p className={styles.section_title}>Meeting History</p>
      <div className={styles.tile_layout}>
        {events?.map((event) => (
          <PastMeetingTile key={event.id} event={event} />
        ))}
      </div>
    </>
  );
}
