import React from "react";

const TriggerIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_12024292"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path d="m28.16831 53.81964a21.9492 21.9492 0 0 1 -18.2794-20.819h2.81175a1.00069 1.00069 0 0 0 0-2.00137h-7.71917a1.00087 1.00087 0 0 0 0 2.00137h2.906a24.1361 24.1361 0 0 0 19.93875 22.791 1.00092 1.00092 0 0 0 .34207-1.972z"></path>
    <path d="m9.02332 28.98325a1.00268 1.00268 0 0 0 1.15705-.815 21.95848 21.95848 0 0 1 20.81899-18.27985v2.81266a1.00079 1.00079 0 0 0 2.00137-.00006v-7.71959a1.00087 1.00087 0 0 0 -2.00137.00006v2.90553a24.13674 24.13674 0 0 0 -22.79105 19.9392 1.00075 1.00075 0 0 0 .81501 1.15705z"></path>
    <path d="m54.97677 35.01669a1.00376 1.00376 0 0 0 -1.15705.815 21.9498 21.9498 0 0 1 -20.819 18.27946v-2.8118a1.00088 1.00088 0 0 0 -2.00137.00006v7.71911a1.00087 1.00087 0 0 0 2.00137-.00006v-2.906a24.13621 24.13621 0 0 0 22.79106-19.93879 1.001 1.001 0 0 0 -.81501-1.15698z"></path>
    <path d="m59.01861 30.99928h-2.90605a23.942 23.942 0 0 0 -19.93875-22.791 1.00075 1.00075 0 0 0 -.342 1.97206 21.9492 21.9492 0 0 1 18.2794 20.819h-2.81177a1.00088 1.00088 0 0 0 0 2.00137h7.71917a1.00087 1.00087 0 0 0 0-2.00143z"></path>
    <path d="m26.19235 33.00065h4.807v4.807a1.00069 1.00069 0 0 0 2.00137 0v-4.807h4.807a1.00069 1.00069 0 0 0 0-2.00137h-4.807v-4.80653a1.00088 1.00088 0 0 0 -2.00137 0v4.80653h-4.807a1.00088 1.00088 0 0 0 0 2.00137z"></path>
    <path d="m18.99111 32a13.024 13.024 0 0 0 13.00894 13.0089c17.25819-.715 17.25306-25.30589-.00013-26.01793a13.02375 13.02375 0 0 0 -13.00881 13.00903zm24.0165 0a11.02 11.02 0 0 1 -11.00756 11.00753c-14.60305-.60467-14.59878-21.41272 0-22.01519a11.02011 11.02011 0 0 1 11.00756 11.00766z"></path>
  </svg>
);

export default TriggerIcon;
