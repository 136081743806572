import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./styles.module.css";
import TrailMessage from "./TrailMessage";
import { AuthContext } from "../../../context/AuthContext";
import { EmailContext } from "../../../context/EmailContext";
import { CalendarContext } from "../../../context/CalendarContext";

import CreateEmailPopup from "../../Modals/PopUpComponents/CreateEmailPopup/CreateEmailPopup";

import InboxSidebar from "./InboxSidebar";
import { useNavigate } from "react-router-dom";

import InboxConversations from "./InboxCoversations";

import conversation_data from "../../../data/conversation_data";

import InboxIcon from "../../../assets/SVG/InboxIcons/InboxIcon";
import OutboxIcon from "../../../assets/SVG/InboxIcons/OutboxIcon";
import ScheduledIcon from "../../../assets/SVG/InboxIcons/ScheduledIcon";
import StarredIcon from "../../../assets/SVG/InboxIcons/StarredIcon";
import ArchiveIcon from "../../../assets/SVG/InboxIcons/ArchiveIcon";
import ConfigurationIcon from "../../../assets/SVG/ConfigurationIcon";

import GmailIcon from "../../../assets/SVG/InboxIcons/PlatformIcons/GmailIcon";
import OutlookIcon from "../../../assets/SVG/InboxIcons/PlatformIcons/OutlookIcon";
import LinkedInIcon from "../../../assets/SVG/InboxIcons/PlatformIcons/LinkedInIcon";
import InstagramIcon from "../../../assets/SVG/InboxIcons/PlatformIcons/InstagramIcon";
import MessangerIcon from "../../../assets/SVG/InboxIcons/PlatformIcons/MessangerIcon";
import WhatsAppIcon from "../../../assets/SVG/InboxIcons/PlatformIcons/WhatsAppIcon";
import GmailIconSmall from "../../../assets/SVG/InboxIcons/PlatformIcons/GmailIconSmall";
import { SocialAccountsContext } from "../../../context/SocialAccountsContext";
import TrailEmail from "./TrailEmail";

import SelectThreadIcon from "../../../assets/SVG/SelectThreadIcon";

import MessageInput from "./MessageInput";

export default function Inbox() {
  const [isEditing, setIsEditing] = useState(false);
  const [loadingTrail, setLoadingTrail] = useState(false);
  const [replyingEmail, setReplyingEmail] = useState(false);

  const navigate = useNavigate();

  const [creatingEmail, setCreatingEmail] = useState(false);

  const [inboxItems] = useState(["Inbox", "Outbox"]);
  const [currentInboxType, setCurrentInboxType] = useState(inboxItems[0]);

  const inboxFilters = [
    { name: "Inbox", icon: InboxIcon, count: 42 },
    { name: "Outbox", icon: OutboxIcon, count: 21 },
    { name: "Scheduled", icon: ScheduledIcon, count: 152 },
    { name: "Starred", icon: StarredIcon, count: 52 },
    { name: "Archived", icon: ArchiveIcon, count: 37 },
  ];
  const [currentInboxView, setCurrentInboxView] = useState(inboxFilters[0]);

  const inboxDisplays = [
    { name: "Gmail", icon: GmailIcon, show: true },
    { name: "Outlook", icon: OutlookIcon, show: true },
    { name: "LinkedIn", icon: LinkedInIcon, show: true },
    { name: "Instagram", icon: InstagramIcon, show: true },
    { name: "Facebook", icon: MessangerIcon, show: true },
    { name: "WhatsApp", icon: WhatsAppIcon, show: true },
  ];

  const [currentInboxDisplays, setCurrentInboxDisplays] = useState(
    inboxDisplays[0]
  );

  function openPopup() {
    setCreatingEmail(true);
  }

  function closePopup() {
    setCreatingEmail(false);
  }

  function handleConnectEmail() {
    navigate(`/settings/mailboxes`);
  }

  const [searchQuery, setSearchQuery] = useState("");

  const { profile, setSidebarNotifications } = useContext(AuthContext);
  const { emails } = useContext(CalendarContext);
  const { messages } = useContext(SocialAccountsContext);

  const [inbox, setInbox] = useState([]);

  useEffect(() => {
    if (Array.isArray(emails) && Array.isArray(messages)) {
      // Combine emails and messages

      const combined = [...emails, ...messages];

      // Sort by timestamp
      const sortedInbox = combined.sort((a, b) => {
        const timeA = new Date(a.timestamp).getTime();
        const timeB = new Date(b.timestamp).getTime();
        return timeB - timeA; // Sort in descending order (latest first)
      });

      setInbox(sortedInbox);
    } else {
      setInbox([]); // Fallback to an empty array if emails or messages are not valid arrays
    }
  }, [emails, messages]);

  const {
    // emails,
    setEmails,
    selectedTrail,
    setSelectedTrail,
    // fetchEmails,
    fetchMessages,
    fetchEmailsByAccount,
    markThreadAsRead,
  } = useContext(EmailContext);

  const textAreaRef = useRef(null);
  useEffect(() => {
    if (isEditing && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [isEditing]);

  const [connectedInboxes, setConnectedInboxes] = useState(null);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (profile && profile.connected_accounts) {
      setConnectedInboxes(profile.connected_accounts);
      setActiveTab(profile.connected_accounts[0]);
    }
  }, [profile]);

  const selectChat = (chat) => {
    let wasUnread = false;

    const updatedEmails = emails.map((email) => {
      if (email.id === chat.id) {
        if (!email.threadInfo.read) {
          wasUnread = true;
        }
        const updatedThreadInfo = {
          ...email.threadInfo,
          read: true,
        };

        return {
          ...email,
          threadInfo: updatedThreadInfo,
        };
      }
      return email;
    });

    setEmails(updatedEmails);
    setSelectedTrail(chat);

    if (wasUnread) {
      setSidebarNotifications((prevNotifications) => ({
        ...prevNotifications,
        inbox: prevNotifications.inbox - 1,
      }));
      markThreadAsRead(chat.id);
    }
  };

  const replyToEmail = (email) => {
    setReplyingEmail(true);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(scrollToBottom, [selectedTrail]);

  return (
    <div className={styles.main_layout}>
      <CreateEmailPopup
        isOpen={creatingEmail}
        onClose={closePopup}
        activeEmail={activeTab}
        connectedInboxes={connectedInboxes}
      />

      <div className={styles.inbox_content}>
        <InboxSidebar
          inboxFilters={inboxFilters}
          currentInboxView={currentInboxView}
          setCurrentInboxView={setCurrentInboxView}
          inboxDisplays={inboxDisplays}
          setCurrentInboxDisplays={setCurrentInboxDisplays}
        />

        <InboxConversations
          inbox={inbox}
          setSelectedTrail={setSelectedTrail}
          selectedTrail={selectedTrail}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          openPopup={openPopup}
          loadingTrail={loadingTrail}
        />

        {selectedTrail ? (
          <div className={styles.inbox_view}>
            <TrailEmail profile={profile} selectedTrail={selectedTrail} />
            <MessageInput />
          </div>
        ) : (
          // <>
          //   {selectedTrail.account_type === "EMAIL" ? (
          //     <TrailEmail profile={profile} selectedTrail={selectedTrail} />
          //   ) : (
          //     <TrailMessage profile={profile} selectedTrail={selectedTrail} />
          //   )}
          // </>
          <div className={styles.no_chat_selected}>
            <div className={styles.select_chat_box}>
              <p className={styles.select_chat_text}>Select a Thread</p>
              <SelectThreadIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
