import React from "react";
import { useState } from "react";
import styles from "./checkbox.module.css";

function NewCheckbox({ checked, setChcked }) {
  const [completed, setCompleted] = useState(false);

  const handleCheckboxChange = () => {
    setCompleted(!completed); // Toggle the completed state
  };

  return (
    <div className={styles.checkbox_field}>
      <input
        type="checkbox"
        className={styles.ui_checkbox}
        checked={completed}
        onChange={handleCheckboxChange} // Added onChange handler
      />
    </div>
  );
}

export default NewCheckbox;
