import React from "react";
import styles from "./styles.module.css";
import WorkflowIcon from "assets/SVG/NewSidebarIcons/WorkflowIcon";
import AddIcon from "assets/SVG/AddIcon";
import MobileIcon from "assets/SVG/FunnelsIcons/mobileIcon";
import PreviewIcon from "assets/SVG/FunnelsIcons/PreviewIcon";
import AccessibilityIcon from "assets/SVG/FunnelsIcons/AccessibilityIcon";
import LanguageIcon from "assets/SVG/FunnelsIcons/LanguageIcon";

export default function HeaderMenu({ websiteForm }) {
  return (
    <div className={styles.header_menu}>
      <div className={styles.header_menu_side}>
        <div className={styles.header_menu_section}>
          {/* <iPhoneIcon />
          <iPhoneIcon /> */}
          <div className={styles.icon_button}>
            <MobileIcon width={12} height={12} />
          </div>
          <div className={styles.icon_button}>
            <PreviewIcon width={12} height={12} />
          </div>
          <div className={styles.icon_button}>
            <AccessibilityIcon width={12} height={12} />
          </div>
          <div className={styles.icon_button}>
            <LanguageIcon width={12} height={12} />
          </div>
        </div>
        {/* <div className={styles.header_menu_divider}></div>
        <div className={styles.header_menu_section}>
          <AccessibilityIcon width={12} height={12} />
          <LanguageIcon width={12} height={12} />
        </div>
        <div className={styles.header_menu_divider}></div> */}
      </div>
      <div className={styles.add_funnel_page}>
        <AddIcon color="rgba(255, 255, 255, 1)" width={12} height={12} />
        Add New Slide
      </div>
    </div>
  );
}
