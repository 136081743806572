import React from "react";
import { useState, useContext, useEffect } from "react";

import styles from "./auth.module.css";

import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router";

import GeneralSignUp from "./SignUpPages/GeneralSignUp";
import CreatePassword from "./SignUpPages/CreatePassword";
import GeneralInformation from "./SignUpPages/GeneralInformation";
import CompanyInformation from "./SignUpPages/CompanyInformation";
import ChoosePlanWithFree from "./SignUpPages/ChoosePlanWithFree";
import ChoosePlan from "./SignUpPages/ChoosePlan";
import PaymentInformation from "./SignUpPages/PaymentInformation";
import PageControl from "../Common/PageContols/PageControl";

const SignUpComponent = () => {
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);

  const [currentStage, setCurrentStage] = useState(1);
  const [formData, setFormData] = useState({});

  const company_stages = [
    { component: GeneralSignUp, stage: 1 },
    { component: CreatePassword, stage: 2 },
    { component: CompanyInformation, stage: 3 },
    { component: GeneralInformation, stage: 4 },
    { component: ChoosePlanWithFree, stage: 5 },
    { component: PaymentInformation, stage: 6 },
    // { component: CompanyBrands, stage: 3 },
    // { component: ChooseSubscription, stage: 4 },
  ];

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState("");

  const [error, setError] = useState(null);

  function handleSignUp() {
    navigate("/sign-up");
  }

  function handleForgotPassword() {
    navigate("/forgot-password");
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await signIn(email, password);
      navigate("/social-search");
    } catch (error) {
      console.error("Sign-in error:", error);
      setError("Invalid Login Credentials");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(`Current stage: ${currentStage}`);
    navigate(`/sign-up/step/${currentStage}`, { replace: true });
  }, [currentStage, navigate]);

  const goToNextStage = () => {
    console.log("NEXT");
    if (currentStage < company_stages.length) {
      setCurrentStage(currentStage + 1);
    }
  };

  const goBackStage = () => {
    if (currentStage > 1) {
      setCurrentStage(currentStage - 1);
    }
  };

  const CurrentComponent = company_stages.find(
    (stage) => stage.stage === currentStage
  )?.component;

  const updateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleInputChange = (key, value) => {
    updateFormData({ [key]: value });
  };

  const handleMultipleInputChanges = (updates) => {
    updateFormData(updates);
  };

  function TestControls() {
    console.log(formData);
    return (
      <div className={styles.test_controls}>
        <PageControl prevFunction={goBackStage} nextFunction={goToNextStage} />
      </div>
    );
  }

  return (
    <div className={styles.home_components}>
      {/* <TestControls /> */}
      <CurrentComponent
        onNext={goToNextStage}
        onBack={goBackStage}
        handleInputChange={handleInputChange}
        handleMultipleInputChanges={handleMultipleInputChanges}
        formData={formData}
      />
      <div className={styles.right_side_sign_in}>
        <img
          className={styles.blue_background}
          src="/bluesquaresrotated.jpeg"
        ></img>
        <div className={styles.preview_text}>
          <p className={styles.preview_title_text}>
            Supercharge Your Marketing with BrandLink
          </p>
          <p className={styles.preview_subtitle_text}>
            Put your affiliate outreach on autopilot
          </p>
        </div>
        <img className={styles.preview_ss} src="/SignInSS.png"></img>
      </div>
    </div>
  );
};

export default SignUpComponent;
