import React from "react";
import styles from "./styles.module.css";
import BlockEditor from "./RichEditor/components/BlockEditor/BlockEditor";
import BroadcastEditorHeader from "./BroadcastEditorHeader";

export default function BroadcastEditor() {
  return (
    <div className={styles.main_layout}>
      <BroadcastEditorHeader />
      <BlockEditor />
    </div>
  );
}
