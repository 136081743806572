import React, { useState } from "react";
import classNames from "classnames";
import styles from "./table.module.css";
import NewCheckbox from "../../../Common/NewCheckbox/NewCheckbox";
import PaginationControls from "../../../Common/PaginationControls/PaginationControls";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/popover";

export default function Table({ columns, data, statusColorMap }) {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const currentRows = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleRowSelect = (id) => {
    setSelectedRows((prev) => {
      const updated = new Set(prev);
      updated.has(id) ? updated.delete(id) : updated.add(id);
      return updated;
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.size === currentRows.length) {
      setSelectedRows(new Set());
    } else {
      const allIds = new Set(currentRows.map((doc) => doc.id));
      setSelectedRows(allIds);
    }
  };

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <div className={styles.tableContainer}>
      <TasksTableHeader
        columns={columns}
        currentRows={currentRows}
        selectedRows={selectedRows}
        onSelectAll={handleSelectAll}
      />
      <TasksTableBody
        currentRows={currentRows}
        columns={columns}
        selectedRows={selectedRows}
        onRowSelect={handleRowSelect}
        statusColorMap={statusColorMap}
      />
      {/* <div className={styles.tableFooter}>
        <PaginationControls
          currentPage={currentPage}
          totalRows={data.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
        />
      </div> */}
    </div>
  );
}

const TasksTableHeader = ({
  columns,
  currentRows,
  selectedRows,
  onSelectAll,
}) => (
  <div className={styles.tableHeaderRow}>
    <div className={styles.headerCheckbox}>
      <NewCheckbox
        checked={
          selectedRows.size === currentRows.length && currentRows.length > 0
        }
        indeterminate={
          selectedRows.size > 0 && selectedRows.size < currentRows.length
        }
        onChange={onSelectAll}
      />
    </div>
    <NameHeaderCell />
    {columns
      .filter((column) => column.key !== "name")
      .map((column) => (
        <div key={column.key} className={styles.tableHead}>
          {column.label}
        </div>
      ))}
    <AddColumnHeaderCell />
  </div>
);

const TasksTableBody = ({
  currentRows,
  columns,
  selectedRows,
  onRowSelect,
  statusColorMap,
}) => (
  <div className={styles.tableBody}>
    {currentRows.map((document) => (
      <TasksTableRow
        key={document.id}
        document={document}
        columns={columns}
        isSelected={selectedRows.has(document.id)}
        onRowSelect={() => onRowSelect(document.id)}
        statusColorMap={statusColorMap}
      />
    ))}
    <ExtraRow columns={columns} />
  </div>
);

const TasksTableRow = ({
  document,
  columns,
  isSelected,
  onRowSelect,
  statusColorMap,
}) => (
  <div
    className={classNames(
      styles.tableRow,
      styles.tableRowHover,
      isSelected && styles.selectedRow
    )}
  >
    <div className={styles.checkboxCell}>
      <NewCheckbox checked={isSelected} onChange={onRowSelect} />
    </div>
    <NameCell document={document} />
    {columns
      .filter((column) => column.key !== "name")
      .map((column) => (
        <div key={column.key} className={styles.cell}>
          {renderCell(document, column.key, statusColorMap)}
        </div>
      ))}
    <AddColumnCell />
  </div>
);

const renderCell = (document, columnKey, statusColorMap) => {
  const cellValue = document[columnKey];
  switch (columnKey) {
    case "status":
      return (
        <span
          className={classNames(styles.statusBadge)}
          style={{
            backgroundColor: statusColorMap[cellValue]?.background,
            color: statusColorMap[cellValue]?.text,
          }}
        >
          {cellValue}
        </span>
      );
    default:
      return cellValue;
  }
};

const NameHeaderCell = () => <div className={styles.tableHead}>Name</div>;

const NameCell = ({ document }) => (
  <div className={styles.cell}>{document.name}</div>
);

const AddColumnHeaderCell = () => (
  <div className={styles.add_column_head}>
    <Popover
      placement="bottom-end"
      classNames={{
        content: styles.customPopoverContent,
      }}
    >
      <PopoverTrigger>
        <div className={styles.add_column_button}>+ Add Column</div>
      </PopoverTrigger>
      <PopoverContent>
        <AttributeDropdown />
      </PopoverContent>
    </Popover>
  </div>
);

const AddColumnCell = () => <div className={styles.empty_cell}></div>;

const ExtraRow = ({ columns }) => (
  <div className={styles.tableRow}>
    <div className={styles.checkboxCell} />
    <div className={styles.bottom_cell}>4 Rows</div>
    {columns
      .filter((column) => column.key !== "name")
      .map((column) => (
        <div key={column.key} className={styles.bottom_cell}>
          100% filled
        </div>
      ))}
    <div className={styles.empty_cell} />
  </div>
);

const AttributeDropdown = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAttributes, setFilteredAttributes] = useState(attributes);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredAttributes(
      attributes.filter((attribute) =>
        attribute.name.toLowerCase().includes(term)
      )
    );
  };

  return (
    <div className={styles.dropdown_menu}>
      {/* Search Input */}
      <div className={styles.dropdown_header}>
        <input
          type="text"
          className={styles.dropdown_header_search}
          placeholder="Search Attributes..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      {/* Scrollable Dropdown Body */}
      <div className={styles.dropdown_body}>
        <div className={styles.scrollable}>
          {filteredAttributes.length > 0 ? (
            filteredAttributes.map((attribute) => (
              <div key={attribute.key} className={styles.dropdown_item}>
                {attribute.name}
              </div>
            ))
          ) : (
            <div className={styles.no_results}>No attributes found</div>
          )}
        </div>
      </div>

      {/* Footer */}
      <div className={styles.dropdown_footer}>
        <div className={styles.add_attribute_button}>+ Create Attribute</div>
      </div>
    </div>
  );
};

const attributes = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "Email addresses",
    key: "email_addresses",
  },
  {
    name: "Description",
    key: "description",
  },
  {
    name: "Company",
    key: "company",
  },
  {
    name: "Job title",
    key: "job_title",
  },
  {
    name: "Phone numbers",
    key: "phone_numbers",
  },
  {
    name: "Primary location",
    key: "primary_location",
  },
  {
    name: "AngelList",
    key: "angellist",
  },
  {
    name: "Facebook",
    key: "facebook",
  },
  {
    name: "Instagram",
    key: "instagram",
  },
  {
    name: "LinkedIn",
    key: "linkedin",
  },
  {
    name: "Twitter",
    key: "twitter",
  },
  {
    name: "Twitter follower count",
    key: "twitter_follower_count",
  },
  {
    name: "First interaction",
    key: "first_interaction",
  },
  {
    name: "Last interaction",
    key: "last_interaction",
  },
  {
    name: "Next interaction",
    key: "next_interaction",
  },
  {
    name: "Connection strength",
    key: "connection_strength",
  },
  {
    name: "Strongest connection",
    key: "strongest_connection",
  },
  {
    name: "Next due task",
    key: "next_due_task",
  },
  {
    name: "Created at",
    key: "created_at",
  },
  {
    name: "Created by",
    key: "created_by",
  },
  {
    name: "Record ID",
    key: "record_id",
  },
];
