import React from "react";

const AccessibilityIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="fi_2956431"
    viewBox="0 0 60 60"
    width={width}
    height={height}
  >
    <path d="M30,12a4,4,0,1,0-4-4A4,4,0,0,0,30,12Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,30,6Z"></path>
    <path d="M30,44A22,22,0,1,0,8,22,22.025,22.025,0,0,0,30,44ZM30,2A20,20,0,1,1,10,22,20.023,20.023,0,0,1,30,2Z"></path>
    <path d="M19,20h6v3.238a5.08,5.08,0,0,1-.158,1.25L22.094,35.13a3.114,3.114,0,1,0,6.056,1.453L30,28.49l1.85,8.1A3.114,3.114,0,1,0,37.9,35.122L35.159,24.489A5.048,5.048,0,0,1,35,23.238V20h5.893a3.084,3.084,0,0,0,3.067-2.5A3,3,0,0,0,41,14H19.107a3.084,3.084,0,0,0-3.067,2.5A3,3,0,0,0,19,20Zm.107-4H41a1,1,0,0,1,.985,1.175A1.083,1.083,0,0,1,40.893,18H35a2,2,0,0,0-2,2v3.238a6.958,6.958,0,0,0,.223,1.75l2.744,10.626A1.115,1.115,0,0,1,34.3,36.836a1.1,1.1,0,0,1-.5-.7l-2.268-9.924a1.57,1.57,0,0,0-3.062,0L26.2,36.136a1.115,1.115,0,0,1-1.356.831,1.117,1.117,0,0,1-.814-1.345l2.747-10.635A6.989,6.989,0,0,0,27,23.238V20a2,2,0,0,0-2-2H19a1,1,0,0,1-.985-1.175A1.083,1.083,0,0,1,19.107,16Z"></path>
    <path d="M59,48H45.858a3.981,3.981,0,0,0-7.716,0H1a1,1,0,0,0,0,2H38.142a3.981,3.981,0,0,0,7.716,0H59a1,1,0,0,0,0-2ZM42,51a2,2,0,1,1,2-2A2,2,0,0,1,42,51Z"></path>
    <path d="M59,55H23.858a3.981,3.981,0,0,0-7.716,0H1a1,1,0,0,0,0,2H16.142a3.981,3.981,0,0,0,7.716,0H59a1,1,0,0,0,0-2ZM20,58a2,2,0,1,1,2-2A2,2,0,0,1,20,58Z"></path>
  </svg>
);

export default AccessibilityIcon;
