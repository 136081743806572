import ManuallyTriggerIcon from "../../../../../assets/SVG/BlueprintIcons/ManuallyTriggerIcon";
import RecurringScheduleIcon from "../../../../../assets/SVG/BlueprintIcons/RecurringScheduleIcon";
import WebhookIcon from "../../../../../assets/SVG/BlueprintIcons/WebhookIcon";
import FormSubmissionIcon from "../../../../../assets/SVG/BlueprintIcons/FormSubmissionIcon";
import TaskCreatedIcon from "../../../../../assets/SVG/BlueprintIcons/TaskCreatedIcon";

const action_options = [
  {
    section: "Records",
    actions: [
      { title: "Create or Update Record", icon: TaskCreatedIcon },
      { title: "Create Record", icon: TaskCreatedIcon },
      { title: "Find Records", icon: RecurringScheduleIcon },
      { title: "Update Record", icon: WebhookIcon },
    ],
  },
  {
    section: "Lists",
    actions: [
      { title: "Add Record to List", icon: ManuallyTriggerIcon },
      { title: "Delete List Entry", icon: RecurringScheduleIcon },
      { title: "Find List Entries", icon: WebhookIcon },
      { title: "Update List Entry", icon: TaskCreatedIcon },
    ],
  },
  // {
  //   section: "Sequences",
  //   actions: [{ title: "Enroll in Sequence", icon: FormSubmissionIcon }],
  // },
  // {
  //   section: "AI Agents",
  //   actions: [
  //     { title: "Classify Record", icon: RecurringScheduleIcon },
  //     { title: "Classify Text", icon: WebhookIcon },
  //     { title: "Summarize Record", icon: TaskCreatedIcon },
  //     { title: "Prompt Completion", icon: FormSubmissionIcon },
  //   ],
  // },
  // {
  //   section: "Calculations",
  //   actions: [
  //     { title: "Adjust Time", icon: RecurringScheduleIcon },
  //     { title: "Aggregate Values", icon: WebhookIcon },
  //     { title: "Formula", icon: TaskCreatedIcon },
  //     { title: "Random Number", icon: FormSubmissionIcon },
  //   ],
  // },
  // {
  //   section: "Conditions",
  //   actions: [
  //     { title: "Filter", icon: RecurringScheduleIcon },
  //     { title: "If / Else", icon: WebhookIcon },
  //     { title: "Switch", icon: TaskCreatedIcon },
  //   ],
  // },
  // {
  //   section: "Utilities",
  //   actions: [
  //     { title: "Loop", icon: RecurringScheduleIcon },
  //     { title: "Parse JSON", icon: WebhookIcon },
  //     { title: "Send HTTP Request", icon: FormSubmissionIcon },
  //   ],
  // },
];

export default action_options;

// const action_options = [
//   {
//     id: "0",
//     type: "Email",
//     title: "Update CRM",
//     image: "/images/IntegrationLogos/SequenceLogos/HubSpotLogo.jpeg",
//   },
//   {
//     id: "0",
//     type: "Email",
//     title: "Update CRM",
//     image: "/images/IntegrationLogos/SequenceLogos/SalesForceLogo.webp",
//   },
//   {
//     id: "0",
//     type: "Slack",
//     title: "Send Slack Message",
//     image: "/images/IntegrationLogos/SequenceLogos/SlackLogo.png",
//   },
//   {
//     id: "0",
//     type: "Zapier",
//     title: "Connect Zapier Hook",
//     image: "/images/IntegrationLogos/SequenceLogos/ZapierLogo.png",
//   },
//   {
//     id: "0",
//     type: "LinkedIn",
//     title: "Send Connection Request",
//     image: "/images/IntegrationLogos/SequenceLogos/LinkedinLogo.png",
//   },
//   {
//     id: "0",
//     type: "LinkedIn",
//     title: "Send Message",
//     image: "/images/IntegrationLogos/SequenceLogos/LinkedinLogo.png",
//   },
//   {
//     id: "0",
//     type: "Instagram",
//     title: "Send Message",
//     image: "/images/IntegrationLogos/SequenceLogos/InstagamLogo.webp",
//   },
// ];
