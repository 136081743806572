import React from "react";

const CampaignIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_2107962"
    width={width}
    viewBox="0 0 24 24"
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="m17.972 3.054-12.366 4.946h-3.356c-1.24 0-2.25 1.01-2.25 2.25v2.75c0 1.178.925 2 2.25 2h.85l.908 6.356c.053.37.369.644.742.644h3c.214 0 .417-.091.56-.25.142-.159.21-.371.186-.584l-.59-5.246 10.065 4.026c.091.036.185.054.279.054.147 0 .295-.044.421-.129.206-.14.329-.372.329-.621v-15.5c0-.249-.123-.481-.329-.621-.207-.14-.469-.168-.699-.075zm-16.472 9.946v-2.75c0-.413.337-.75.75-.75h2.75v4h-2.75c-.342 0-.75-.087-.75-.5zm5.411 7.5h-1.511l-.785-5.5h.991l.721.288zm10.589-2.357-10.176-4.071c-.043-.02-.084-.041-.131-.053l-.693-.277v-4.484l11-4.4z"
    ></path>
    <path
      fill={color}
      d="m21.219 9.336 2.5-2c.323-.259.376-.731.117-1.055-.26-.322-.731-.374-1.055-.117l-2.5 2c-.323.259-.376.731-.117 1.055.148.184.366.281.586.281.165 0 .33-.054.469-.164z"
    ></path>
    <path
      fill={color}
      d="m21.219 14.664c-.322-.257-.794-.205-1.055.117-.259.323-.206.796.117 1.055l2.5 2c.139.11.304.164.469.164.22 0 .438-.097.586-.281.259-.323.206-.796-.117-1.055z"
    ></path>
    <path
      fill={color}
      d="m23.25 11.25h-2.5c-.414 0-.75.336-.75.75s.336.75.75.75h2.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"
    ></path>
  </svg>
);

export default CampaignIcon;
