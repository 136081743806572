import React from "react";

const BusinessIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_9511955"
    height={height}
    width={width}
    viewBox="0 0 511.856 511.856"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill={color}
        d="m460.442 481.856v-367.829h-47.635v367.829h-30v-481.856l-253.828 71.168v101.95h108.429v308.738h-30v-278.738h-125.993v278.738h-61.503v30h472.033v-30zm-165.991-171.019v-60.341h30v60.341zm30 49.861v60.341h-30v-60.341zm-30-160.063v-60.341h30v60.341zm-165.04 49.861h30v60.341h-30zm0 110.202h30v60.341h-30z"
      ></path>
    </g>
  </svg>
);

export default BusinessIcon;
