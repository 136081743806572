import React, { useState } from "react";
import MoreIconHorizontal from "../../../assets/SVG/MoreIconHorizontal";
import MoreIconVertical from "../../../assets/SVG/MoreIconVertical";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/popover";
import styles from "./styles.module.css";

function More({
  more_menu,
  item,
  variant = "vertical",
  width = 8,
  height = 8,
}) {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const handleMenuClick = (action, object) => {
    action(object); // Call the action for the menu item
    setPopoverOpen(false); // Close the popover
  };

  return (
    <Popover
      placement="left"
      isOpen={isPopoverOpen}
      onOpenChange={setPopoverOpen}
      classNames={{
        content: styles.customPopoverContent,
      }}
    >
      <PopoverTrigger>
        <div className={styles.more_button}>
          {variant === "vertical" ? (
            <MoreIconVertical width={width} height={height} />
          ) : (
            <MoreIconHorizontal width={width} height={height} />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <MorePopover
          more_menu={more_menu}
          object={item}
          onItemClick={(action, object) => handleMenuClick(action, object)}
        />
      </PopoverContent>
    </Popover>
  );
}

const MorePopover = ({ more_menu, object, onItemClick }) => (
  <div className={styles.more_menu}>
    {more_menu.map((item) => {
      if (item.key === "delete") {
        return (
          <div
            key={item.key}
            className={styles.delete_menu_item}
            onClick={() => onItemClick(item.action, object._id)}
          >
            <button style={{ color: "red" }}>{item.name}</button>
          </div>
        );
      }

      return (
        <div
          key={item.key}
          className={styles.menu_item}
          onClick={() => onItemClick(item.action, object)}
        >
          <button>{item.name}</button>
        </div>
      );
    })}
  </div>
);

export default More;
