import React from "react";

const FormSubmissionIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    viewBox="0 0 24 24"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_9511711"
  >
    <g id="Layer_2" data-name="Layer 2">
      <path d="m16 6.75h-8a1.25 1.25 0 0 1 -1.25-1.25v-3a1.25 1.25 0 0 1 1.25-1.25h8a1.25 1.25 0 0 1 1.25 1.25v3a1.25 1.25 0 0 1 -1.25 1.25zm-7.75-1.5h7.5v-2.5h-7.5z"></path>
      <path d="m19 22.75h-14a1.76 1.76 0 0 1 -1.75-1.75v-16a1.76 1.76 0 0 1 1.75-1.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.25.25 0 0 0 -.25.25v16a.25.25 0 0 0 .25.25h14a.25.25 0 0 0 .25-.25v-16a.25.25 0 0 0 -.25-.25h-2.5a.75.75 0 0 1 0-1.5h2.5a1.76 1.76 0 0 1 1.75 1.75v16a1.76 1.76 0 0 1 -1.75 1.75z"></path>
      <path d="m11.25 16.41a.73.73 0 0 1 -.5-.19l-2.25-2a.75.75 0 0 1 -.06-1.06.76.76 0 0 1 1.06-.06l1.7 1.52 3.25-3.45a.75.75 0 0 1 1.06 0 .75.75 0 0 1 0 1.06l-3.75 4a.78.78 0 0 1 -.51.18z"></path>
    </g>
  </svg>
);

export default FormSubmissionIcon;
