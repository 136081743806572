import React from "react";
import styles from "./node.module.css";
import AddIcon from "../../../../../assets/SVG/AddIcon";
import { Handle, Position } from "@xyflow/react";

export default function Node({ data }) {
  const {
    title,
    subtitle,
    id,
    handleNewNodeSelect,
    isFirstNode,
    isLastNode,
    isSelected,
  } = data;

  const nodeClass = isSelected ? styles.node_selected : styles.node;

  return (
    <div className={nodeClass}>
      {!isFirstNode && (
        <Handle
          type="target"
          position={Position.Top}
          style={{ background: "var(--handle-color)" }}
        />
      )}

      <div className={styles.node_top}>
        <div className={styles.icon_box}>
          {/* Optional icon or placeholder */}
        </div>
        <div className={styles.node_info}>
          <p className={styles.node_title}>{title}</p>
        </div>
      </div>

      <div className={styles.node_divider}></div>

      <div className={styles.node_bottom}>
        <p className={styles.node_description}>
          {subtitle || "No Description"}
        </p>
      </div>

      {isLastNode ? (
        <div
          className={styles.custom_add_handle}
          onClick={() =>
            handleNewNodeSelect({
              id,
              // title: "New Node",
              // subtitle: "Generated Node",
            })
          }
        >
          <AddIcon color="currentColor" width={10} height={10} />
        </div>
      ) : (
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ background: "var(--handle-color)" }}
        />
      )}
    </div>
  );
}
