import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import AskArcherIcon from "assets/SVG/NewSidebarIcons/AskArcherIcon";
import ArcherIcon from "assets/SVG/ArcherIcon/AskArcherIcon";

const gradientColors = [
  "#f79533",
  "#f37055",
  "#ef4e7b",
  "#a166ab",
  "#5073b8",
  "#1098ad",
  "#07b39b",
  "#6fba82",
];

export default function QuickstartBar({ feature, quickstartPlaceholder }) {
  const [currentColor, setCurrentColor] = useState(gradientColors[0]);
  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % gradientColors.length);
    }, 300); // Adjust speed as needed
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCurrentColor(gradientColors[colorIndex]);
  }, [colorIndex]);

  return (
    <div className={styles.quickstart_bar}>
      <div className={styles.searchbar} placeholder={quickstartPlaceholder}>
        <div className={styles.searchbar_background}>
          <ArcherIcon width={14} height={14} />
          <input
            className={styles.search_input}
            placeholder={quickstartPlaceholder}
          />
        </div>
      </div>
      <div className={styles.browse_button}>Browse Popular Prompts</div>
    </div>
  );
}
