import React from "react";

const LogoutIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3287994"
    enable-background="new 0 0 512 512"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m512 256c0 68.38-26.629 132.668-74.98 181.02s-112.64 74.98-181.02 74.98-132.668-26.629-181.02-74.98-74.98-112.64-74.98-181.02c0-100.905 59.559-192.698 151.731-233.854 8.068-3.604 17.531.018 21.133 8.086 3.604 8.069-.018 17.53-8.086 21.133-80.66 36.015-132.778 116.339-132.778 204.635 0 123.514 100.486 224 224 224s224-100.486 224-224c0-88.295-52.118-168.619-132.778-204.634-8.068-3.603-11.689-13.064-8.086-21.133s13.064-11.688 21.133-8.086c92.172 41.155 151.731 132.948 151.731 233.853zm-240-61.203v-178.797c0-8.836-7.163-16-16-16s-16 7.164-16 16v178.797c0 8.836 7.163 16 16 16s16-7.163 16-16z"></path>
  </svg>
);

export default LogoutIcon;
