import React, { createContext, useContext } from "react";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAccountContext } from "./AccountContext";

export const ContactsContext = createContext();

export const ContactsProvider = ({ children }) => {
  const { user_id, profileLoading } = useAccountContext();
  const queryClient = useQueryClient();
  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const fetchUserContacts = async () => {
    console.log("User ID:", user_id);
    if (!user_id) return [];

    const response = await axios.get(`${API_BASE_URL}/contacts`, {
      params: { account_id: user_id },
    });

    if (response.status !== 200) {
      throw new Error(response.data?.error || "Failed to fetch contacts");
    }
    console.log("Contacts data:", response.data);
    return response.data.contacts;
  };

  const {
    data: contacts,
    isLoading: contactsLoading,
    error: contactsError,
  } = useQuery({
    queryKey: ["userContacts", user_id],
    queryFn: fetchUserContacts,
    enabled: !!user_id && !profileLoading,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const createContact = useMutation({
    mutationFn: async (newContact) => {
      const response = await axios.post(`${API_BASE_URL}/contacts`, newContact);

      if (response.status !== 201) {
        throw new Error(response.data?.error || "Failed to create contact");
      }

      return response.data.contact;
    },
    onMutate: async (newContact) => {
      await queryClient.cancelQueries(["userContacts", user_id]);
      const previousContacts = queryClient.getQueryData([
        "userContacts",
        user_id,
      ]);
      queryClient.setQueryData(["userContacts", user_id], (old) => [
        ...(old || []),
        { ...newContact, id: Date.now() },
      ]);
      return { previousContacts };
    },
    onError: (err, newContact, context) => {
      queryClient.setQueryData(
        ["userContacts", user_id],
        context.previousContacts
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userContacts", user_id]);
    },
  });

  const updateContact = useMutation({
    mutationFn: async ({ id, updatedFields }) => {
      const response = await axios.put(
        `${API_BASE_URL}/contacts/${id}`,
        updatedFields
      );

      if (response.status !== 200) {
        throw new Error(response.data?.error || "Failed to update contact");
      }

      return response.data.contact;
    },
    onMutate: async ({ id, updatedFields }) => {
      await queryClient.cancelQueries(["userContacts", user_id]);
      const previousContacts = queryClient.getQueryData([
        "userContacts",
        user_id,
      ]);
      queryClient.setQueryData(["userContacts", user_id], (old) =>
        old.map((contact) =>
          contact.id === id ? { ...contact, ...updatedFields } : contact
        )
      );
      return { previousContacts };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(
        ["userContacts", user_id],
        context.previousContacts
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userContacts", user_id]);
    },
  });

  const deleteContact = useMutation({
    mutationFn: async (id) => {
      const response = await axios.delete(`${API_BASE_URL}/contacts/${id}`);

      if (response.status !== 200) {
        throw new Error(response.data?.error || "Failed to delete contact");
      }

      return id;
    },
    onMutate: async (id) => {
      await queryClient.cancelQueries(["userContacts", user_id]);
      const previousContacts = queryClient.getQueryData([
        "userContacts",
        user_id,
      ]);
      queryClient.setQueryData(["userContacts", user_id], (old) =>
        old.filter((contact) => contact.id !== id)
      );
      return { previousContacts };
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(
        ["userContacts", user_id],
        context.previousContacts
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(["userContacts", user_id]);
    },
  });

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        contactsLoading,
        contactsError,
        createContact: createContact.mutateAsync,
        updateContact: updateContact.mutateAsync,
        deleteContact: deleteContact.mutateAsync,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export const useContactsContext = () => useContext(ContactsContext);
