import React from "react";

const ClientPortal = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    clip-rule="evenodd"
    fill-rule="evenodd"
    width={width}
    viewBox="0 0 24 24"
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_9001461"
  >
    <g id="Icon">
      <path d="m21.722 21.57c.238.339.155.807-.184 1.044-.339.238-.807.155-1.044-.184l-1.619-2.312c-.238-.339-.155-.807.184-1.045.339-.237.807-.155 1.044.185z"></path>
      <path d="m22.137 17.291c.421.243.659.711.606 1.194-.053.482-.386.888-.849 1.034l-2.067.652-1.319 1.719c-.295.385-.791.559-1.262.444-.472-.116-.831-.499-.915-.977l-.998-5.67c-.083-.469.114-.943.503-1.216.39-.272.903-.295 1.315-.057zm-5.224-1.283.787 4.472 1.081-1.41c.094-.122.223-.212.37-.259l1.694-.534z"></path>
      <circle cx="5.25" cy="4.75" r=".75"></circle>
      <circle cx="7.75" cy="4.75" r=".75"></circle>
      <circle cx="10.25" cy="4.75" r=".75"></circle>
      <path d="m22.75 14c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-9.5c0-.464-.184-.909-.513-1.237-.328-.329-.773-.513-1.237-.513h-15c-.967 0-1.75.783-1.75 1.75v15c0 .464.184.909.513 1.237.328.329.773.513 1.237.513h9.5c.414 0 .75.336.75.75s-.336.75-.75.75h-9.5c-.862 0-1.689-.342-2.298-.952-.61-.609-.952-1.436-.952-2.298v-15c0-1.795 1.455-3.25 3.25-3.25h15c.862 0 1.689.342 2.298.952.61.609.952 1.436.952 2.298z"></path>
      <path d="m22 6.75c.414 0 .75.336.75.75s-.336.75-.75.75h-20c-.414 0-.75-.336-.75-.75s.336-.75.75-.75z"></path>
    </g>
  </svg>
);

export default ClientPortal;
