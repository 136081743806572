import React, { useState } from "react";
import classNames from "classnames";
import styles from "./table.module.css";
import NewCheckbox from "../../../Common/NewCheckbox/NewCheckbox";
import PaginationControls from "../../../Common/PaginationControls/PaginationControls";
import ViewTabControls from "components/Common/ViewTabControls/ViewTabControls";

export default function Table({ columns, data, statusColorMap }) {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50;

  const currentRows = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const isAllSelected =
    selectedRows.size === currentRows.length && currentRows.length > 0;
  const isIndeterminate =
    selectedRows.size > 0 && selectedRows.size < currentRows.length;

  const handleRowSelect = (id) => {
    setSelectedRows((prev) => {
      const updated = new Set(prev);
      updated.has(id) ? updated.delete(id) : updated.add(id);
      return updated;
    });
  };

  const handleSelectAll = () => {
    setSelectedRows(
      isAllSelected ? new Set() : new Set(currentRows.map((doc) => doc.id))
    );
  };

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  return (
    <div className={styles.tableContainer}>
      {/* Header */}
      <div className={styles.tableHeaderRow}>
        <div className={styles.headerCheckbox}>
          <NewCheckbox
            checked={isAllSelected}
            indeterminate={isIndeterminate}
            onChange={handleSelectAll}
          />
        </div>
        {columns.map((col) => (
          <div key={col.uid} className={styles.tableHead}>
            {col.name}
          </div>
        ))}
      </div>

      {/* Body */}
      <div className={styles.tableBody}>
        {currentRows.map((doc) => (
          <div
            key={doc.id}
            className={classNames(
              styles.tableRow,
              styles.tableRowHover,
              selectedRows.has(doc.id) && styles.selectedRow
            )}
          >
            <div className={styles.checkboxCell}>
              <NewCheckbox
                checked={selectedRows.has(doc.id)}
                onChange={() => handleRowSelect(doc.id)}
              />
            </div>
            {columns.map((col) => {
              const value = doc[col.uid];
              return (
                <div
                  key={col.uid}
                  className={styles.cell}
                  style={
                    col.uid === "status" && statusColorMap[value]
                      ? {
                          backgroundColor: statusColorMap[value].background,
                          color: statusColorMap[value].text,
                        }
                      : {}
                  }
                >
                  {value}
                </div>
              );
            })}
          </div>
        ))}
      </div>

      {/* Footer */}
      <div className={styles.tableFooter}>
        <ViewTabControls
          currentPage={currentPage}
          totalRows={data.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
