import React from "react";

const DocumentationIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3650317"
    width={width}
    viewBox="0 0 512 512"
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="m441.363 0h-289.438c-15.265 0-27.683 12.418-27.683 27.683v12.931h-12.961c-15.265 0-27.683 12.418-27.683 27.683v12.93h-12.953c-15.269 0-27.69 12.416-27.69 27.677v375.421c0 15.261 12.421 27.677 27.69 27.677h289.424c15.268 0 27.69-12.416 27.69-27.677v-12.937h12.961c15.265 0 27.683-12.418 27.683-27.683v-12.93h12.961c15.265 0 27.683-12.419 27.683-27.684v-375.408c-.002-15.265-12.42-27.683-27.684-27.683zm-81.294 492h-289.424c-4.24 0-7.69-3.443-7.69-7.677v-375.421c0-4.233 3.45-7.677 7.69-7.677h223.717v63.341c0 5.522 4.478 10 10 10h63.397v309.757c-.001 4.234-3.45 7.677-7.69 7.677zm-6.466-337.434h-39.241v-39.212zm54.799 289.138c0 4.243-3.44 7.683-7.683 7.683h-12.961v-286.771c0-.003-.003-.187-.008-.476-.043-2.436-1.045-4.761-2.766-6.487-.001-.001-.001-.001-.001-.001-.052-.054-73.553-73.501-73.553-73.501-.01-.01-.12-.113-.293-.276-1.834-1.716-4.261-2.667-6.773-2.65h-.002-200.764v-12.93c0-4.243 3.44-7.683 7.683-7.683h289.438c4.243 0 7.683 3.44 7.683 7.683zm40.643-40.613c0 4.236-3.446 7.684-7.683 7.684h-12.961v-342.479c0-15.265-12.418-27.683-27.683-27.683h-256.476v-12.93c0-4.236 3.447-7.683 7.683-7.683h289.437c4.236 0 7.683 3.446 7.683 7.683z"
    ></path>
  </svg>
);

export default DocumentationIcon;
