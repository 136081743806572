import { heIL } from "@mui/x-date-pickers/locales";
import React from "react";

const IntegrationsIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_453178"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M297.448,279.808c-5.533-5.532-14.505-5.532-20.038,0.001l-31.873,31.873l-45.219-45.219l31.873-31.874
			c5.534-5.534,5.534-14.506,0-20.039c-5.533-5.534-14.506-5.534-20.039,0l-31.873,31.874l-25.485-25.485
			c-5.533-5.534-14.506-5.534-20.039,0l-56.36,56.36c-39.275,39.274-41.73,101.64-7.364,143.801
			c-0.46,0.358-0.909,0.738-1.332,1.161l-65.548,65.55c-5.534,5.533-5.534,14.506,0,20.039c2.767,2.767,6.393,4.15,10.019,4.15
			c3.626,0,7.253-1.384,10.019-4.15l65.549-65.549c0.423-0.423,0.803-0.872,1.161-1.332c19.675,16.037,43.75,24.055,67.825,24.055
			c27.515,0,55.029-10.473,75.976-31.42l56.36-56.36c5.534-5.533,5.534-14.506,0-20.039l-25.485-25.485l31.873-31.873
			C302.982,294.314,302.982,285.341,297.448,279.808z M214.661,413.565c-30.845,30.843-81.029,30.843-111.874,0l-4.352-4.352
			c-30.844-30.844-30.844-81.03,0-111.874l46.34-46.34l116.227,116.226L214.661,413.565z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M507.849,24.19c5.534-5.533,5.534-14.505,0-20.039c-5.532-5.534-14.505-5.534-20.039,0l-65.549,65.548
			c-0.423,0.422-0.801,0.87-1.159,1.33c-19.112-15.613-42.816-24.104-67.827-24.104c-28.7,0-55.682,11.177-75.976,31.471
			l-56.36,56.36c-5.534,5.534-5.534,14.505,0,20.039L357.206,291.06c2.657,2.658,6.261,4.15,10.019,4.15
			c3.758,0,7.363-1.493,10.019-4.15l56.36-56.36c20.294-20.294,31.47-47.276,31.47-75.975c0-25.011-8.49-48.715-24.104-67.827
			c0.459-0.358,0.907-0.737,1.33-1.159L507.849,24.19z M413.565,214.662l-46.34,46.341L250.998,144.775l46.34-46.341
			c14.942-14.941,34.807-23.17,55.937-23.17c21.131,0,40.996,8.229,55.937,23.17l4.352,4.352
			c14.941,14.941,23.17,34.807,23.17,55.937C436.735,179.855,428.506,199.72,413.565,214.662z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M374.062,196.728l-58.79-58.791c-5.533-5.534-14.506-5.534-20.039,0c-5.534,5.534-5.534,14.505,0,20.039l58.791,58.791
			c2.767,2.767,6.393,4.15,10.019,4.15c3.626,0,7.253-1.383,10.019-4.15C379.596,211.233,379.596,202.262,374.062,196.728z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M218.149,352.641l-58.791-58.791c-5.533-5.533-14.506-5.533-20.039,0c-5.533,5.534-5.533,14.506,0.001,20.039
			l58.791,58.791c2.767,2.767,6.393,4.15,10.019,4.15c3.626,0,7.253-1.384,10.019-4.15
			C223.683,367.146,223.683,358.173,218.149,352.641z"
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default IntegrationsIcon;
