import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.css";
import SliderHeaderMenu from "../../Common/SliderHeaderMenu/SliderHeaderMenu";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";
import Table from "./Table/Table";

export default function Documents() {
  const navigate = useNavigate();

  const menuItems = [
    "All Documents",
    "Drafts",
    "Pending",
    "Signed",
    "Expired/Declined",
  ];
  const [activeTab, setActiveTab] = useState(menuItems[0]);

  return (
    <div className={styles.main_layout}>
      <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <ToolbarExposed feature="Document" />
      <div className={styles.page_layout}>
        <div className={styles.table_layout}>
          <Table
            columns={columns}
            data={data}
            statusColorMap={statusColorMap}
          />
        </div>
      </div>
    </div>
  );
}

const columns = [
  { name: "Name", uid: "name" },
  { name: "Last Activity", uid: "lastActivity" },
  { name: "Recipient", uid: "recipient" },
  { name: "Status", uid: "status" },
];

const data = [
  {
    id: 1,
    name: "NDA",
    lastActivity: "October 22, 2024",
    recipient: "David Kalt",
    status: "Pending",
  },
  {
    id: 2,
    name: "Lease Agreement",
    lastActivity: "October 22, 2024",
    recipient: "Jack Carson",
    status: "Pending",
  },
  {
    id: 3,
    name: "W2",
    lastActivity: "October 22, 2024",
    recipient: "Riley Hayden",
    status: "Signed",
  },
  {
    id: 4,
    name: "Consulting Contract",
    lastActivity: "October 22, 2024",
    recipient: "Nick Sandler",
    status: "Expired",
  },
  {
    id: 5,
    name: "Shareholders Agreement",
    lastActivity: "October 22, 2024",
    recipient: "Mike Ross",
    status: "Signed",
  },
  {
    id: 6,
    name: "Equity Contract",
    lastActivity: "October 22, 2024",
    recipient: "Tom Jackson",
    status: "Signed",
  },
];

const statusColorMap = {
  Pending: {
    background: "rgba(204, 153, 0, 0.1)", // Dark yellow background at 0.1 opacity
    text: "rgba(204, 153, 0, 1)", // Dark yellow text at full opacity
  },
  Signed: {
    background: "rgba(0, 77, 36, 0.1)", // Dark green background at 0.1 opacity
    text: "rgba(0, 77, 36, 1)", // Dark green text at full opacity
  },
  Expired: {
    background: "rgba(200, 0, 0, 0.1)", // Red background at 0.1 opacity
    text: "rgba(200, 0, 0, 1)", // Red text at full opacity
  },
};
