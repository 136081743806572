import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

import { artemisDatabase } from "../services/firebase";
import { storage } from "../services/firebase";

export const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
  const [emails, setEmails] = useState([]);
  const [events, setEvents] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [conversation, setConversation] = useState([]);

  const API_BASE_URL = process.env.REACT_APP_API_URL;

  const fetchEmails = async (grantId) => {
    const folderId = "INBOX";
    try {
      const response = await axios.post(`${API_BASE_URL}/nylas/fetch-emails`, {
        grantId: grantId,
        folderId: folderId,
      });
      if (response.status === 200) {
        const emails = response.data.data.map((email) => ({
          ...email,
          timestamp: email.latestMessageReceivedDate,
          account_type: "EMAIL",
        }));
        console.log(emails);
        setEmails(emails);
      } else {
        console.error("Failed to fetch emails");
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  const fetchEvents = async (grantId) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/nylas/fetch-events`, {
        grantId: grantId,
      });
      if (response.status === 200) {
        console.log(response.data.data);
        setEvents(response.data.data);
      } else {
        console.error("Failed to fetch events");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchContacts = async (grantId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/nylas/fetch-contacts`,
        {
          grantId: grantId,
        }
      );
      if (response.status === 200) {
        setContacts(response.data.data);
      } else {
        console.error("Failed to fetch events");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const fetchEmailFolders = async (grantId) => {
    try {
      const response = await axios.post(
        ` ${API_BASE_URL}/nylas/fetch-email-folders`,
        {
          grantId: grantId,
        }
      );
      if (response.status === 200) {
        console.log(response.data.data);
        setContacts(response.data.data);
      } else {
        console.error("Failed to fetch events");
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    fetchEmails("ab8f7b0e-e92b-4e0c-8972-0eee02427bd1");
    fetchEvents("ab8f7b0e-e92b-4e0c-8972-0eee02427bd1");
    fetchContacts("ab8f7b0e-e92b-4e0c-8972-0eee02427bd1");
    fetchEmailFolders("ab8f7b0e-e92b-4e0c-8972-0eee02427bd1");
  }, []);

  return (
    <CalendarContext.Provider
      value={{
        conversation,
        setConversation,
        fetchEmails,
        fetchEvents,
        emails,
        events,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = () => useContext(CalendarContext);
