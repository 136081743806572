import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./email.module.css";
import { AuthContext } from "../../../../../context/AuthContext";
import { PaymentContext } from "../../../../../context/PaymentContext";
import { EmailContext } from "../../../../../context/EmailContext";
import axios from "axios";
import { toast } from "react-toastify";

import LinkMailboxPopUp from "../../../../Modals/PopUpComponents/LinkMailboxPopup/LinkMailboxPopUp";
import MorePopup from "../../../../Common/MorePopup/MorePopUp";

import { ConfirmDeletePopup } from "../../../../Modals/PopUpComponents/DeletePopup/ConfirmDeletePopup";

import getEmailProviderImage from "../../../../../utils/getEmailProviderImage";
import CircularLoader from "../../../../Common/CircularLoader/CircularLoader";

export default function EmailSettings() {
  const { profile } = useContext(AuthContext);
  const { currentPlan } = useContext(PaymentContext);

  const [linkingMailbox, setLinkingMailbox] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New loading state

  const mailboxes = profile.connected_accounts;

  const countConnected = mailboxes.filter(
    (account) => account.disconnected !== true
  ).length;

  const countDisconnected = mailboxes.filter(
    (account) => account.disconnected === true
  ).length;

  function openPopup() {
    setLinkingMailbox(true);
  }

  function closePopup() {
    setLinkingMailbox(false);
  }

  useEffect(() => {
    // Check if there's an authorization code in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      // Automatically call the backend endpoint with the code
      handleOAuthExchange(code);
    }
  }, []);

  const handleOAuthExchange = async (authCode) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://artemis--ai.uc.r.appspot.com/nylas/oauth/exchange`,
        {
          params: {
            code: authCode,
          },
        }
      );

      if (response.status === 200) {
        console.log("OAuth2 flow completed successfully:", response.data);
      }
    } catch (error) {
      console.error("Error during OAuth exchange:", error);
    } finally {
      setIsLoading(false);
    }
  };

  async function connectAccount(provider) {
    setIsLoading(true);
    try {
      const baseUrl = window.location.origin;

      const response = await axios.post(
        "https://artemis--ai.uc.r.appspot.com/nylas/generate-auth-link",
        {
          userId: profile.uid,
          // provider: provider,
          baseUrl: baseUrl,
        }
      );

      const data = response.data;
      console.log(data);
      if (response.status === 200) {
        window.location.href = data.url;
      } else {
        alert("Failed to connect account");
      }
    } catch (error) {
      console.error("Error connecting account:", error);
      alert("Error connecting account. Please try again.");
    }
    setIsLoading(false);
  }

  async function reconnectAccount(accountId) {
    setIsLoading(true);
    try {
      const baseUrl = window.location.origin;

      const response = await axios.post(
        "https://brandlink-24432.uc.r.appspot.com/email/generate-reconnect-auth-link",
        {
          userId: profile.uid,
          accountId: accountId,
          baseUrl: baseUrl,
        }
      );

      const data = response.data;
      if (response.status === 200) {
        window.location.href = data.url;
      } else {
        alert("Failed to connect account");
      }
    } catch (error) {
      console.error("Error connecting account:", error);
      alert("Error connecting account. Please try again.");
    }
    setIsLoading(false);
  }

  function handleProviderSelect(provider) {
    connectAccount(provider);
  }

  return (
    <div className={styles.main_layout}>
      <div className={styles.link_email_button} onClick={openPopup}>
        Link Email
      </div>
      <LinkMailboxPopUp
        isOpen={linkingMailbox}
        onClose={closePopup}
        onProviderSelect={handleProviderSelect}
        isLoading={isLoading}
      />
      <p className={styles.top_section_title}>Mailboxes</p>

      {/* <div className={styles.progress_line_background}>
        <div
          className={styles.progress_line}
          style={{
            width: `${
              ((profile?.connected_emails?.length || 0) /
                (currentPlan?.metadata?.mailbox_limit || 1)) *
              100
            }%`,
          }}
        ></div>
      </div> */}
      <p className={styles.top_section_subtitle}>
        {profile?.connected_emails?.length || 0} /{" "}
        {currentPlan?.metadata?.mailbox_limit} Connected
      </p>
      <div className={styles.email_status_options}>
        <div className={styles.email_status_active}>
          <div className={styles.status_dot_active}></div>
          {countConnected} Active
        </div>
        <div className={styles.email_status_disconnected}>
          <div className={styles.status_dot_disconnected}></div>
          {countDisconnected} Disconnected
        </div>
      </div>
      {mailboxes && mailboxes != 0 ? (
        <>
          <div className={styles.mailbox_header}>
            <div className={styles.mailbox_info_header}>Mailbox</div>
            <div className={styles.mailbox_stat_title}>Status</div>
            {/* <div className={styles.mailbox_stat_title}>Sent</div>
            <div className={styles.mailbox_stat_title}>Opened</div>
            <div className={styles.mailbox_stat_title}>Replied</div> */}
          </div>
          <div className={styles.connected_mailboxes}>
            {mailboxes.map((mailbox) => (
              <LinkedMailbox
                mailbox={mailbox}
                reconnectAccount={reconnectAccount}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles.no_mailboxes_layout}>
          No Mailboxes Connected
          <div
            className={styles.connect_first_mailbox_button}
            onClick={openPopup}
          >
            Link your first email
          </div>
        </div>
      )}

      {/* <div className={styles.my_email_settings}>
        <div className={styles.email_settings_top}>
          <div className={styles.email_settings_title_area}>
            <div className={styles.title}>My Emails</div>
            <div className={styles.line}></div>
            <div className={styles.email_count}>
              {mailboxes.length} Connected
            </div>
          </div>

        
        </div>
     
      </div> */}
    </div>
  );
}

function LinkedMailbox({ mailbox, reconnectAccount }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [isEmailDeleting, setIsEmailDeleting] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);

  const togglePopup = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const { disconnectEmailAccount } = useContext(EmailContext);

  const menuItems = [
    // { label: "Edit", action: () => console.log("Edit action") },
    { label: "Disconnect", action: () => setDeletePopup(true) },
  ];

  async function handleDisconnect() {
    setIsEmailDeleting(true);
    try {
      await disconnectEmailAccount(mailbox.id);
      toast.success("Email Disconnected");
    } catch (error) {
      toast.error("Error Disconnecting Email");
      console.error("Error Disconnecting Email:", error);
    } finally {
      setIsEmailDeleting(false);
      setDeletePopup(false);
    }
  }

  async function handleReconnect() {
    setIsReconnecting(true);
    try {
      await reconnectAccount(mailbox.id);
    } catch (error) {
      console.error("Error Disconnecting Email:", error);
    } finally {
      setIsReconnecting(false);
    }
  }

  //reconnectAccount

  return (
    <div className={styles.mailbox}>
      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        desc={"Are you sure you want to disconnet?"}
        label={"Disconnect"}
        onConfirm={handleDisconnect}
        isLoading={isEmailDeleting}
      />
      <div className={styles.mailbox_info}>
        <img
          className={styles.mailbox_icon}
          src={getEmailProviderImage(mailbox.type)}
        ></img>
        <p className={styles.mailbox_title}>{mailbox.name}</p>
      </div>
      {!mailbox.disconnected ? (
        <div className={styles.email_status_active}>
          <div className={styles.status_dot_active}></div>Active
        </div>
      ) : (
        <div className={styles.email_status_disconnected}>
          <div className={styles.status_dot_disconnected}></div>Disconnected
        </div>
      )}
      {mailbox.disconnected && (
        <div className={styles.reconnect_button} onClick={handleReconnect}>
          {isReconnecting ? (
            <CircularLoader color="rgba(0, 131, 10, 1)" size="20%" />
          ) : (
            "Reconnect"
          )}
        </div>
      )}

      {/* <div className={styles.mailbox_stat}>63</div>
      <div className={styles.mailbox_stat}>24</div>
      <div className={styles.mailbox_stat}>53</div> */}
      <div className={styles.more_button} onClick={(e) => togglePopup(e)}>
        <MorePopup
          items={menuItems}
          isOpen={isMenuOpen}
          togglePopup={togglePopup}
          closeMenu={() => setIsMenuOpen(false)}
        />
        <div className={styles.more_dot}></div>
        <div className={styles.more_dot}></div>
        <div className={styles.more_dot}></div>
      </div>
    </div>
  );
}

// const mailbox_options = [
//   {
//     id: "GOOGLE",
//     title: "Google",
//     subtitle: "Gmail, GSuite, Workspace",
//     icon: "/EmailProviders/search.png",
//   },
//   {
//     id: "OUTLOOK",
//     title: "Outlook",
//     subtitle: "Hotmail, Live, MSN",
//     icon: "/EmailProviders/outlook.png",
//   },
//   {
//     id: "MAIL",
//     title: "Other",
//     subtitle: "Any Provider, IMAP",
//     icon: "/EmailProviders/mail.png",
//   },
// ];
