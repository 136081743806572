import React from "react";
import styles from "./styles.module.css";

export default function Switch({ id, isToggled, onToggle, text = false }) {
  return (
    <div className={styles.checkbox_wrapper_35}>
      <input
        value="private"
        name={id}
        id={id}
        type="checkbox"
        className={styles.switch}
        checked={isToggled}
        onChange={onToggle}
      />
      <label htmlFor={id}>
        {text && (
          <>
            <span className={styles.switch_x_text}>Invite Archer: </span>
            <span className={styles.switch_x_toggletext}>
              <span className={styles.switch_x_unchecked}>
                <span className={styles.switch_x_hiddenlabel}>Unchecked: </span>
                No
              </span>
              <span className={styles.switch_x_checked}>
                <span className={styles.switch_x_hiddenlabel}>Checked: </span>
                Yes
              </span>
            </span>
          </>
        )}
      </label>
    </div>
  );
}
