import React from "react";

const ArchiveIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_666120"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 442.88 442.88"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M22.016,146.432v268.8c0,9.216,7.68,16.384,16.384,16.384h365.568c9.216,0,16.384-7.68,16.384-16.384v-268.8H22.016z
                 M304.128,249.344c0,9.216-7.68,16.384-16.384,16.384h-133.12c-9.216,0-16.384-7.68-16.384-16.384v-33.28
                c0-9.216,7.68-16.384,16.384-16.384h133.12c9.216,0,16.384,7.68,16.384,16.384V249.344z"
        ></path>
      </g>
    </g>
    <g>
      <g>
        <path
          fill={color}
          d="M425.984,11.264h-409.6C7.168,11.264,0,18.944,0,27.648v96.768h442.88V28.16C442.368,18.944,435.2,11.264,425.984,11.264z
                "
        ></path>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default ArchiveIcon;
