import React from "react";

const CreateIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_8191584"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="image-gallery-plus">
      <path
        d="m22.448 7.608-1.2 8.58a3.142 3.142 0 0 1 -1.257 2.312.311.311 0 0 1 -.488-.244v-8.591a3.829 3.829 0 0 0 -4.168-4.165h-9.412c-.3 0-.307-.27-.286-.39a3.134 3.134 0 0 1 1.112-2.085 3.2 3.2 0 0 1 2.442-.473l10.561 1.48a3.211 3.211 0 0 1 2.223 1.134 3.191 3.191 0 0 1 .473 2.442zm-4.448 2.057v8.668a2.358 2.358 0 0 1 -2.665 2.667h-10.668a2.357 2.357 0 0 1 -2.667-2.667v-8.668a2.357 2.357 0 0 1 2.667-2.665h10.668a2.358 2.358 0 0 1 2.665 2.665zm-4.75 4.335a.75.75 0 0 0 -.75-.75h-1.75v-1.75a.75.75 0 0 0 -1.5 0v1.75h-1.75a.75.75 0 0 0 0 1.5h1.75v1.75a.75.75 0 0 0 1.5 0v-1.75h1.75a.75.75 0 0 0 .75-.75z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default CreateIcon;
