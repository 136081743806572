import React from "react";
import styles from "./styles.module.css";

function Button({ text, action, icon, variant = "bordered" }) {
  const variantStyle =
    variant === "bordered" ? styles.button_bordered : styles.button_filled;
  return (
    <button onClick={action} className={variantStyle}>
      {icon && <span className={styles.icon}>{icon}</span>}
      {text}
    </button>
  );
}

export default Button;
