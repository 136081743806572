import React from "react";

const LinkedInIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 176 176"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_3991775"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="_10.linkedin" data-name="10.linkedin">
        <rect
          id="background"
          fill="#0b63bd"
          height="176"
          rx="16.33"
          width="176"
        ></rect>
        <g id="icon" fill="#fff">
          <path d="m63.4 48a15 15 0 1 1 -15-15 15 15 0 0 1 15 15z"></path>
          <path d="m60 73v66.27a3.71 3.71 0 0 1 -3.71 3.73h-15.81a3.71 3.71 0 0 1 -3.72-3.72v-66.28a3.72 3.72 0 0 1 3.72-3.72h15.81a3.72 3.72 0 0 1 3.71 3.72z"></path>
          <path d="m142.64 107.5v32.08a3.41 3.41 0 0 1 -3.42 3.42h-17a3.41 3.41 0 0 1 -3.42-3.42v-31.09c0-4.64 1.36-20.32-12.13-20.32-10.45 0-12.58 10.73-13 15.55v35.86a3.42 3.42 0 0 1 -3.37 3.42h-16.42a3.41 3.41 0 0 1 -3.41-3.42v-66.87a3.41 3.41 0 0 1 3.41-3.42h16.42a3.42 3.42 0 0 1 3.42 3.42v5.78c3.88-5.82 9.63-10.31 21.9-10.31 27.18 0 27.02 25.38 27.02 39.32z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default LinkedInIcon;
