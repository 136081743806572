import React from "react";

const InstagramIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 152 152"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_3955027"
  >
    <linearGradient
      id="linear-gradient"
      gradientUnits="userSpaceOnUse"
      x1="7.03"
      x2="144.97"
      y1="7.03"
      y2="144.97"
    >
      <stop offset="0" stopColor="#fae100"></stop>
      <stop offset=".15" stopColor="#fcb720"></stop>
      <stop offset=".3" stopColor="#ff7950"></stop>
      <stop offset=".5" stopColor="#ff1c74"></stop>
      <stop offset="1" stopColor="#6c1cd1"></stop>
    </linearGradient>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Square">
        <g id="_03.Instagram" data-name="03.Instagram">
          <rect
            id="Background"
            fill="url(#linear-gradient)"
            height="152"
            rx="24"
            width="152"
          ></rect>
          <g fill="#fff">
            <path
              id="Shade"
              d="m143 5.27c-10.53 26.42-29.8 55.28-56.12 81.6s-55.19 45.59-81.61 56.13a23.85 23.85 0 0 1 -5.27-15v-104a24 24 0 0 1 24-24h104a23.85 23.85 0 0 1 15 5.27z"
              opacity=".1"
            ></path>
            <g id="Icon">
              <path d="m94 36h-36a22 22 0 0 0 -22 22v36a22 22 0 0 0 22 22h36a22 22 0 0 0 22-22v-36a22 22 0 0 0 -22-22zm15 54.84a18.16 18.16 0 0 1 -18.16 18.16h-29.68a18.16 18.16 0 0 1 -18.16-18.16v-29.68a18.16 18.16 0 0 1 18.16-18.16h29.68a18.16 18.16 0 0 1 18.16 18.16z"></path>
              <path d="m90.59 61.56-.19-.19-.16-.16a20.16 20.16 0 0 0 -14.24-5.88 20.52 20.52 0 0 0 -20.38 20.67 20.75 20.75 0 0 0 6 14.61 20.19 20.19 0 0 0 14.42 6 20.73 20.73 0 0 0 14.55-35.05zm-14.59 28a13.56 13.56 0 1 1 13.37-13.56 13.46 13.46 0 0 1 -13.37 13.56z"></path>
              <path d="m102.43 54.38a4.88 4.88 0 0 1 -4.85 4.92 4.81 4.81 0 0 1 -3.42-1.43 4.93 4.93 0 0 1 3.43-8.39 4.82 4.82 0 0 1 3.09 1.12l.1.1a3.05 3.05 0 0 1 .44.44l.11.12a4.92 4.92 0 0 1 1.1 3.12z"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default InstagramIcon;
