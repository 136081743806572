import React from "react";

const SocialMediaIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    fill="none"
    widths={width}
    viewBox="0 0 64 64"
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_13916105"
  >
    <g fill="#000">
      <path
        fill={color}
        d="m19 32c0-1.1046.8954-2 2-2h9v-9c0-1.1046.8954-2 2-2s2 .8954 2 2v9h9c1.1046 0 2 .8954 2 2s-.8954 2-2 2h-9v9c0 1.1046-.8954 2-2 2s-2-.8954-2-2v-9h-9c-1.1046 0-2-.8954-2-2z"
      ></path>
      <path
        fill={color}
        clipRule="evenodd"
        d="m2 18c0-8.83656 7.16344-16 16-16h28c8.8366 0 16 7.16344 16 16v28c0 8.8366-7.1634 16-16 16h-28c-8.83656 0-16-7.1634-16-16zm16-12c-6.6274 0-12 5.3726-12 12v28c0 6.6274 5.3726 12 12 12h28c6.6274 0 12-5.3726 12-12v-28c0-6.6274-5.3726-12-12-12z"
        fillRule="evenodd"
      ></path>
    </g>
  </svg>
);

export default SocialMediaIcon;
