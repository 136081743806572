import React from "react";

const SelectThreadIcon = ({
  width = 15,
  height = 15,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.5,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_839996"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 365.714 365.714"
  >
    <g>
      <g>
        <g>
          <path
            d="M88.816,236.669V96.653H0v224.653l53.29-39.184c1.045-1.045,2.09-2.612,3.657-2.612h204.278v-36.571H95.086
                    C90.906,242.939,88.816,241.371,88.816,236.669z"
          ></path>
          <path
            d="M104.49,44.408v182.857h204.278c1.567,0,2.612,1.567,3.657,2.612l53.29,39.184V44.408H104.49z M146.286,80.98h57.469
                    c4.328,0,7.837,3.509,7.837,7.837c0,4.328-3.509,7.837-7.837,7.837h-57.469c-4.328,0-7.837-3.509-7.837-7.837
                    C138.449,84.488,141.958,80.98,146.286,80.98z M323.918,185.469H146.286c-4.328,0-7.837-3.509-7.837-7.837
                    s3.509-7.837,7.837-7.837h177.633c4.328,0,7.837,3.509,7.837,7.837S328.246,185.469,323.918,185.469z M323.918,138.449H146.286
                    c-4.328,0-7.837-3.509-7.837-7.837c0-4.328,3.509-7.837,7.837-7.837h177.633c4.328,0,7.837,3.509,7.837,7.837
                    C331.755,134.94,328.246,138.449,323.918,138.449z"
          ></path>
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default SelectThreadIcon;
