import React from "react";

const HomeIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_10416163"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      fill={color}
      d="m19 3.5c.83 0 1.5.67 1.5 1.5v14c0 .83-.67 1.5-1.5 1.5h-14c-.83 0-1.5-.67-1.5-1.5v-14c0-.83.67-1.5 1.5-1.5zm0-1.5h-14c-1.66 0-3 1.34-3 3v14c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-14c0-1.66-1.34-3-3-3z"
    ></path>
    <path
      fill={color}
      d="m12 17.75c-.41 0-.75-.34-.75-.75v-9c0-.41.34-.75.75-.75s.75.34.75.75v9c0 .41-.34.75-.75.75z"
    ></path>
    <path
      fill={color}
      d="m8 17.75c-.41 0-.75-.34-.75-.75v-4c0-.41.34-.75.75-.75s.75.34.75.75v4c0 .41-.34.75-.75.75z"
    ></path>
    <path
      fill={color}
      d="m16 17.75c-.41 0-.75-.34-.75-.75v-6c0-.41.34-.75.75-.75s.75.34.75.75v6c0 .41-.34.75-.75.75z"
    ></path>
  </svg>
);

export default HomeIcon;
