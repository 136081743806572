import React, { useState } from "react";
import styles from "./styles.module.css";
import TableControls from "../../Common/TableControls/TableControls";
import Table from "./Table/Table";
import CreateTask from "../../Modals/CreateTask/CreateTask";
import { useTasksContext } from "../../../context/TasksContext";
import formatDateStamp from "../../../helpers/formatDateStamp";
import { statusColorMap } from "../../../helpers/statusColorMap";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

export default function Tasks() {
  const { tasks, tasksLoading, tasksError, deleteTask } = useTasksContext();

  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const openCreateTaskModal = (task = null) => {
    console.log(task);
    setSelectedTask(task);
    setIsCreateTaskOpen(true);
  };

  const closeCreateTaskModal = () => {
    setIsCreateTaskOpen(false);
    setSelectedTask(null);
  };

  const more_menu = [
    {
      name: "Mark Complete",
      key: "mark_complete",
      action: null,
    },
    {
      name: "Nudge Assignee",
      key: "nudge_assignee",
      action: null,
    },
    {
      name: "Edit",
      key: "edit",
      action: openCreateTaskModal,
    },
    {
      name: "Delete",
      key: "delete",
      action: deleteTask,
    },
  ];

  const columns = [
    { name: "Task", uid: "title" },
    { name: "Due Date", uid: "due_date" },
    { name: "Record", uid: "record" },
    { name: "Assigned To", uid: "assigned" },
  ];

  // const mappedTasks =
  //   tasks?.map((task) => ({
  //     id: task.id,
  //     task: task.title,
  //     dueDate: formatDateStamp(task.due_date),
  //     record: task.record || "N/A",
  //     assigned: task.assigned || "N/A",
  //     originalTask: task, // Include the original task
  //   })) || [];

  if (tasksLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.main_layout}>
      <CreateTask
        isOpen={isCreateTaskOpen}
        onClose={closeCreateTaskModal}
        task={selectedTask}
      />
      <ToolbarExposed
        feature="Task"
        createFunction={() => openCreateTaskModal()}
      />
      <div className={styles.page_layout}>
        <div className={styles.table_layout}>
          {tasksLoading ? (
            <p>Loading tasks...</p>
          ) : tasksError ? (
            <p>Error loading tasks: {tasksError.message}</p>
          ) : (
            <Table
              columns={columns}
              data={tasks}
              statusColorMap={statusColorMap}
              onRowClick={(task) => openCreateTaskModal(task)}
              more_menu={more_menu}
            />
          )}
        </div>
      </div>
    </div>
  );
}
