import React from "react";

const MeetingsIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_8569777"
    enable-background="new 0 0 512 512"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m497 345.433h-17.134v-226c0-25.759-20.811-47.133-47.133-47.133h-353.466c-25.86 0-47.133 20.907-47.133 47.133v226h-17.134c-8.284 0-15 6.716-15 15v32.134c0 25.754 20.804 47.133 47.133 47.133h417.733c25.856 0 47.134-20.899 47.134-47.133v-32.134c0-8.284-6.716-15-15-15zm-434.867-226c0-9.439 7.638-17.133 17.133-17.133h353.467c9.46 0 17.133 7.657 17.133 17.133v226h-75.372c-5.333-42.368-32.949-77.891-70.702-94.442 41.648-41.584 11.703-114.566-47.793-114.424-59.494-.142-89.442 72.851-47.791 114.424-37.753 16.55-65.369 52.074-70.701 94.442h-75.374zm193.867 151.567c44.203 0 81.012 32.237 88.169 74.433h-39.969c-3.978 0-7.794 1.58-10.606 4.393-2.314 2.314-5.518 3.641-8.787 3.641h-57.613c-3.27 0-6.473-1.328-8.79-3.643-2.813-2.812-6.627-4.391-10.604-4.391h-39.968c7.156-42.196 43.965-74.433 88.168-74.433zm-37.217-67.217c0-20.521 16.695-37.216 37.217-37.216 49.306 1.871 49.292 72.57 0 74.433-20.521 0-37.217-16.695-37.217-37.217zm263.217 188.784c0 9.439-7.638 17.133-17.134 17.133h-417.733c-9.46 0-17.133-7.658-17.133-17.133v-17.134h172.337c7.187 5.217 15.806 8.034 24.856 8.034h57.613c9.05 0 17.67-2.816 24.857-8.034h172.337z"></path>
  </svg>
);

export default MeetingsIcon;
