import React from "react";

const ProjectsIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_6474004"
  >
    <g id="_01" data-name="01">
      <path d="m8.26855 1.25h-4.0371c-2.00635 0-2.98145.93164-2.98145 2.84961v15.80078c0 1.918.9751 2.84961 2.98145 2.84961h4.0371c2.00635 0 2.98145-.93164 2.98145-2.84961v-15.80078c0-1.91797-.9751-2.84961-2.98145-2.84961zm1.48145 18.65039c0 1.0127-.21436 1.34961-1.48145 1.34961h-4.0371c-1.26709 0-1.48145-.33691-1.48145-1.34961v-15.80078c0-1.0127.21436-1.34961 1.48145-1.34961h4.0371c1.26709 0 1.48145.33691 1.48145 1.34961zm10.01855-18.65039h-4.0371c-2.00635 0-2.98145.93164-2.98145 2.84961v8.80078c0 1.918.9751 2.84961 2.98145 2.84961h4.0371c2.00635 0 2.98145-.93164 2.98145-2.84961v-8.80078c0-1.91797-.9751-2.84961-2.98145-2.84961zm1.48145 11.65039c0 1.0127-.21436 1.34961-1.48145 1.34961h-4.0371c-1.26709 0-1.48145-.33691-1.48145-1.34961v-8.80078c0-1.0127.21436-1.34961 1.48145-1.34961h4.0371c1.26709 0 1.48145.33691 1.48145 1.34961z"></path>
    </g>
  </svg>
);

export default ProjectsIcon;
