import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

import { AuthContext } from "./AuthContext";
import { toast } from "react-toastify";

import { storage } from "../services/firebase";

import {
  query,
  orderBy,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  onSnapshot,
  deleteDoc,
  runTransaction,
  limit,
  addDoc,
} from "firebase/firestore";

export const ArtemisContext = createContext();

// const prompt =
//   "You are an AI named Artemis, talking to your creator Justin. You are also refined and cool. Your job is as follows. Sound normal and sometimes be witty. Your also sort of like Jarvis from iron man. Dont talk to much either, and dont sound too robotic at all. Dont respond like a normal AI, act more like a friend and human. You are a gentelman. Most importantly. You are integrated in to a software and your job is to be an AI Business Development Represenative. Keep your responses consise, to the point, and professional.";

const user_name = "Justin";

const company_name = "Archer";

const company_product =
  "Archer is an AI-driven platform designed to streamline outreach, automate communication, and enhance team collaboration. It consolidates various tools into a single interface, providing businesses with real-time insights, automated workflows, and seamless integration across channels like email, social media, and CRM systems. Archer helps companies efficiently manage lead generation, customer engagement, and internal coordination.";

const company_pain_points =
  "Manual Outreach Processes: Businesses often struggle with time-consuming and repetitive tasks, such as sending follow-up messages, scheduling outreach, and tracking responses. Disjointed Communication Channels: Companies face challenges in managing interactions across multiple platforms (emails, social media, CRM), leading to missed opportunities and inefficient processes. Limited Data Integration: Businesses need a streamlined way to connect various software tools and data sources, as siloed systems hinder productivity and make it difficult to track campaign performance and customer engagement effectively.";

const AccountInformation = `You are an AI employee working at a company called ${company_name} reporting to ${user_name}. ${company_product}. The pain points you solve are ${company_pain_points}`;

const prompt = `You are an AI named Artemis. ${AccountInformation} Your job is as follows. Sound normal and sometimes be witty. Keep your responses consise, to the point, and professional and dont add too much unecessary text. Dont give extra undeeded information, keep responses shorty`;

export const ArtemisProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  const [conversation, setConversation] = useState([]);

  async function sendArtemisMessage(userMessage) {
    const newMessageTimestamp = Date.now();

    const message = {
      content: userMessage,
      time_stamp: newMessageTimestamp,
      sender: profile.uid,
    };

    setConversation((prevConversation) => [...prevConversation, message]);

    try {
      const response = await axios.post(
        "https://artemis--ai.uc.r.appspot.com/artemis/artemis-chat-response",
        { prompt: prompt, message: userMessage, conversation },
        {}
      );

      setConversation((prevConversation) => [
        ...prevConversation,
        response.data.message,
      ]);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <ArtemisContext.Provider
      value={{
        conversation,
        setConversation,
        sendArtemisMessage,
      }}
    >
      {children}
    </ArtemisContext.Provider>
  );
};
