import React from "react";

const CircleAddIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3024515"
    width={width}
    height={height}
    viewBox="0 0 189.524 189.524"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          fill={color}
          clipRule="evenodd"
          d="m94.762 180.048c47.102 0 85.286-38.183 85.286-85.286 0-47.102-38.183-85.286-85.286-85.286-47.102 0-85.286 38.184-85.286 85.286s38.184 85.286 85.286 85.286zm0 9.476c52.335 0 94.762-42.427 94.762-94.762 0-52.336-42.427-94.762-94.762-94.762-52.336 0-94.762 42.426-94.762 94.762 0 52.335 42.426 94.762 94.762 94.762z"
          fillRule="evenodd"
        ></path>
      </g>
      <g>
        <path
          fill={color}
          clipRule="evenodd"
          d="m132.667 94.762c0 2.616-2.122 4.738-4.738 4.738h-66.334c-2.617 0-4.738-2.122-4.738-4.738s2.121-4.738 4.738-4.738h66.333c2.617 0 4.739 2.122 4.739 4.738z"
          fillRule="evenodd"
        ></path>
      </g>
      <g>
        <path
          fill={color}
          clipRule="evenodd"
          d="m94.762 132.667c-2.616 0-4.738-2.122-4.738-4.738v-66.334c0-2.617 2.122-4.738 4.738-4.738s4.738 2.121 4.738 4.738v66.333c0 2.617-2.122 4.739-4.738 4.739z"
          fillRule="evenodd"
        ></path>
      </g>
    </g>
  </svg>
);

export default CircleAddIcon;
