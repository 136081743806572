const campaigns_data = [
  {
    id: "1",
    status: "active",
    campaign_name: "Consumer Sales Chicago Campaign",
    campaign_description:
      "Targeting consumer sales in Chicago with tailored marketing to boost engagement and drive sales growth.",
    contacts_in_campaign: 201,
    delivered: 142,
    opened: 49,
    clicked: 15,
  },
  {
    id: "2",
    status: "active",
    campaign_name: "Affiliate Recruitment Male Campaign",
    campaign_description:
      "Recruiting male affiliates to expand our network, leveraging targeted messaging and incentives.",
    contacts_in_campaign: 301,
    delivered: 142,
    opened: 49,
    clicked: 15,
  },
];

export default campaigns_data;
