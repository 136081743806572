import React, { useState, useContext } from "react";
import Navbar from "../Navbar/Navbar";

import { useNavigate } from "react-router";
import styles from "./sidebar.module.css";
import { AuthContext } from "../../../context/AuthContext";
import main_menu from "./main_menu";
import ArrowIcon from "assets/SVG/GeneralIcons/ArrowIcon";
// import main_menu from "./main_menu_clear";
// import UpgradePopUp from "../../components/PopUpComponents/UpgradePopup/UpgradePopup";
// import CancelIcon from "../../../assets/SVG/CancelIcon";

import { Tooltip } from "@nextui-org/react";

import { getCSSVariable } from "../../../utils/getCSSVariable";

import AccountMenu from "../AccountMenu/AccountMenu";

import { Accordion, AccordionItem } from "@nextui-org/accordion";
import { useAccountContext } from "context/AccountContext";

export function Sidebar({ setActiveTab, activeTab }) {
  const router = useNavigate();
  const { profile } = useContext(AuthContext);
  const { isDevMode } = useAccountContext();

  const [activeIndex, setActiveIndex] = useState(null);

  const [activeIcon, setActiveIcon] = useState(null);

  const navigate = useNavigate();

  const percentComplete = 45;

  const navigateTo = (url) => {
    router(url);
  };

  let lastSection = null;

  return (
    <div className={styles.sidebar}>
      <div className={styles.navbar_sidebar_extension}>
        <AccountMenu />
      </div>
      <div className={styles.quick_controls}>
        <div className={styles.action_control}>
          <div className={styles.control_title}>Quick Actions</div>
          <div className={styles.control_shortcut}>⌘K</div>
        </div>
        <div className={styles.search_control}>
          <div className={styles.control_title}>🔍</div>
          <div className={styles.control_shortcut}>/</div>
        </div>
      </div>

      {main_menu
        .filter((item) => !item.dev_mode || isDevMode)
        .map((item, index) => {
          const isLastItem = index === main_menu.length - 1;

          const element = (
            <React.Fragment key={index}>
              {item.sub_menu ? (
                <SidebarItemSubcollection
                  key={index}
                  item={item} // Pass item instead of text
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  navigateTo={() => navigate(item.url)} // Navigate to URL on click
                />
              ) : (
                <SidebarItem
                  key={index}
                  text={item}
                  activeTab={activeTab}
                  navigateTo={() => navigate(item.url)} // Navigate to URL on click
                  type="parent"
                />
              )}

              {(isLastItem ||
                main_menu.filter((item) => !item.dev_mode || isDevMode)[
                  index + 1
                ]?.menu_section !== item.menu_section) && (
                <div className={styles.line_divider}></div>
              )}
            </React.Fragment>
          );

          lastSection = item.menu_section;

          return element;
        })}
      <div className={styles.sidebar_bottom_layout}></div>
    </div>
  );
}

const SidebarItem = ({ text, activeTab, navigateTo }) => {
  const { iconColor } = useAccountContext();

  const isActive = activeTab.name === text.title;
  const sidebarIcon = <text.Icon color={iconColor} width={13} height={13} />;

  const sidebarIconTooltip = (
    <text.Icon color={iconColor} width={11} height={11} />
  );

  function SidebarTooltip() {
    return (
      <div className={styles.tooltip_content}>
        <div className={styles.tooltip_header}>
          {sidebarIconTooltip}
          {text.title}
        </div>
        <div className={styles.tooltip_body}>{text.description}</div>
        <div className={styles.tooltip_docs}>
          Watch Tutorial
          <ArrowIcon width={8} height={8} />
        </div>
      </div>
    );
  }

  return (
    <Tooltip
      content={<SidebarTooltip />}
      placement="right"
      showArrow
      offset={10}
      radius="none"
      closeDelay={100}
    >
      <div className={styles.sidebar_tab_item}>
        <div
          className={
            isActive
              ? styles.active_sidebar_item_content
              : styles.sidebar_item_content
          }
          onClick={() => !text.coming_soon && navigateTo(text.url)}
        >
          <div className={styles.sidebar_icon}>{sidebarIcon}</div>
          <p
            className={
              isActive ? styles.active_sidebar_text : styles.sidebar_text
            }
          >
            {text.title}
          </p>
          {text.coming_soon && (
            <div className={styles.coming_soon}>Coming Soon</div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};

const SidebarItemSubcollection = ({ item, activeTab, setActiveTab }) => {
  const navigate = useNavigate();

  const handleSubItemClick = (subItem) => {
    setActiveTab({
      name: subItem.title,
      icon: subItem.Icon,
    });
    navigate(subItem.url);
  };

  return (
    <Accordion
      className={styles.sidebarAccordion}
      defaultExpandedKeys={item.title === "Autopilot" ? ["1"] : []}
    >
      <AccordionItem
        defaultExpandedKeys={item.title === "Autopilot" ? ["1"] : []}
        textValue={typeof item === "string" ? item : "Item"}
        key="1"
        title={
          <SidebarItem
            text={item}
            activeTab={activeTab}
            navigateTo={() => navigate(item.url)}
            type="parent"
          />
        }
        className={styles.accordionItem}
      >
        <div className={styles.sub_menu_layout}>
          <div className={styles.sub_menu_indicator}>
            <div className={styles.sub_menu_indicator_line}></div>
          </div>
          <div className={styles.sub_menu_items}>
            {item.sub_menu.map((subItem, subIndex) => (
              <SidebarItem
                key={subIndex}
                text={subItem}
                activeTab={activeTab}
                navigateTo={() => handleSubItemClick(subItem)}
                type="child"
              />
            ))}
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

// const OnboardingHubTile = ({ percentComplete }) => {
//   return (
//     <div className={styles.onboarding_hub_box}>
//       <div className={styles.onboarding_hub_tile}>
//         <div className={styles.onboarding_hub_top}>
//           <p className={styles.onboarding_hub_title}>Onboarding Hub</p>
//           <CancelIcon color="rgba(0, 0, 0, 0.75)" width={15} height={15} />
//         </div>
//         <p className={styles.onboarding_hub_subtitle}>
//           Earn credits getting started with Archer
//         </p>
//         <div className={styles.onboarding_bottom}>
//           <div className={styles.progress_section}>
//             <p className={styles.percent_complete_text}>
//               {percentComplete}% percent complete
//             </p>
//             <div className={styles.progress_line_background}>
//               <div
//                 className={styles.progress_line}
//                 style={{ width: `${percentComplete}%` }}
//               ></div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
