import React from "react";

const LeadCaptureIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_7629530"
  >
    <g id="Layer_45" data-name="Layer 45">
      <path d="m20.93 58.5h-6.14a9.3 9.3 0 0 1 -9.29-9.29v-4.78a1.5 1.5 0 0 0 -3 0v4.78a12.3 12.3 0 0 0 12.29 12.29h6.14a1.5 1.5 0 1 0 0-3z"></path>
      <path d="m60 42.93a1.5 1.5 0 0 0 -1.5 1.5v4.78a9.3 9.3 0 0 1 -9.29 9.29h-6.14a1.5 1.5 0 1 0 0 3h6.14a12.3 12.3 0 0 0 12.29-12.29v-4.78a1.5 1.5 0 0 0 -1.5-1.5z"></path>
      <path d="m49.21 2.5h-6.14a1.5 1.5 0 0 0 0 3h6.14a9.3 9.3 0 0 1 9.29 9.29v7.5a1.5 1.5 0 0 0 3 0v-7.5a12.3 12.3 0 0 0 -12.29-12.29z"></path>
      <path d="m4 23.79a1.5 1.5 0 0 0 1.5-1.5v-7.5a9.3 9.3 0 0 1 9.29-9.29h6.14a1.5 1.5 0 0 0 0-3h-6.14a12.3 12.3 0 0 0 -12.29 12.29v7.5a1.5 1.5 0 0 0 1.5 1.5z"></path>
      <path d="m51.57 32a19.57 19.57 0 1 0 -19.57 19.57 19.59 19.59 0 0 0 19.57-19.57zm-19.57 16.57a16.57 16.57 0 1 1 16.57-16.57 16.58 16.58 0 0 1 -16.57 16.57z"></path>
    </g>
  </svg>
);

export default LeadCaptureIcon;
