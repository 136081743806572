import React, { useRef, useEffect, useState } from "react";
import { ReactFlow, Background, BackgroundVariant } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import NewBlueprint from "./Nodes/NewBlueprint";
import Node from "./Nodes/Node";
import styles from "./styles.module.css";

export default function EditorPlayground({
  nodes,
  setNodes,
  edges,
  setEdges,
  onNodeClick,
  handleTriggerSelection,
  handleNewNodeSelect,
  selectedNode, // Pass selectedNode from parent
}) {
  const reactFlowWrapper = useRef(null);
  const [centeredNodes, setCenteredNodes] = useState([]);

  useEffect(() => {
    if (reactFlowWrapper.current) {
      const wrapperWidth = reactFlowWrapper.current.clientWidth;
      const centerX = (wrapperWidth - 350) / 2;

      const updatedNodes =
        nodes.length > 0
          ? nodes.map((node, index) => ({
              ...node,
              position: { ...node.position, x: centerX },
              data: {
                ...node.data,
                handleNewNodeSelect, // Pass the function
                isFirstNode: index === 0, // Check if it's the first node
                isLastNode: index === nodes.length - 1, // Check if it's the last node
                isSelected: selectedNode?.id === node.id, // Pass isSelected
              },
            }))
          : [
              {
                id: "newBlueprint",
                type: "custom",
                position: { x: centerX, y: 200 },
                data: { handleTriggerSelection, handleNewNodeSelect },
              },
            ];

      setCenteredNodes(updatedNodes);
    }
  }, [nodes, selectedNode]);

  const onNodeClickHandler = (event, node) => {
    if (node.id === "newBlueprint") {
      handleTriggerSelection();
    } else {
      onNodeClick(node);
    }
  };

  return (
    <div ref={reactFlowWrapper} className={styles.flow_area}>
      <ReactFlow
        nodes={centeredNodes}
        edges={edges}
        nodeTypes={{ custom: nodes.length > 0 ? Node : NewBlueprint }}
        panOnScroll={true}
        onNodeClick={onNodeClickHandler}
      >
        <Background
          id="2"
          gap={15}
          color="rgba(0, 0, 0, 0.25)"
          variant={BackgroundVariant.Dots}
        />
      </ReactFlow>
    </div>
  );
}
