import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";

import {
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  onSnapshot,
  updateDoc,
  where,
  orderBy,
  getCountFromServer,
  limit,
} from "firebase/firestore";

export const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const { profile } = useContext(AuthContext);

  const [productPlans, setProductPlans] = useState([]);
  const [customerInformation, setCustomerInformation] = useState();
  const [currentPlan, setCurrentPlan] = useState(null);

  const fetchCustomerInformation = async () => {
    if (!profile || !profile.stripe_id) {
      console.error("No customer ID available.");
      return [];
    }
    const endpoint = `https://brandlink-24432.uc.r.appspot.com/payment/fetch-customer-information/${profile.stripe_id}`;
    try {
      const response = await axios.get(endpoint);
      console.log(response.data);
      setCustomerInformation(response.data);

      const subscriptionProductId =
        response.data.subscriptions[0].items[0].product;

      const currentPlanFromProductPlans = productPlans.find(
        (plan) => plan.id === subscriptionProductId
      );

      setCurrentPlan(currentPlanFromProductPlans);
    } catch (error) {
      console.error("Error fetching customer information:", error);
      return [];
    }
  };

  useEffect(() => {
    if (profile && profile.stripe_id) {
      const getCustomerInformation = async () => {
        await fetchCustomerInformation(profile.stripe_id);
      };

      getCustomerInformation();
    }
  }, [profile, productPlans]);

  const fetchProducts = async () => {
    const endpoint = `https://brandlink-24432.uc.r.appspot.com/payment/fetch-products`;
    try {
      const response = await axios.get(endpoint, {});
      return response.data;
    } catch (error) {
      console.error("Error fetching products:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchAndSetProducts = async () => {
      try {
        const data = await fetchProducts();

        const sortedProductPlans = data.data.sort((a, b) => {
          const orderA =
            a.metadata && a.metadata.order ? parseInt(a.metadata.order) : 999;
          const orderB =
            b.metadata && b.metadata.order ? parseInt(b.metadata.order) : 999;

          return orderA - orderB;
        });

        setProductPlans(sortedProductPlans);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAndSetProducts();
  }, []);

  const createStripeCustomer = async (formData) => {
    try {
      const response = await axios.post(
        "https://brandlink-24432.uc.r.appspot.com/payment/create-stripe-customer",
        {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error creating customer:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const fetchPaymentPortalLink = async (userID, userStripeID) => {
    const apiUrl =
      "https://brandlink-24432.uc.r.appspot.com/payment/create-payment-portal";

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, userStripeID }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      const portalURL = data.portalURL;

      return portalURL;
    } catch (err) {
      console.error("Error fetching payment portal link:", err);
      throw err;
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        productPlans,
        customerInformation,
        createStripeCustomer,
        fetchPaymentPortalLink,
        currentPlan,
        setCurrentPlan,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};
