import React from "react";

const BlueprintsIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    enable-background="new 0 0 24 24"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_2211504"
  >
    <g id="line" display="none">
      <g
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
      >
        <path d="m12 .5-11.5 5.5 11.5 5.5 11.5-5.5z" display="inline"></path>
        <path d="m10 3.4 2-.9 1.5.7" display="inline"></path>
        <path d="m19.3 8 4.2 2-11.5 5.5-11.5-5.5 4.2-2" display="inline"></path>
        <path
          d="m19.3 16 4.2 2-11.5 5.5-11.5-5.5 4.2-2"
          display="inline"
        ></path>
        <path
          d="m19.3 12 4.2 2-11.5 5.5-11.5-5.5 4.2-2"
          display="inline"
        ></path>
      </g>
      <circle cx="15.5" cy="4.1" display="inline" r=".5"></circle>
    </g>
    <g id="line_expanded">
      <g>
        <path d="m13.3 3.7c.2.1.5 0 .7-.2.1-.3 0-.5-.2-.7l-1.6-.8c-.1 0-.3 0-.4 0l-2 1c-.3.1-.4.4-.3.6s.3.3.5.3h.2l1.8-.8z"></path>
        <path d="m24 10c0-.2-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-11.5-5.5c-.1 0-.3 0-.4 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2s.1 0 .2 0l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5zm-12-8.9 10.3 4.9-10.3 4.9-10.3-4.9zm10.3 16.9-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm0-4-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm-10.3.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4 3 1.4z"></path>
        <circle cx="15.5" cy="4.1" r=".5"></circle>
      </g>
    </g>
    <g id="colored_line" display="none">
      <path
        d="m24 10c0-.2-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-11.5-5.5c-.1 0-.3 0-.4 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2s.1 0 .2 0l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5zm-12-8.9 10.3 4.9-10.3 4.9-10.3-4.9zm10.3 16.9-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm0-4-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm-10.3.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4 3 1.4z"
        display="inline"
        fill="#1689fc"
      ></path>
      <g display="inline">
        <path
          d="m10 3.9c-.2 0-.4-.1-.5-.3s0-.5.3-.6l2-.9c.1-.1.3-.1.4-.1l1.5.7c.3.2.4.5.3.7-.1.3-.4.4-.7.2l-1.3-.5-1.8.8c-.1 0-.1 0-.2 0z"
          fill="#3e5959"
        ></path>
      </g>
      <g display="inline">
        <circle cx="15.5" cy="4.1" fill="#3e5959" r=".5"></circle>
      </g>
    </g>
    <g id="filled_line" display="none">
      <g display="inline">
        <path d="m12 12.5-11 5.5 11 5.5 11-5.5z" fill="#b7e0f6"></path>
        <path d="m12 8.5-11 5.5 11 5.5 11-5.5z" fill="#32d736"></path>
        <path d="m12 4.5-11 5.5 11 5.5 11-5.5z" fill="#fcbf29"></path>
        <path d="m12 .5-11 5.5 11 5.5 11-5.5z" fill="#e63950"></path>
        <path
          d="m12 10-7.5-3.8c-.8-.4-.8-1.6 0-2l-3.5 1.8 11 5.5 11-5.5-3.5-1.8c.8.4.8 1.6 0 2z"
          fill="#cc2e43"
        ></path>
        <path
          d="m12 14-7.5-3.8c-.8-.4-.8-1.6 0-2l-3.5 1.8 11 5.5 11-5.5-3.5-1.8c.8.4.8 1.6 0 2z"
          fill="#fea832"
        ></path>
        <path
          d="m12 18-7.5-3.8c-.8-.4-.8-1.6 0-2l-3.5 1.8 11 5.5 11-5.5-3.5-1.8c.8.4.8 1.6 0 2z"
          fill="#10bb67"
        ></path>
        <path
          d="m12 22-7.5-3.8c-.8-.4-.8-1.6 0-2l-3.5 1.8 11 5.5 11-5.5-3.5-1.8c.8.4.8 1.6 0 2z"
          fill="#81c8ee"
        ></path>
        <g>
          <path
            d="m13.3 3.7c.2.1.5 0 .7-.2.1-.3 0-.5-.2-.7l-1.6-.8c-.1 0-.3 0-.4 0l-2 1c-.3.1-.4.4-.3.6s.3.3.5.3h.2l1.8-.8z"
            fill="#e2566e"
          ></path>
          <path
            d="m24 10c0-.2-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-11.5-5.5c-.1 0-.3 0-.4 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2s.1 0 .2 0l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5zm-12-8.9 10.3 4.9-10.3 4.9-10.3-4.9zm10.3 16.9-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm0-4-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm-10.3.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4 3 1.4z"
            fill="#384949"
          ></path>
          <circle cx="15.5" cy="4.1" fill="#e2566e" r=".5"></circle>
        </g>
      </g>
    </g>
    <g id="duo_tone_1_" display="none">
      <path
        d="m23.5 10-11.5 5.5-11.5-5.5 4.2-2 7.3 3.5 7.3-3.5z"
        display="inline"
        fill="#00ecce"
      ></path>
      <path
        d="m23.5 18-11.5 5.5-11.5-5.5 4.2-2 7.3 3.5 7.3-3.5z"
        display="inline"
        fill="#00ecce"
      ></path>
      <g display="inline" fill="#01a28e">
        <path d="m13.3 3.7c.2.1.5 0 .7-.2.1-.3 0-.5-.2-.7l-1.6-.8c-.1 0-.3 0-.4 0l-2 1c-.3.1-.4.4-.3.6s.3.3.5.3h.2l1.8-.8z"></path>
        <path d="m24 10c0-.2-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-11.5-5.5c-.1 0-.3 0-.4 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l3.2 1.5-3.2 1.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2s.1 0 .2 0l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5l-3.2-1.5 3.2-1.5c.2-.1.3-.3.3-.5zm-12-8.9 10.3 4.9-10.3 4.9-10.3-4.9zm10.3 16.9-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm0-4-10.3 4.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4zm-10.3.9-10.3-4.9 3-1.4 7.1 3.4h.2s.1 0 .2 0l7.1-3.4 3 1.4z"></path>
        <circle cx="15.5" cy="4.1" r=".5"></circle>
      </g>
    </g>
    <g id="flat" display="none">
      <g display="inline">
        <path
          d="m12 12c-.1 0-.1 0-.2 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2z"
          fill="#cae8f9"
        ></path>
        <path
          d="m12 8c-.1 0-.1 0-.2 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2z"
          fill="#32d736"
        ></path>
        <path
          d="m12 4c-.1 0-.1 0-.2 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2z"
          fill="#fedb41"
        ></path>
        <path
          d="m12 0c-.1 0-.1 0-.2 0l-11.5 5.5c-.2.1-.3.3-.3.5s.1.4.3.5l11.5 5.5h.2z"
          fill="#fd3c65"
        ></path>
        <path
          d="m12 1c-.1 0-.1 0-.2 0l-2 1c-.3.1-.4.4-.3.6s.3.3.5.3h.2l1.8-.8z"
          fill="#fd003a"
        ></path>
        <path
          d="m23.7 17.5-11.5-5.5c-.1 0-.1 0-.2 0v12h.2l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5z"
          fill="#b7e0f6"
        ></path>
        <path
          d="m23.7 13.5-11.5-5.5c-.1 0-.1 0-.2 0v12h.2l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5z"
          fill="#10bb67"
        ></path>
        <path
          d="m23.7 9.5-11.5-5.5c-.1 0-.1 0-.2 0v12h.2l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5z"
          fill="#fcbf29"
        ></path>
        <path
          d="m23.7 5.5-11.5-5.5c-.1 0-.1 0-.2 0v12h.2l11.5-5.5c.2-.1.3-.3.3-.5s-.1-.4-.3-.5z"
          fill="#fd003a"
        ></path>
        <g fill="#e61e14">
          <path d="m13.7 1.7-1.5-.7c-.1 0-.1 0-.2 0v1.1l1.3.6c.2.1.5 0 .7-.2.1-.3 0-.6-.3-.8z"></path>
          <circle cx="15.5" cy="3.1" r=".5"></circle>
        </g>
      </g>
    </g>
  </svg>
);

export default BlueprintsIcon;
