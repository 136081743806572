import React from "react";

const OutboxIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_7133292"
  >
    <g id="Ikon">
      <path
        fill={color}
        d="m29.52832 5.06982-7.56152 22.68311a1.99254 1.99254 0 0 1 -1.89649 1.36768l-.03125-.00049a1.99184 1.99184 0 0 1 -1.88476-1.4253l-2.8537-9.51257 7.44061-7.441a.99989.99989 0 1 0 -1.41406-1.41406l-7.44061 7.441-9.513-2.85413a1.99985 1.99985 0 0 1 -.05762-3.813l22.68308-7.56106a1.99928 1.99928 0 0 1 2.5293 2.52978zm-20.78711 12.25733a.99962.99962 0 0 0 -1.41406 0l-5 5a.99989.99989 0 0 0 1.41406 1.41406l5-5a.99962.99962 0 0 0 0-1.41406zm6 6a.99962.99962 0 0 0 -1.41406 0l-5 5a.99989.99989 0 1 0 1.41406 1.41406l5-5a.99962.99962 0 0 0 0-1.41406zm-5.03906-1.03906a1.0001 1.0001 0 0 0 -1.41406.00976l-4.96582 5.03415a.99992.99992 0 1 0 1.42382 1.4043l4.96582-5.03418a1.0001 1.0001 0 0 0 -.00976-1.41403z"
      ></path>
    </g>
  </svg>
);

export default OutboxIcon;
