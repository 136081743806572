import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "./AuthContext";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";

import {
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  onSnapshot,
  updateDoc,
  where,
  orderBy,
  getCountFromServer,
  limit,
} from "firebase/firestore";

export const CampaignContext = createContext();

export const CampaignProvider = ({ children }) => {
  const [campaigns, setCampaigns] = useState([]);
  const { profile } = useContext(AuthContext);

  async function createCampaign(campaignData) {
    const url = `https://brandlink-24432.uc.r.appspot.com/campaign/create-campaign/${profile.uid}`;

    try {
      const response = await axios.post(url, campaignData);
      console.log("Campaign created successfully:", response.data);
      return response.data; // Assuming successful response includes necessary data
    } catch (error) {
      console.error(
        "Error creating campaign:",
        error.response ? error.response.data : error.message
      );
      throw error; // Re-throw the error to be caught by the caller
    }
  }

  const pauseCampaign = async (campaignId) => {
    const url = `https://brandlink-24432.uc.r.appspot.com/campaign/pause-campaign/${campaignId}`;

    try {
      const response = await axios.post(url);
      console.log("Campaign resumed successfully:", response.data);
    } catch (error) {
      console.error(
        "Error resuming campaign:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const resumeCampaign = async (campaignId) => {
    const url = `https://brandlink-24432.uc.r.appspot.com/campaign/resume-campaign/${campaignId}`;

    try {
      const response = await axios.post(url);
      console.log("Campaign resumed successfully:", response.data);
    } catch (error) {
      console.error(
        "Error resuming campaign:",
        error.response ? error.response.data : error.message
      );
    }
  };

  async function fetchUserCampaigns() {
    try {
      if (!profile?.id) {
        return;
      }
      const campaignsRef = collection(db, "Campaigns");
      const q = query(
        campaignsRef,
        where("user_id", "==", profile.id),
        orderBy("created_at", "desc")
      );
      const querySnapshot = await getDocs(q);

      const campaigns = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const campaignData = doc.data();

          const contactsRef = collection(db, `Campaigns/${doc.id}/contacts`);
          const contactsSnapshot = await getCountFromServer(contactsRef);

          return {
            id: doc.id,
            ...campaignData,
            contact_count: contactsSnapshot.data().count,
          };
        })
      );

      return campaigns;
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      throw error;
    }
  }

  async function fetchCampaignById(campaignId) {
    console.log("Fetching campaign with ID:", campaignId);
    try {
      const campaignRef = doc(db, "Campaigns", campaignId);
      const docSnapshot = await getDoc(campaignRef);

      if (docSnapshot.exists()) {
        const campaign = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        };

        const contactsRef = collection(campaignRef, "contacts");
        const contactsQuery = query(contactsRef, limit(25));
        const contactsSnapshot = await getDocs(contactsQuery);
        campaign.campaign_contacts = contactsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch the count of contacts
        const contactsCountSnapshot = await getCountFromServer(contactsRef);
        campaign.contact_count = contactsCountSnapshot.data().count;

        const messageSequenceRef = collection(campaignRef, "message_sequence");
        const messageSequenceQuery = query(messageSequenceRef, limit(25));
        const messageSequenceSnapshot = await getDocs(messageSequenceQuery);
        campaign.campaign_message_sequence = messageSequenceSnapshot.docs.map(
          (doc) => ({
            id: doc.id,
            ...doc.data(),
          })
        );

        console.log("Fetched campaign:", campaign);
        return campaign;
      } else {
        console.log("No campaign found with the given ID.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching campaign:", error);
      throw error;
    }
  }

  return (
    <CampaignContext.Provider
      value={{
        campaigns,
        setCampaigns,
        createCampaign,
        fetchUserCampaigns,
        fetchCampaignById,
        pauseCampaign,
        resumeCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
