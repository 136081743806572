import React from "react";

const RecurringScheduleIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="-4 1 511 511.99997"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_1147726"
  >
    <path
      fill={color}
      d="m432.289062 0h-360.109374c-39.527344 0-71.679688 32.164062-71.679688 71.6875v368.625c0 39.523438 32.152344 71.6875 71.679688 71.6875h360.109374c39.523438 0 71.675782-32.164062 71.675782-71.6875 0-17.132812 0-363.082031 0-368.625 0-39.523438-32.152344-71.6875-71.675782-71.6875zm40.828126 440.3125c0 22.515625-18.320313 40.839844-40.828126 40.839844h-360.109374c-22.507813 0-40.832032-18.324219-40.832032-40.839844v-286.109375h441.769532zm-441.769532-368.625c0-22.515625 18.324219-40.839844 40.832032-40.839844h360.109374c22.507813 0 40.828126 18.324219 40.828126 40.839844v51.671875h-441.769532zm0 0"
    ></path>
    <path
      fill={color}
      d="m145.417969 56.769531c-11.238281 0-20.335938 9.097657-20.335938 20.339844 0 11.226563 9.097657 20.328125 20.335938 20.328125 11.230469 0 20.328125-9.101562 20.328125-20.328125 0-11.242187-9.097656-20.339844-20.328125-20.339844zm0 0"
    ></path>
    <path
      fill={color}
      d="m359.046875 56.769531c-11.230469 0-20.328125 9.097657-20.328125 20.339844 0 11.226563 9.097656 20.328125 20.328125 20.328125 11.238281 0 20.335937-9.101562 20.335937-20.328125 0-11.242187-9.097656-20.339844-20.335937-20.339844zm0 0"
    ></path>
    <path
      fill={color}
      d="m145.914062 389.011719 16.058594-4.359375c39.480469 57.621094 102.519532 54.125 102.847656 54.210937 67.789063 0 123.675782-55.0625 124.621094-122.867187.460938-33.632813-12.277344-65.34375-35.875-89.269532-51.664062-52.40625-138.308594-49.6875-181.175781 10.601563-4.9375 6.941406-3.3125 16.5625 3.628906 21.5 6.941407 4.933594 16.574219 3.308594 21.511719-3.628906 31.851562-44.78125 95.992188-45.425781 134.070312-6.820313 58.167969 59.011719 16.207032 159.636719-66.78125 159.636719-.652343-.148437-40.792968 2.359375-70.714843-32.082031l15.710937-4.265625c9.101563-2.46875 12.113282-13.871094 5.421875-20.515625l-46.972656-46.660156c-6.683594-6.652344-18.066406-3.558594-20.480469 5.5625l-16.925781 63.996093c-2.414063 9.121094 5.953125 17.429688 15.054687 14.960938zm0 0"
    ></path>
  </svg>
);

export default RecurringScheduleIcon;
