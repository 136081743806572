import { heIL } from "@mui/x-date-pickers/locales";
import React from "react";

const DocumentsIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    version="1.1"
    id="fi_937178"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 367.18 367.18"
  >
    <g>
      <g>
        <g>
          <path
            d="M362.266,68.69c-4.301-2.099-9.523-0.307-11.622,4.045l-8.499,17.51l-6.81-3.277l8.96-18.432
				c0-0.051,0.051-0.102,0.051-0.154c0.051-0.102,0.102-0.256,0.154-0.358c1.382-3.43,2.97-7.475,3.533-12.032
				c1.741-13.568-3.43-27.904-13.875-38.4c-9.933-9.984-25.139-15.616-39.578-14.746c-3.021,0.205-6.246,0.768-9.574,1.741
				c-5.274,1.536-10.445,4.198-15.309,7.936c-3.482,2.662-8.346,7.219-11.674,13.619c0,0.051-0.051,0.102-0.051,0.154
				s-0.051,0.102-0.051,0.102l-31.744,65.485c-0.154,0.256-0.307,0.512-0.461,0.819c-0.154,0.256-0.256,0.563-0.358,0.87
				L143.13,263.045c-1.229,2.56-1.843,5.427-1.741,8.294v0.051l1.843,54.63c0.205,5.325,2.202,10.291,5.53,14.182l-4.864,9.83
				c-2.15,4.301-0.358,9.523,3.942,11.674c1.229,0.614,2.56,0.922,3.84,0.922c3.226,0,6.298-1.792,7.834-4.864l4.71-9.574
				c0.717,0.051,1.382,0.102,2.099,0.102c4.813,0,9.574-1.485,13.568-4.454l44.134-32.358c0.922-0.666,1.792-1.485,2.56-2.304
				c0.154-0.154,0.256-0.307,0.41-0.461c0.973-1.126,1.792-2.406,2.458-3.738l98.253-202.394l6.81,3.277l-43.776,90.163
				c-2.099,4.301-0.307,9.523,4.045,11.622c1.229,0.614,2.509,0.87,3.789,0.87c3.226,0,6.349-1.792,7.834-4.915l47.002-96.819
				c0.256-0.358,0.512-0.768,0.717-1.178c0.205-0.41,0.307-0.768,0.461-1.178l11.725-24.166
				C368.41,76.011,366.567,70.789,362.266,68.69z M169.55,329.861c-1.587,1.126-3.584,1.382-5.376,0.717
				c-0.256-0.154-0.512-0.307-0.819-0.461c-0.154-0.051-0.256-0.102-0.41-0.154c-1.485-1.024-2.406-2.662-2.458-4.454l-1.229-35.584
				c11.52,8.96,24.781,15.411,38.912,18.995L169.55,329.861z M215.322,294.175c-20.941-2.048-40.499-11.571-55.04-26.829
				l77.21-159.13l55.142,26.675L215.322,294.175z M330.727,53.791c-0.307,2.406-1.28,4.966-2.253,7.373l-28.16,58.01l-55.194-26.675
				l28.262-58.266c1.536-2.816,3.942-5.632,6.912-7.885c3.072-2.355,6.349-4.096,9.472-5.018c2.099-0.614,4.096-0.973,5.786-1.075
				c9.472-0.563,19.763,3.226,26.214,9.677C328.321,36.485,331.751,45.65,330.727,53.791z"
          ></path>
          <path
            d="M116.609,347.013c-2.202-0.051-4.762,0-7.424,0c-13.926,0.154-39.885,0.41-48.179-8.858
				c-1.434-1.587-3.277-4.454-2.611-10.394c1.946-16.742,11.366-34.355,19.661-49.869l1.382-2.611
				c2.714-5.069,5.53-10.189,8.294-15.206c7.936-14.336,16.128-29.184,22.63-44.8c4.813-11.52,7.475-22.374,7.936-32.154
				c0.614-13.107-2.662-24.525-9.728-33.946c-8.448-11.315-20.787-19.61-37.683-25.446c-14.029-4.864-29.491-7.373-43.11-9.626
				c-6.144-1.024-11.981-1.946-17.357-3.072c-4.71-0.973-9.318,2.099-10.24,6.81c-0.973,4.71,2.099,9.318,6.81,10.24
				c5.734,1.178,11.725,2.15,18.022,3.174c25.856,4.25,55.194,9.011,69.632,28.314c8.55,11.418,8.448,27.904-0.358,48.998
				c-6.144,14.746-14.08,29.133-21.811,43.059c-2.765,5.018-5.632,10.24-8.397,15.411l-1.382,2.56
				c-9.062,16.896-19.302,36.045-21.606,56.064c-1.126,9.574,1.229,17.664,6.963,24.064c12.032,13.363,35.84,14.694,54.323,14.694
				c2.406,0,4.762,0,6.963-0.051c2.611,0,5.018-0.051,7.066,0c0.051,0,0.051,0,0.102,0c4.762,0,8.653-3.84,8.704-8.602
				C125.262,351.058,121.421,347.064,116.609,347.013z"
          ></path>
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default DocumentsIcon;
