import React, { useState } from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";

import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";

import { useNavigate } from "react-router-dom";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

import SliderHeaderMenu from "components/Common/SliderHeaderMenu/SliderHeaderMenu";

import Blueprints from "../Blueprints/Blueprints";
import Campaigns from "../Campaigns/Campaigns";
import Templates from "../Templates/Templates";

import CampaignIcon from "assets/SVG/NewSidebarIcons/CampaignIcon";
import BlueprintsIcon from "assets/SVG/SliderHeaderIcons/BlueprintsIcon";
import TemplateIcon from "assets/SVG/NewSidebarIcons/TemplateIcon";
import AnalyticsIcon from "assets/SVG/SliderHeaderIcons/AnalyticsIcon";

export default function AutoPilot() {
  const quickstartPlaceholder =
    "Example: Create a funnel that I can link on my Instagram";

  const navigate = useNavigate();

  function handleEditor() {
    console.log("Navigating to funnel editor");
    navigate("/blueprints/editor");
  }

  const menuItems = [
    {
      icon: <BlueprintsIcon width={12} height={12} />,
      label: "Blueprints",
    },
    {
      icon: <CampaignIcon width={12} height={12} />,
      label: "Campaigns",
    },
    {
      icon: <TemplateIcon width={12} height={12} />,
      label: "Templates",
    },
    // {
    //   icon: <AnalyticsIcon width={12} height={12} />,
    //   label: "Analytics",
    // },
  ];
  const [activeTab, setActiveTab] = useState(menuItems[0]);

  // Function to render the content based on the active tab
  const renderTabContent = () => {
    switch (activeTab.label) {
      case "Blueprints":
        return <Blueprints />;
      case "Campaigns":
        return <Campaigns />;
      case "Templates":
        return <Templates />;
      //   case "Analytics":
      //     return <div>Analytics content goes here.</div>;
      default:
        return null;
    }
  };

  return (
    <div className={styles.main_layout}>
      <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {/* <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
      <ToolbarExposed feature="Blueprint" createFunction={handleEditor} /> */}
      <div className={styles.page_layout}>{renderTabContent()}</div>
    </div>
  );
}

// function Tile() {
//   return (
//     <div className={styles.tile}>
//       <div className={styles.tile_header}>
//         <p className={styles.tile_title}>Weekly Prospecting Outreach</p>
//         <More width={8} height={8} />
//       </div>
//       <div className={styles.tile_body}></div>
//       <div className={styles.tile_footer}>
//         <AccountTag />
//         <p className={styles.last_updated}>Updated Today</p>
//       </div>
//     </div>
//   );
// }
