import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { getCSSVariable } from "utils/getCSSVariable";

export const AccountContext = createContext();

export const AccountProvider = ({ children }) => {
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const PROFILE_ID = "6737c97ec8fe0fd2eda2de9c";

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [iconColor, setIconColor] = useState(getCSSVariable("--icon-color"));
  const [isDevMode, setIsDevMode] = useState(false);

  useEffect(() => {
    setIconColor(getCSSVariable("--icon-color"));
  }, [isDarkMode]);

  const fetchAccountData = async () => {
    const response = await axios.get(`${API_BASE_URL}/account`, {
      params: { account_id: PROFILE_ID },
    });

    if (response.status !== 200) {
      throw new Error(response.data?.error || "Failed to fetch account data");
    }
    console.log(response.data);
    return response.data;
  };

  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
  } = useQuery({
    queryKey: ["accountData", PROFILE_ID],
    queryFn: fetchAccountData,
    enabled: !!PROFILE_ID,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <AccountContext.Provider
      value={{
        user_id: profileData?.account?._id,
        org_id: profileData?.organization?._id,
        profile: profileData,
        profileLoading,
        profileError,
        iconColor,
        isDarkMode,
        setIsDarkMode,
        isDevMode,
        setIsDevMode,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => useContext(AccountContext);
