// AccountMenu.jsx
import React, { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import styles from "./styles.module.css";

import WorkflowIcon from "assets/SVG/NewSidebarIcons/WorkflowIcon";
import LogoutIcon from "assets/SVG/AccountMenu/LogoutIcon";
import NotificationsIcon from "assets/SVG/AccountMenu/NotificationsIcon";
import AccountSettingsIcon from "assets/SVG/AccountMenu/AccountSettingsIcon";
import WorkspaceSettingsIcon from "assets/SVG/AccountMenu/WorkspaceSettingsIcon";
import InviteMemberIcon from "assets/SVG/AccountMenu/InviteMemberIcon";
import DarkModeIcon from "assets/SVG/AccountMenu/DarkModeIcon";
import DeveloperToolsIcon from "assets/SVG/SliderHeaderIcons/DeveloperToolsIcon";

import Switch from "components/Common/Switch/Switch";

import { useAccountContext } from "context/AccountContext";

function AccountMenu() {
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const { isDarkMode, setIsDarkMode, isDevMode, setIsDevMode } =
    useAccountContext();

  const handleMenuClick = (action, payload, isSwitch) => {
    if (action) {
      action(payload);
    }
    // Close the menu only if the item is not a switch
    if (!isSwitch) {
      setPopoverOpen(false);
    }
  };

  const accountMenu = [
    {
      title: "Account Settings",
      icon: AccountSettingsIcon,
      action: () => console.log("Account settings"),
      key: "account-settings",
    },
    {
      title: "Workspace Settings",
      icon: WorkspaceSettingsIcon,
      action: () => console.log("Account settings"),
      key: "account-settings",
    },
    {
      title: "Notifications",
      icon: NotificationsIcon,
      action: () => console.log("Account settings"),
      key: "account-settings",
      divider: true,
    },
    {
      title: "Dark Mode",
      icon: DarkModeIcon,
      switch: true,
      key: "dark-mode",
      id: "dark-mode-switch",
      isToggled: isDarkMode,
      onToggle: () => setIsDarkMode(!isDarkMode),
      divider: true,
    },
    {
      title: "Developer Mode",
      icon: DeveloperToolsIcon,
      switch: true,
      key: "dev-mode",
      id: "dev-mode-switch",
      isToggled: isDevMode,
      onToggle: () => setIsDevMode(!isDevMode),
      divider: true,
    },
    {
      title: "Invite Member",
      icon: InviteMemberIcon,
      action: () => console.log("Account settings"),
      key: "account-settings",
    },
    {
      title: "Logout",
      icon: LogoutIcon,
      action: () => console.log("Account settings"),
      key: "logout",
    },
  ];

  return (
    <Popover
      placement="bottom-start"
      isOpen={isPopoverOpen}
      onOpenChange={setPopoverOpen}
      classNames={{
        content: styles.customPopoverContent,
      }}
    >
      <PopoverTrigger>
        <div
          className={styles.account_button}
          style={{
            width: "250px",
            height: "50px",
            borderRadius: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0 16px",
          }}
        >
          <div className={styles.sidebar_profile_info}>
            <img
              src="/ArcherLogoBugBlackBg.png"
              className={styles.sidebar_profile_image}
              alt="Profile"
            />
            <div className={styles.sidebar_profile_text}>
              <p className={styles.sidebar_profile_name}>Justin Cose</p>
              <p className={styles.sidebar_profile_company}>CEO at Archer</p>
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <div className={styles.customMenu}>
          <div className={styles.menu_header}>
            <div className={styles.sidebar_profile_info}>
              <img
                src="/JustinImagePlaceholder.jpeg"
                className={styles.sidebar_profile_image}
                alt="Profile"
              />
              <div className={styles.sidebar_profile_text}>
                <p className={styles.sidebar_profile_name}>Justin Cose</p>
                <p className={styles.sidebar_profile_company}>
                  justin@askarcher.ai
                </p>
              </div>
            </div>
          </div>
          <div className={styles.menu_body}>
            {accountMenu.map((item) => (
              <div key={item.key}>
                <div
                  className={styles.menuItem}
                  onClick={() =>
                    handleMenuClick(item.action, null, item.switch)
                  }
                >
                  <div className={styles.menuItemLeft}>
                    <item.icon width={12} height={12} />
                    <p className={styles.sectionTitle}>{item.title}</p>
                  </div>
                  {item.switch && (
                    <Switch
                      id={item.id}
                      isToggled={item.isToggled}
                      onToggle={item.onToggle}
                    />
                  )}
                </div>

                {item.divider && <div className={styles.line_divider} />}
              </div>
            ))}
          </div>
          <div className={styles.menu_footer}>
            <CreditsRemaining />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default AccountMenu;

const CreditsRemaining = ({ percentComplete = 37 }) => {
  return (
    <div className={styles.progress_section}>
      <p className={styles.percent_complete_text}>
        {percentComplete}% percent complete
      </p>
      <div className={styles.progress_line_background}>
        <div
          className={styles.progress_line}
          style={{ width: `${percentComplete}%` }}
        ></div>
      </div>
    </div>
  );
};
