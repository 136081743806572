import React, { useState } from "react";
import { useNodesState, useEdgesState } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import styles from "./styles.module.css";

import SliderHeaderMenu from "../../../Common/SliderHeaderMenu/SliderHeaderMenu";
import FunnelPreview from "./FunnelPreview";
import EditorPlayground from "./FunnelPlaygrond";
import EditorSidebar from "./FunnelSidebar";
import FunnelStages from "./FunnelStages";

import HeaderMenu from "./HeaderMenu";

import SettingsIcon from "assets/SVG/SliderHeaderIcons/SettingsIcon";
import TemplateIcon from "assets/SVG/NewSidebarIcons/TemplateIcon";
import WorkflowIcon from "assets/SVG/NewSidebarIcons/WorkflowIcon";
import BlastsIcon from "assets/SVG/NewSidebarIcons/BlastsIcon";

import FunnelNew from "./FunnelNew";

export default function FunnelEditor() {
  const menuItems = [
    {
      icon: <TemplateIcon width={12} height={12} />,
      label: "Create",
    },
    {
      icon: <WorkflowIcon width={12} height={12} />,
      label: "Logic",
    },
    {
      icon: <BlastsIcon width={12} height={12} />,
      label: "Responses",
    },
    {
      icon: <SettingsIcon width={12} height={12} />,
      label: "Settings",
    },
    // {
    //   icon: <AnalyticsIcon width={12} height={12} />,
    //   label: "Analytics",
    // },
  ];

  const [activeTab, setActiveTab] = useState(menuItems[0]);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [view, setView] = useState("blueprintProperties");
  const [type, setType] = useState(null);

  return (
    <div className={styles.main_layout}>
      {!type ? (
        <FunnelNew />
      ) : (
        <>
          <SliderHeaderMenu
            menuItems={menuItems}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className={styles.page_layout}>
            <EditorSidebar
              selectedNode={selectedNode}
              setNodes={setNodes}
              setEdges={setEdges}
              setSelectedNode={setSelectedNode}
              view={view}
              setView={setView}
            />
            <div className={styles.content_area}>
              {/* <FunnelStages websiteForm={websiteForm} /> */}
              <HeaderMenu />
              <FunnelPreview websiteForm={websiteForm} />
            </div>

            {/* <EditorPlayground
          nodes={nodes}
          setNodes={setNodes}
          edges={edges}
          setEdges={setEdges}
          onNodeClick={handleNodeSelection}
          handleTriggerSelection={handleTriggerSelection}
        /> */}
          </div>
        </>
      )}
    </div>
  );
}

const websiteForm = {
  title: "Inbound Lead Collection Form",
  description:
    "We'd love to learn more about your company and how we can help! Please fill out this quick form.",
  steps: [
    {
      stepTitle: "What’s your name?",
      stepType: "text", // Text input
      placeholder: "Enter your full name",
      required: true,
    },
    {
      stepTitle: "What’s your email address?",
      stepType: "email", // Email input
      placeholder: "Enter your email",
      required: true,
    },
    {
      stepTitle: "How big is your company?",
      stepType: "dropdown", // Dropdown select
      options: [
        "1-10 employees",
        "11-50 employees",
        "51-200 employees",
        "201-500 employees",
        "500+ employees",
      ],
      required: true,
    },
    {
      stepTitle: "What’s your role?",
      stepType: "radio", // Single choice
      options: [
        "CEO/Founder",
        "Marketing Manager",
        "Sales Manager",
        "Developer/Engineer",
        "Other",
      ],
      required: true,
    },
    {
      stepTitle: "What’s your main goal with our platform?",
      stepType: "text", // Open-ended text input
      placeholder: "E.g., increase sales, improve workflow, etc.",
      required: false,
    },
    {
      stepTitle: "How did you hear about us?",
      stepType: "dropdown", // Dropdown select
      options: [
        "Google Search",
        "Social Media",
        "Referral",
        "Advertisement",
        "Other",
      ],
      required: true,
    },
    {
      stepTitle: "Would you like to schedule a demo?",
      stepType: "radio", // Yes/No
      options: ["Yes", "No"],
      required: true,
    },
    {
      stepTitle: "Any additional comments or questions?",
      stepType: "textarea", // Multi-line text input
      placeholder: "Let us know your thoughts!",
      required: false,
    },
  ],
  metadata: {
    createdBy: "Marketing Team",
    createdAt: "2024-11-18T12:00:00Z",
    formType: "Lead Generation",
  },
};
