import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./styles.module.css";
import RefreshIcon from "../../../assets/SVG/RefreshIcon";

import CampaignActionIcon from "../../../assets/SVG/ActionIcons/CampaignActionIcon";
import ListActionIcon from "../../../assets/SVG/ActionIcons/ListActionIcon";
import JoinMeetingActionIcon from "../../../assets/SVG/ActionIcons/JoinMeetingActionIcon";
import PlayActionIcon from "../../../assets/SVG/ActionIcons/PlayActionIcon.js.js";

import { getCSSVariable } from "../../../utils/getCSSVariable";

const suggestedPrompts = [
  {
    prompt: "Start outbound recruiting for sales reps",
    icon: CampaignActionIcon,
  },
  {
    prompt: "Find a list of B2B sales prospects in tech",
    icon: ListActionIcon,
  },
  {
    prompt: "Join my meeting in an hour and take notes",
    icon: JoinMeetingActionIcon,
  },
  {
    prompt: "Post on my Insta and Linkedin everyday",
    icon: PlayActionIcon,
  },
];

export default function NewConversationPrompt({ setUserInput }) {
  return (
    <div className={styles.new_conversation_prompt}>
      <p className={styles.new_conversation_title}>Hi there, Justin</p>
      <p className={styles.new_conversation_title}>
        I'm Archer, how can I help you today?
      </p>
      <p className={styles.new_conversation_subtitle}>
        Use a common prompt below or ask me anything else
      </p>
      <div className={styles.suggested_prompt_list}>
        {suggestedPrompts.map((suggestion, index) => (
          <div
            key={index}
            className={styles.suggested_prompt_box}
            onClick={() => setUserInput(suggestion.prompt)}
          >
            <p className={styles.suggested_prompt_title}>{suggestion.prompt}</p>
            <suggestion.icon
              color={getCSSVariable("--icon-color")}
              width={14}
              height={14}
            />
          </div>
        ))}
      </div>
      <div className={styles.refresh_button}>
        <RefreshIcon
          width={15}
          height={15}
          color={getCSSVariable("--icon-color")}
        />
        <p className={styles.refresh_text}>Refresh Prompts</p>
      </div>
    </div>
  );
}
