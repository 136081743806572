import React from "react";

const OutlookIcon = ({
  width = 20,
  height = 20,
  color = "rgba(255, 255, 255, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_16509303"
    viewBox="0 0 128 128"
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m120.2 22.8h-86v-13.7c0-2.3 1.8-4.1 4-4.1h77.9c2.3 0 4.1 1.8 4.1 4.1z"
        fill="#0464b8"
      ></path>
      <path d="m34.2 22.8h28.7v26.6h-28.7z" fill="#0377d4"></path>
      <path d="m62.9 22.8h28.7v26.6h-28.7z" fill="#29a7eb"></path>
      <path d="m91.6 22.8h28.7v26.6h-28.7z" fill="#4fd8ff"></path>
      <path d="m34.2 49.4h28.7v26.6h-28.7z" fill="#1681d7"></path>
      <path d="m62.9 49.4h28.7v26.6h-28.7z" fill="#0178d4"></path>
      <path d="m91.6 49.4h28.7v26.6h-28.7z" fill="#29a7eb"></path>
      <path d="m62.9 75.9h28.7v26.6h-28.7z" fill="#0464b8"></path>
      <path d="m91.6 75.9h28.7v26.6h-28.7z" fill="#1681d7"></path>
    </g>
    <path
      d="m126.9 69.1-6.6 3.9v-11.2l6.5 3.6c1.5.7 1.5 2.8.1 3.7z"
      fill="#042071"
    ></path>
    <path
      d="m126.9 69.1-.6.4-.7.4-5.3 3.1v-.1l-88.4 50.6h89.1c3.8 0 6.8-3.1 6.8-6.8l.1-49.4c.1.6-.3 1.4-1 1.8z"
      fill="#168fde"
    ></path>
    <g>
      <path
        d="m122 123.5h-89.2c-3.8 0-6.8-3.1-6.8-6.8v-48.2z"
        fill="#29a7eb"
      ></path>
    </g>
    <path
      d="m59 96.5h-53c-3.5 0-6.4-2.9-6.4-6.4v-52.2c0-3.5 2.9-6.4 6.4-6.4h53c3.5 0 6.4 2.9 6.4 6.4v52.2c0 3.5-2.8 6.4-6.4 6.4z"
      fill="#1681d7"
    ></path>
    <g>
      <path
        d="m32.5 82.9c-10.3 0-16.8-7.8-16.8-18.2 0-11 7.1-18.8 17.3-18.8 10.6 0 16.8 7.9 16.8 18.1.1 12.1-7.3 18.9-17.3 18.9zm.4-5.2c6.4 0 10-5.9 10-13.4 0-6.8-3.4-13.1-10-13.1s-10.1 6.2-10.1 13.4 3.6 13.1 10.1 13.1z"
        fill="#fff"
      ></path>
    </g>
    <path
      d="m65.5 37.3v.6 52.2c0 3.5-2.9 6.4-6.4 6.4h-33.1v5.7h38.4c3.5 0 6.4-2.9 6.4-6.4v-52.2c0-3.2-2.3-5.9-5.3-6.3z"
      opacity=".2"
    ></path>
  </svg>
);

export default OutlookIcon;
