import React from "react";

const DeveloperToolsIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_2572691"
    enable-background="new 0 0 24 24"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m13.663 24h-3.326c-.812 0-1.503-.555-1.681-1.349l-.422-1.873c-.687-.291-1.341-.671-1.954-1.134l-1.822.575c-.782.241-1.632-.09-2.024-.788l-1.658-2.868c-.397-.71-.263-1.585.322-2.135l1.412-1.298c-.043-.38-.065-.759-.065-1.13s.021-.75.064-1.13l-1.405-1.292c-.591-.556-.726-1.43-.333-2.131l1.668-2.885c.388-.69 1.237-1.024 2.017-.781l1.824.575c.613-.463 1.268-.843 1.954-1.134l.422-1.874c.178-.793.869-1.348 1.681-1.348h3.326c.812 0 1.503.555 1.681 1.349l.422 1.873c.687.291 1.341.671 1.954 1.134l1.822-.575c.78-.244 1.631.09 2.024.788l1.658 2.868c.397.71.263 1.585-.322 2.135l-1.412 1.298c.043.38.064.759.064 1.13s-.021.75-.064 1.13l1.406 1.292c.002.002.004.004.006.006.585.55.72 1.424.327 2.125l-1.668 2.885c-.389.69-1.238 1.024-2.017.781l-1.825-.576c-.613.463-1.268.843-1.954 1.134l-.422 1.874c-.177.794-.868 1.349-1.68 1.349zm-7.229-5.941c.172 0 .341.059.478.171.685.565 1.435 1 2.228 1.293.238.088.416.291.472.539l.509 2.26c.023.103.115.178.218.178h3.326c.103 0 .194-.075.218-.177l.509-2.26c.056-.248.233-.451.472-.539.793-.293 1.543-.729 2.228-1.293.196-.162.459-.214.703-.137l2.201.695c.104.032.216-.004.265-.093l1.668-2.884c.05-.089.032-.211-.049-.289l-1.688-1.552c-.184-.169-.271-.417-.233-.664.066-.44.1-.88.1-1.307s-.033-.866-.1-1.307c-.037-.247.05-.495.233-.664l1.692-1.556c.076-.072.096-.195.04-.294l-1.658-2.867c-.054-.095-.162-.132-.272-.1l-2.198.694c-.244.077-.507.025-.703-.137-.685-.565-1.435-1-2.228-1.293-.238-.088-.416-.291-.472-.539l-.509-2.26c-.027-.102-.118-.177-.221-.177h-3.326c-.103 0-.194.075-.218.177l-.509 2.261c-.055.248-.233.45-.471.539-.793.293-1.543.728-2.228 1.293-.196.162-.459.213-.703.137l-2.201-.695c-.104-.032-.216.005-.265.093l-1.668 2.883c-.051.09-.031.213.051.291l1.687 1.55c.184.169.271.417.233.664-.066.44-.1.88-.1 1.307s.033.866.1 1.307c.037.247-.05.495-.233.664l-1.692 1.556c-.076.072-.096.195-.04.294l1.658 2.867c.055.096.164.135.272.1l2.198-.694c.074-.024.15-.035.226-.035z"></path>
    <path d="m11.251 16c-.057 0-.113-.006-.17-.019-.403-.093-.655-.496-.562-.899l1.5-6.5c.093-.405.501-.654.899-.563.403.093.655.496.562.899l-1.5 6.5c-.08.348-.388.582-.729.582z"></path>
    <path d="m15.25 15c-.205 0-.409-.083-.558-.248-.277-.308-.252-.782.056-1.06l1.881-1.692-1.881-1.692c-.308-.277-.333-.751-.056-1.06.278-.307.752-.332 1.06-.056l2.5 2.25c.158.143.248.346.248.558s-.09.415-.248.558l-2.5 2.25c-.144.129-.323.192-.502.192z"></path>
    <path d="m8.75 15c-.179 0-.358-.063-.502-.192l-2.5-2.25c-.158-.143-.248-.346-.248-.558s.09-.415.248-.558l2.5-2.25c.308-.277.781-.252 1.06.056.277.308.252.782-.056 1.06l-1.881 1.692 1.881 1.692c.308.277.333.751.056 1.06-.149.165-.353.248-.558.248z"></path>
  </svg>
);

export default DeveloperToolsIcon;
