import HomeIcon from "../../../assets/SVG/NewSidebarIcons/HomeIcon";
import AskArcherIcon from "../../../assets/SVG/NewSidebarIcons/AskArcherIcon";
import WorkflowIcon from "../../../assets/SVG/NewSidebarIcons/WorkflowIcon";
import SearchIcon from "../../../assets/SVG/NewSidebarIcons/SearchIcon";
import InboxIcon from "../../../assets/SVG/NewSidebarIcons/InboxIcon";
import CampaignIcon from "../../../assets/SVG/NewSidebarIcons/CampaignIcon";
import CalendarIcon from "../../../assets/SVG/NewSidebarIcons/CalendarIcon";
import RecordsIcon from "../../../assets/SVG/NewSidebarIcons/RecordsIcon";
import DocumentsIcon from "../../../assets/SVG/NewSidebarIcons/DocumentsIcon";
import TasksIcon from "../../../assets/SVG/NewSidebarIcons/TasksIcon";
import BroadcastIcon from "../../../assets/SVG/NewSidebarIcons/BroadcastIcon";
import SocialMediaIcon from "../../../assets/SVG/NewSidebarIcons/SocialMediaIcon";
import FormsIcon from "../../../assets/SVG/NewSidebarIcons/Forms";
import IntegrationsIcon from "../../../assets/SVG/NewSidebarIcons/IntegrationsIcon";

import ProjectsIcon from "../../../assets/SVG/NewSidebarIcons/ProjectsIcon";

import ContactsIcon from "../../../assets/SVG/NewSidebarIcons/ContactsIcon";

import ObjectIcon from "../../../assets/SVG/NewSidebarIcons/ObjectIcon";
import BlueprintIcon from "../../../assets/SVG/NewSidebarIcons/BlueprintIcon";

import InvoiceIcon from "../../../assets/SVG/NewSidebarIcons/InvoiceIcon";
import TemplateIcon from "../../../assets/SVG/NewSidebarIcons/TemplateIcon";
import NotesIcon from "../../../assets/SVG/NewSidebarIcons/NotesIcon";
import BlastsIcon from "../../../assets/SVG/NewSidebarIcons/BlastsIcon";
import Approval from "../../../assets/SVG/NewSidebarIcons/Approval";
import PipelinesIcon from "../../../assets/SVG/NewSidebarIcons/PipelinesIcon";
import AccountsIcon from "assets/SVG/NewSidebarIcons/AccountsIcon";
import TicketsIcon from "assets/SVG/NewSidebarIcons/TicketsIcon";
import LeadCaptureIcon from "assets/SVG/NewSidebarIcons/LeadCaptureIcon";
import ClientPortal from "assets/SVG/NewSidebarIcons/ClientPortal";
import MeetingsIcon from "assets/SVG/NewSidebarIcons/MeetingsIcon";
const main_menu = [
  // {
  //   id: 0,
  //   title: "Admin",
  //   Icon: ObjectIcon,
  //   iconKey: "stroke",
  //   url: "/admin",
  //   isSelf: true,
  //   menu_section: "Admin",
  // },
  {
    id: 1,
    title: "Ask Archer",
    Icon: AskArcherIcon,
    url: "/ask-archer",
    menu_section: "General",
    description:
      "Your AI assistant for task execution and platform navigation.",
  },
  {
    id: 2,
    title: "Dashboard",
    Icon: HomeIcon,
    url: "/dashboard",
    menu_section: "General",
    description: "Overview of tasks, performance stats, and upcoming meetings.",
  },
  // {
  //   id: 2,
  //   title: "Reports",
  //   Icon: DocumentsIcon,
  //   url: "/reports",
  //   menu_section: "General",
  //   description:
  //     "Access detailed insights and analytics about your campaigns and activities.",
  // },

  {
    id: 4,
    title: "Inboxes",
    Icon: InboxIcon,
    url: "/inbox",
    menu_section: "Communication",
    description:
      "Central hub for managing emails, social media messages, and communications.",
  },
  {
    id: 10,
    title: "Meetings",
    Icon: MeetingsIcon,
    url: "/meetings",
    menu_section: "Communication",
    description:
      "Integrated calendar and scheduling tools with AI meeting assistance.",
  },

  // {
  //   id: 7,
  //   title: "Contacts",
  //   Icon: ContactsIcon,
  //   url: "/contacts",
  //   menu_section: "Data",
  //   description:
  //     "Advanced contact management with tagging and syncing capabilities.",
  // },
  {
    id: 5,
    title: "Prospector",
    Icon: SearchIcon,
    url: "/prospector",
    menu_section: "Data",
    description:
      "Search and discover leads across a massive database of profiles.",
  },
  {
    id: 7,
    title: "Records",
    Icon: RecordsIcon,
    url: "/accounts",
    menu_section: "Data",
    description: "Manage and segment your client accounts with precision.",
  },
  // {
  //   id: 7,
  //   title: "Accounts",
  //   Icon: AccountsIcon,
  //   url: "/accounts",
  //   menu_section: "Data",
  //   description: "Manage and segment your client accounts with precision.",
  // },
  {
    id: 3,
    title: "Autopilot",
    Icon: WorkflowIcon,
    url: "/autopilot",
    menu_section: "Autopilot",
    description:
      "Automate workflows with customizable sequences and integrations.",
  },
  {
    id: 3,
    title: "Lead Capture",
    Icon: LeadCaptureIcon,
    url: "/lead-capture",
    menu_section: "Autopilot",
    description:
      "Create forms and lead capture funnels to automate follow-ups.",
    dev_mode: true,
  },
  {
    id: 3,
    title: "Funnels",
    Icon: FormsIcon,
    url: "/funnels",
    menu_section: "Autopilot",
    description: "Design and optimize funnels for streamlined lead conversion.",
  },
  {
    id: 6,
    title: "Broadcasts",
    Icon: BlastsIcon,
    url: "/broadcasts",
    menu_section: "Autopilot",
    description:
      "Create and send email blasts to engage your audience at scale.",
  },

  {
    id: 6,
    title: "Billing & Revenue",
    Icon: InvoiceIcon,
    url: "/invoicing",
    menu_section: "banking",
    dev_mode: true,
  },
  {
    id: 7,
    title: "Documents",
    Icon: DocumentsIcon,
    url: "/documents",
    menu_section: "banking",
    dev_mode: true,
  },
  // {
  //   id: 7,
  //   title: "Support Tickets",
  //   Icon: TicketsIcon,
  //   url: "/tickets",
  //   menu_section: "Client",
  //   dev_mode: true,
  // },

  {
    id: 6,
    title: "Social Media",
    Icon: SocialMediaIcon,
    url: "/social-media",
    menu_section: "Outbound",
    dev_mode: true,
  },
  {
    id: 8,
    title: "Projects",
    Icon: ProjectsIcon,
    url: "/projects",
    menu_section: "Collaboration",
    // dev_mode: true,
  },
  {
    id: 8,
    title: "Tasks",
    Icon: TasksIcon,
    url: "/tasks",
    menu_section: "Collaboration",
    description:
      "Track action items and reminders to stay organized and productive.",
  },
  {
    id: 8,
    title: "Whiteboards",
    Icon: NotesIcon,
    url: "/whiteboards",
    menu_section: "Collaboration",
    description:
      "Brainstorm and visualize workflows with an interactive whiteboard tool.",
  },
  {
    id: 11,
    title: "Connectors",
    Icon: IntegrationsIcon,
    url: "/integrations",
    menu_section: "Integrations",
    description:
      "Link your tools and apps for seamless integrations and automation.",
  },
];
// {
//   id: 8,
//   title: "Client Portals",
//   Icon: ClientPortal,
//   url: "/whiteboards",
//   menu_section: "Client",
// },

export default main_menu;

// {
//   id: 3,
//   title: "Campaigns",
//   Icon: CampaignIcon,
//   url: "/outreach",
//   menu_section: "Autopilot",
// },
// {
//   id: 3,
//   title: "Forms",
//   Icon: FormsIcon,
//   url: "/funnels",
//   menu_section: "Outbound",
// },
// {
//   id: 7,
//   title: "Awaiting Approval",
//   Icon: Approval,
//   url: "/awaiting-approval",
//   menu_section: "Approval",
// },
// {
//   id: 6,
//   title: "Templates",
//   Icon: TemplateIcon,
//   url: "/templates",
//   menu_section: "Outbound",
// },

// {
//   id: 7,
//   title: "Customer Support",
//   Icon: BlueprintIcon,
//   menu_section: "Support",
//   sub_menu: [
//     {
//       id: 3,
//       title: "Chatbot Builder",
//       Icon: WorkflowIcon,
//       url: "/blueprints",
//     },

//     {
//       id: 3,
//       title: "Tickets",
//       Icon: CampaignIcon,
//       url: "/outreach",
//     },
//   ],
// },

// {
//   id: 3,
//   title: "Chatbot Builder",
//   Icon: CustomerSupportIcon,
//   iconKey: "stroke",
//   url: "/chatbot-builder",
//   isSelf: true,
//   menu_section: "Operations",
// },

// {
//   id: 9,
//   title: "Auto Dialer",
//   Icon: PhoneIcon,
//   iconKey: "stroke",
//   url: "/inbox",
//   isSelf: true,
// },
