import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.css";
import Table from "./Table/Table";

import Toolbar from "../../Common/Toolbar/Toolbar";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";

import RecordsSidebar from "./RecordsSidebar";
// import SavedLists from "../Lists/SavedLists";

export default function Records() {
  const navigate = useNavigate();

  return (
    <div className={styles.main_layout}>
      <RecordsSidebar />
      <div className={styles.records_layout}>
        <Toolbar feature="Record" />
        <div className={styles.page_layout}>
          <div className={styles.table_layout}>
            <Table
              columns={columns}
              data={data}
              statusColorMap={statusColorMap}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const columns = [
  { name: "Name", uid: "name" },
  { name: "Company", uid: "company" },
  { name: "Position", uid: "position" },
  { name: "Email", uid: "email" },
  { name: "Phone", uid: "phone" },
];

const data = [
  {
    id: 1,
    name: "John Doe",
    company: "Tech Solutions Inc.",
    position: "Software Engineer",
    email: "johndoe@techsolutions.com",
    phone: "+1 (555) 123-4567",
  },
  {
    id: 2,
    name: "Jane Smith",
    company: "Global Enterprises",
    position: "Project Manager",
    email: "janesmith@globalenterprises.com",
    phone: "+1 (555) 234-5678",
  },
  {
    id: 3,
    name: "Robert Brown",
    company: "FinTech Corp",
    position: "Data Analyst",
    email: "robertbrown@fintechcorp.com",
    phone: "+1 (555) 345-6789",
  },
  {
    id: 4,
    name: "Emily Johnson",
    company: "Health Solutions Ltd.",
    position: "Marketing Specialist",
    email: "emilyjohnson@healthsolutions.com",
    phone: "+1 (555) 456-7890",
  },
  {
    id: 5,
    name: "Michael Lee",
    company: "InnovateX",
    position: "Product Designer",
    email: "michaellee@innovatex.com",
    phone: "+1 (555) 567-8901",
  },
  {
    id: 6,
    name: "Sarah Williams",
    company: "Green Energy Co.",
    position: "Business Analyst",
    email: "sarahwilliams@greenenergy.com",
    phone: "+1 (555) 678-9012",
  },
];

const statusColorMap = {
  Pending: {
    background: "rgba(204, 153, 0, 0.1)", // Dark yellow background at 0.1 opacity
    text: "rgba(204, 153, 0, 1)", // Dark yellow text at full opacity
  },
  Signed: {
    background: "rgba(0, 77, 36, 0.1)", // Dark green background at 0.1 opacity
    text: "rgba(0, 77, 36, 1)", // Dark green text at full opacity
  },
  Expired: {
    background: "rgba(200, 0, 0, 0.1)", // Red background at 0.1 opacity
    text: "rgba(200, 0, 0, 1)", // Red text at full opacity
  },
};
