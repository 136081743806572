import React from "react";
import styles from "./styles.module.css";

import SearchIcon from "../../../assets/SVG/SearchIcon";
import FilterIcon from "../../../assets/SVG/FilterIcon";
import CreateIcon from "../../../assets/SVG/CreateIcon";
import Button from "../Button/Button";

import { getCSSVariable } from "../../../utils/getCSSVariable";

export default function ToolbarExposed({ feature, createFunction }) {
  const placeholderText = "Quick Search " + feature + "s";
  return (
    <div className={styles.navbar}>
      {/* <p className={styles.list_title}>{activeTab} Campaigns</p> */}
      <div className={styles.right_actions}>
        <div className={styles.search_container}>
          <SearchIcon color={getCSSVariable("--icon-color")} />
          <input
            className={styles.search_container_input}
            placeholder={placeholderText}
          ></input>
        </div>
        <Button
          text="Filter"
          // onClick={createFunction}
          icon={<FilterIcon color="rgba(0, 0, 0, 1)" width={15} height={15} />}
          variant="bordered"
        />
        {/* <div className={styles.refresh}>
          <FilterIcon
            width={15}
            height={15}
            color={getCSSVariable("--icon-color")}
          />
          Filter
        </div> */}
        {/* <div className={styles.refresh}>
          <RefreshIcon color={getCSSVariable("--icon-color")} />
          Refresh
        </div> */}
      </div>
      <div className={styles.right_actions}>
        <Button
          text={`Create ${feature}`}
          action={createFunction}
          icon={
            <CreateIcon color="rgba(255, 255, 255, 1)" width={15} height={15} />
          }
          variant="filled"
        />
      </div>
    </div>
  );
}
