import React, { useState } from "react";
import { useNavigate } from "react-router";
import styles from "./styles.module.css";
import Table from "./Table/Table";

import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";
import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";
import SliderHeaderMenu from "../../Common/SliderHeaderMenu/SliderHeaderMenu";
import { useAccountContext } from "../../../context/AccountContext";
import { useContactsContext } from "../../../context/ContactsContext";

export default function Contacts() {
  const { profile, profileLoading, profileError } = useAccountContext();
  const { contacts, contactsLoading, contactsError } = useContactsContext();
  const navigate = useNavigate();

  const quickstartPlaceholder =
    "Example: Create a new Segment of Women in STEM";

  const menuItems = ["All Contacts", "Companies", "Segments", "Pipelines"];
  const [activeTab, setActiveTab] = useState(menuItems[0]);

  // Handle loading and error states
  if (contactsLoading) {
    return <div>Loading...</div>;
  }
  const columns = profile?.organization?.all_contacts_view?.columns || [];

  return (
    <div className={styles.main_layout}>
      <div className={styles.records_layout}>
        <QuickstartBar quickstartPlaceholder={quickstartPlaceholder} />
        {/* <SliderHeaderMenu
          menuItems={menuItems}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        /> */}
        <ToolbarExposed feature="Contact" />
        <div className={styles.page_layout}>
          <Table columns={columns} data={contacts} />
        </div>
      </div>
    </div>
  );
}
