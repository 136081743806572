import React from "react";
import styles from "./styles.module.css";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";
import AccountTag from "../../Common/AccountTag/AccountTag";
import contacts_data from "../../../data/contacts_data";

export default function Pipelines() {
  const dealPipeline = [
    "New Leads",
    "Qualified Leads",
    "Proposal Sent",
    "Negotiation",
    "Closed - Won",
    "Closed - Lost",
    "Follow-Up",
  ];

  return (
    <div>
      <ToolbarExposed feature="Deal" />
      <div className={styles.page_layout}>
        <div className={styles.board_layout}>
          {dealPipeline.map((stage, index) => (
            <Board
              key={index}
              stage={stage}
              contacts={contacts_data.filter(
                (contact) => contact.pipeline_stage === stage
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function Board({ stage, contacts }) {
  return (
    <div className={styles.board}>
      <div className={styles.board_header}>
        <div className={styles.board_title}>{stage}</div>
      </div>
      <div className={styles.board_body}>
        {contacts.length > 0 ? (
          contacts.map((contact, index) => (
            <BoardTile key={index} contact={contact} />
          ))
        ) : (
          <p className={styles.no_contacts}>No contacts in this stage</p>
        )}
      </div>
      <div className={styles.board_footer}>
        <AccountTag />
        <p className={styles.last_updated}>Updated Today</p>
      </div>
    </div>
  );
}

function BoardTile({ contact }) {
  return (
    <div className={styles.board_tile}>
      {/* <div className={styles.contact_info}>
        <img
          src={contact.contact_image}
          alt={contact.name}
          className={styles.contact_image}
        />
        <div className={styles.contact_details}>
          <p className={styles.contact_name}>{contact.name}</p>
          <p className={styles.contact_role}>{contact.role}</p>
          <p className={styles.contact_company}>{contact.company}</p>
        </div>
      </div> */}
    </div>
  );
}
