import React from "react";

const ImportIcon = ({
  width = 20,
  height = 20,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_3199068"
    enable-background="new 0 0 24 24"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m17.5 24c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-12c-3.032 0-5.5 2.468-5.5 5.5s2.468 5.5 5.5 5.5 5.5-2.468 5.5-5.5-2.468-5.5-5.5-5.5z"></path>
    <path d="m17.5 21c-.128 0-.256-.049-.354-.146l-3-3c-.195-.195-.195-.512 0-.707s.512-.195.707 0l2.646 2.646 2.646-2.646c.195-.195.512-.195.707 0s.195.512 0 .707l-3 3c-.096.097-.224.146-.352.146z"></path>
    <path d="m17.5 20.75c-.276 0-.5-.224-.5-.5v-5.75c0-.276.224-.5.5-.5s.5.224.5.5v5.75c0 .276-.224.5-.5.5z"></path>
    <path d="m10 8c-.059 0-.117-.011-.173-.031l-9.5-3.5c-.196-.073-.327-.259-.327-.469s.131-.396.327-.469l9.5-3.5c.111-.041.234-.041.346 0l9.5 3.5c.196.073.327.259.327.469s-.131.396-.327.469l-9.5 3.5c-.056.02-.114.031-.173.031zm-8.054-4 8.054 2.967 8.054-2.967-8.054-2.967z"></path>
    <path d="m8.62 19.49c-.058 0-.116-.01-.173-.031l-8.12-2.99c-.196-.073-.327-.259-.327-.469v-12c0-.276.224-.5.5-.5s.5.224.5.5v11.651l7.793 2.87c.259.095.392.383.296.642-.074.202-.266.327-.469.327z"></path>
    <path d="m19.5 9c-.276 0-.5-.224-.5-.5v-4.5c0-.276.224-.5.5-.5s.5.224.5.5v4.5c0 .276-.224.5-.5.5z"></path>
    <path d="m10 13.03c-.276 0-.5-.224-.5-.5v-5.03c0-.276.224-.5.5-.5s.5.224.5.5v5.03c0 .277-.224.5-.5.5z"></path>
  </svg>
);

export default ImportIcon;
