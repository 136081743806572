import React, { useState, useRef, useEffect } from "react";
import styles from "./slider_menu.module.css";

export default function SliderHeaderMenu({
  menuItems,
  activeTab,
  setActiveTab,
}) {
  const [sliderStyle, setSliderStyle] = useState({});
  const menuRef = useRef([]);

  useEffect(() => {
    const activeIndex = menuItems.findIndex(
      (item) => item.label === activeTab.label
    );
    const activeItem = menuRef.current[activeIndex];
    if (activeItem) {
      const { offsetWidth, offsetLeft } = activeItem;
      setSliderStyle({
        width: `${offsetWidth}px`,
        transform: `translateX(${offsetLeft}px)`,
      });
    }
  }, [activeTab, menuItems]);

  return (
    <div className={styles.navbar}>
      <div className={styles.menu}>
        {menuItems.map((item, index) => (
          <div
            key={item.label}
            ref={(el) => (menuRef.current[index] = el)}
            className={`${styles.menuItem} ${
              activeTab.label === item.label ? styles.active : ""
            }`}
            onClick={() => setActiveTab(item)}
          >
            <span className={styles.icon}>{item.icon}</span>
            <span className={styles.label}>{item.label}</span>
          </div>
        ))}
        <div className={styles.slider} style={sliderStyle} />
      </div>
    </div>
  );
}
