import React from "react";

const WorkflowIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 25"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    id="fi_17689079"
  >
    <g clipRule="evenodd" fill="rgb(0,0,0)" fillRule="evenodd">
      <path
        fill={color}
        d="m6 3.0271c-1.24264 0-2.25 1.00736-2.25 2.25s1.00736 2.25 2.25 2.25 2.25-1.00736 2.25-2.25-1.00736-2.25-2.25-2.25zm-3.75 2.25c0-2.07107 1.67893-3.75 3.75-3.75s3.75 1.67893 3.75 3.75-1.67893 3.75-3.75 3.75-3.75-1.67893-3.75-3.75z"
      ></path>
      <path
        fill={color}
        d="m18 3.0271c-1.2426 0-2.25 1.00736-2.25 2.25s1.0074 2.25 2.25 2.25 2.25-1.00736 2.25-2.25-1.0074-2.25-2.25-2.25zm-3.75 2.25c0-2.07107 1.6789-3.75 3.75-3.75s3.75 1.67893 3.75 3.75-1.6789 3.75-3.75 3.75-3.75-1.67893-3.75-3.75z"
      ></path>
      <path
        fill={color}
        d="m6 17.0271c-1.24264 0-2.25 1.0074-2.25 2.25s1.00736 2.25 2.25 2.25 2.25-1.0074 2.25-2.25-1.00736-2.25-2.25-2.25zm-3.75 2.25c0-2.0711 1.67893-3.75 3.75-3.75s3.75 1.6789 3.75 3.75-1.67893 3.75-3.75 3.75-3.75-1.6789-3.75-3.75z"
      ></path>
      <path
        fill={color}
        d="m6 17.0271c.41421 0 .75-.3358.75-.75v-8c0-.41421-.33579-.75-.75-.75s-.75.33579-.75.75v8c0 .4142.33579.75.75.75z"
      ></path>
      <path
        fill={color}
        d="m18 7.5271c.4142 0 .75.33579.75.75v.03144c0 .67337 0 1.22407-.0366 1.67163-.0378.46323-.1185.88163-.3176 1.27243-.3116.6115-.8088 1.1087-1.4203 1.4203-.3908.1991-.8092.2798-1.2724.3176-.4476.0366-.9983.0366-1.6717.0366h-8.0314c-.41421 0-.75-.3358-.75-.75s.33579-.75.75-.75h8c.7124 0 1.2017-.0006 1.5809-.0316.3705-.0302.5695-.0857.7136-.1591.3293-.1678.597-.4355.7648-.7648.0734-.1441.1289-.3431.1591-.71358.031-.37925.0316-.86848.0316-1.58092 0-.41421.3358-.75.75-.75z"
      ></path>
    </g>
  </svg>
);

export default WorkflowIcon;
