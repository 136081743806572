import React from "react";
import styles from "./admin_frames.module.css";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ConfirmDeletePopup } from "../../Modals/PopUpComponents/DeletePopup/ConfirmDeletePopup";
import AddIcon from "../../../assets/SVG/AddIcon";

import BackIcon from "../../../assets/SVG/BackIcon";

import { AdminContext } from "../../../context/AdminContext";

import CancelIcon from "../../../assets/SVG/CancelIcon";

export default function AdminManageFrame(props) {
  const [selectedFrame, setSelectedFrame] = useState(
    props.selectedTrainingData
  );

  const [deletePopup, setDeletePopup] = useState(false);

  const [newPhrase, setNewPhrase] = useState(false);
  const [addingPhrase, setAddingPhrase] = useState(false);

  const [addingEntity, setAddingEntity] = useState(false);

  const [editingPrompt, setEditingPrompt] = useState(false);
  const [promptText, setPromptText] = useState(selectedFrame.prompt);

  const [newPhraseText, setNewPhraseText] = useState("");
  const [newEntityText, setNewEntityText] = useState("");

  const {
    editFramePrompt,
    addTrainingPhrase,
    deleteTrainingPhrase,
    addEntity,
    removeEntity,
  } = useContext(AdminContext);

  const handleAddPhrase = async () => {
    const response = await addTrainingPhrase(selectedFrame.id, newPhraseText);
    if (response.success) {
      setSelectedFrame((prevFrame) => ({
        ...prevFrame,
        phrases: [
          ...prevFrame.phrases,
          { id: response.newPhraseId, phrase: newPhraseText },
        ],
      }));
      setNewPhraseText(""); // Clear the input field
      toast.success("New phrase added!");
    } else {
      toast.error("Failed to add phrase.");
    }
  };

  const handleDeletePhrase = async (phraseId) => {
    const response = await deleteTrainingPhrase(selectedFrame.id, phraseId);
    if (response.success) {
      setSelectedFrame((prevFrame) => ({
        ...prevFrame,
        phrases: prevFrame.phrases.filter((phrase) => phrase.id !== phraseId),
      }));
      toast.success("Phrase deleted successfully.");
    } else {
      toast.error("Failed to delete phrase.");
    }
  };

  function handleEditPrompt() {
    setEditingPrompt(true);
  }

  async function handleSavePrompt() {
    setEditingPrompt(false);
    const response = await editFramePrompt(selectedFrame.id, promptText);

    if (response.success) {
      toast.success("Prompt updated successfully.");
    } else {
      toast.error("Failed to update prompt.");
    }
  }
  function handleNewEntity() {
    setAddingEntity(true);
  }

  function cancelNewEntity() {
    setAddingEntity(false);
  }

  const handleAddEntity = async () => {
    const response = await addEntity(selectedFrame.id, newEntityText);
    if (response.success) {
      setSelectedFrame((prevFrame) => ({
        ...prevFrame,
        entities: [...prevFrame.entities, newEntityText],
      }));
      setNewEntityText("");
      setAddingEntity(false);
      toast.success("Entity added successfully!");
    } else {
      toast.error("Failed to add entity.");
    }
  };

  const handleRemoveEntity = async (entity) => {
    const response = await removeEntity(selectedFrame.id, entity);
    if (response.success) {
      setSelectedFrame((prevFrame) => ({
        ...prevFrame,
        entities: prevFrame.entities.filter((e) => e !== entity),
      }));
      toast.success("Entity removed successfully!");
    } else {
      toast.error("Failed to remove entity.");
    }
  };

  return (
    <div className={styles.frame_layout}>
      <div className={styles.header_section}>
        <div className={styles.title_area_create}>
          <div className={styles.back_button_small} onClick={props.back}>
            <BackIcon />
          </div>
          <p className={styles.page_title}>
            Edit{" "}
            <span className={styles.page_title_blue}>{selectedFrame.name}</span>
          </p>
        </div>
        <div className={styles.page_controls_top}>
          <div
            className={styles.download_json_button}
            // onClick={replaceTrainingDataJson}
          >
            Delete Intent
          </div>

          <div className={styles.new_line_button}>Sync to Pinecone</div>
        </div>
      </div>
      <div className={styles.section_divider_no_margin}></div>
      <div className={styles.frame_layout_content}>
        <div className={styles.frame_side}>
          {/* <div className={styles.section_divider_no_margin}></div> */}
          <div className={styles.frame_information}>
            {editingPrompt ? (
              <div className={styles.prompt_information}>
                <div className={styles.prompt_top}>
                  <p className={styles.prompt_title}>Prompt</p>
                  <p className={styles.prompt_edit} onClick={handleSavePrompt}>
                    Save
                  </p>
                </div>
                <textarea
                  className={styles.prompt_input_textarea}
                  value={promptText}
                  onChange={(e) => setPromptText(e.target.value)}
                />
              </div>
            ) : (
              <div className={styles.prompt_information}>
                <div className={styles.prompt_top}>
                  <p className={styles.prompt_title}>Prompt</p>

                  <p className={styles.prompt_edit} onClick={handleEditPrompt}>
                    Edit
                  </p>
                </div>

                <p className={styles.prompt_input}>{promptText}</p>
              </div>
            )}
          </div>
          <div className={styles.section_divider_margin}></div>
          {/* <div className={styles.section_divider_no_margin}></div> */}
          <div className={styles.frame_information}>
            <div className={styles.prompt_information}>
              <p className={styles.prompt_title}>Entities</p>
              <div className={styles.entity_list}>
                {selectedFrame.entities.map((entity) => (
                  <div className={styles.active_entity} key={entity}>
                    {entity}
                    <div
                      className={styles.cancel_icon}
                      onClick={() => handleRemoveEntity(entity)}
                    >
                      <CancelIcon
                        color="rgba(0, 0, 0, 0.9)"
                        width={17}
                        height={17}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {addingEntity ? (
                <>
                  <div className={styles.new_entity_layout}>
                    <input
                      placeholder="New Entity"
                      className={styles.entity_input}
                      value={newEntityText}
                      onChange={(e) => setNewEntityText(e.target.value)}
                    />
                    <div
                      className={styles.add_new_entity}
                      onClick={handleAddEntity}
                    >
                      <AddIcon
                        color="rgba(0, 0, 0, 0.75)"
                        width={20}
                        height={20}
                      />
                    </div>
                  </div>
                  <p
                    className={styles.cancel_text}
                    onClick={() => setAddingEntity(false)}
                  >
                    Cancel
                  </p>
                </>
              ) : (
                <div
                  className={styles.new_list_button}
                  onClick={handleNewEntity}
                >
                  <div className={styles.new_list_icon}>
                    <AddIcon color="rgba(0, 0, 0, 1)" width={14} height={14} />
                  </div>
                  <p className={styles.new_list_text}>Add New Entity</p>
                </div>
              )}
            </div>
          </div>
          <div className={styles.section_divider_margin}></div>
        </div>
        <div className={styles.frame_side_shadow}>
          <div className={styles.frame_information}>
            {true && (
              <>
                <p className={styles.prompt_title}>Training Phrases</p>
                <>
                  <div className={styles.new_phrase_tile}>
                    <input
                      placeholder="Add a new phrase"
                      className={styles.input_phrase}
                      value={newPhraseText}
                      onChange={(e) => setNewPhraseText(e.target.value)}
                    />
                    <AddIcon
                      color="rgba(0, 0, 0, 1)"
                      onClick={handleAddPhrase}
                    />
                  </div>
                  <div className={styles.section_divider_no_margin}></div>
                </>
                <div className={styles.phrases_scroll}>
                  {selectedFrame?.phrases?.map((item) => (
                    <div className={styles.phrase_tile} key={item.id}>
                      {item.phrase}
                      <TrashIcon onClick={() => handleDeletePhrase(item.id)} />
                    </div>
                  ))}
                  <div className={styles.buffer}></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ConfirmDeletePopup
        openModal={deletePopup}
        closeModal={() => setDeletePopup(false)}
        // deleteFtn={(event) => {
        //   deleteTrainingDataPoint(event);
        // }}
        desc={"Are you sure you want to delete?"}
      />
    </div>
  );
}

function TrashIcon() {
  return (
    <svg
      enable-background="new 0 0 512 512"
      height="14px"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 512 512"
      width="14px"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g fill="rgba(255, 0, 0, 0.75)"> */}
      <g fill="rgba(0, 0, 0, 0.5)" className={styles.trash_icon_fill}>
        <path d="M444.852,66.908h-99.339V47.04c0-21.943-17.792-39.736-39.736-39.736h-99.339 c-21.944,0-39.736,17.793-39.736,39.736v19.868H67.363v19.868h20.47l19.887,377.489c0,21.944,17.792,39.736,39.736,39.736h218.546 c21.944,0,39.736-17.792,39.736-39.736l19.538-377.489h19.577V66.908z M186.57,47.04c0-10.962,8.926-19.868,19.868-19.868h99.339 c10.962,0,19.868,8.906,19.868,19.868v19.868H186.57V47.04z M385.908,463.236l-0.039,0.505v0.524 c0,10.943-8.906,19.868-19.868,19.868H147.455c-10.942,0-19.868-8.925-19.868-19.868v-0.524l-0.019-0.523L107.72,86.776h297.669 L385.908,463.236z" />
        <rect height="317.885" width="19.868" x="246.173" y="126.511" />
        <polygon points="206.884,443.757 186.551,126.493 166.722,127.753 187.056,445.017" />
        <polygon points="345.649,127.132 325.82,125.891 305.777,443.776 325.606,445.017" />
      </g>
    </svg>
  );
}
