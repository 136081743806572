import React, { useState, useContext } from "react";
import styles from "./styles.module.css";
import { EmailContext } from "../../../context/EmailContext";
import AiButton from "../../Common/AiButton/AiButton";
import ConfigurationIcon from "../../../assets/SVG/ConfigurationIcon";

import { getIconByName } from "../../../helpers/getIcon";
import UserIcon from "../../../assets/SVG/UserIcon copy";
import AddIcon from "../../../assets/SVG/AddIcon";

import SearchIcon from "assets/SVG/SearchIcon";

export default function InboxConversations({
  inbox,
  setSelectedTrail,
  selectedTrail,
  searchQuery,
  setSearchQuery,
  openPopup,
  loadingTrail,
}) {
  return (
    <div className={styles.sidebar}>
      <div className={styles.search_container}>
        {/* <SearchIcon /> */}
        <input
          className={styles.search_input}
          value={searchQuery}
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search"
        />
        <div className={styles.create_email} onClick={openPopup}>
          <AddIcon color="rgba(0, 0, 0, 0.65)" />
        </div>
      </div>

      <div className={styles.loading_line_background}>
        {loadingTrail && <div className={styles.loading_line}></div>}
      </div>
      {inbox?.length && inbox?.length !== 0 ? (
        <div className={styles.chat_list}>
          {Array.isArray(inbox) &&
            inbox?.map((trail) => {
              const conversationInformation =
                trail.account_type === "EMAIL"
                  ? {
                      name: trail.latestDraftOrMessage.from[0].name,
                      text: trail.snippet,
                    }
                  : { name: trail.name, text: trail.snippet };

              return (
                <React.Fragment key={trail.id}>
                  {trail.account_type === "EMAIL" ? (
                    <EmailThread
                      trail={trail}
                      setSelectedTrail={setSelectedTrail}
                      selectedTrail={selectedTrail}
                      conversationInformation={conversationInformation}
                    />
                  ) : (
                    <MessageThread
                      trail={trail}
                      setSelectedTrail={setSelectedTrail}
                      selectedTrail={selectedTrail}
                      conversationInformation={conversationInformation}
                    />
                  )}
                </React.Fragment>
              );
            })}
        </div>
      ) : (
        <div className={styles.empty}>
          <img className={styles.empty_image} src="/emptyInbox.png"></img>
          <p className={styles.empty_text}>No Threads</p>
        </div>
      )}
    </div>
  );
}

function EmailThread({
  trail,
  setSelectedTrail,
  selectedTrail,
  conversationInformation,
}) {
  return (
    <div className={styles.chat_card_box}>
      {/* {!trail.threadInfo.read && (
        <div className={styles.unread_indicator}></div>
      )} */}
      <div
        onClick={() => setSelectedTrail(trail)}
        className={
          selectedTrail?.id === trail.id
            ? styles.selected_conversation_trail
            : styles.conversation_trail
        }
      >
        <div
          className={
            trail.type === "Gmail"
              ? styles.trail_channel_content
              : styles.message_channel_content
          }
        >
          <div className={styles.trail_from}>
            {/* <div className={styles.user_image_bg}>
              {trail.image_url ? (
                <img
                  className={styles.user_image}
                  src={trail.image_url}
                  alt="Contact"
                ></img>
              ) : (
                <UserIcon color="rgba(0, 88, 220)" />
              )}
            </div> */}
            <div className={styles.trail_contact_information}>
              <p className={styles.trail_contact_name}>
                {conversationInformation.name}
              </p>
              {/* <p className={styles.trail_contact_title}>
                {trail.role + " at " + trail.company}
              </p> */}
            </div>
          </div>
          {/* {trail.type === "Gmail" && (
            <p className={styles.trail_subject}>
              {trail.subject}
            </p>
          )} */}
          <p className={styles.trail_subject}>{trail.subject}</p>
          <p className={styles.trail_preview}>{trail.snippet}</p>
        </div>
      </div>
      <div className={styles.channel_icon}>
        {/* <DisplayIcon name={trail.account_type} /> */}
        {/* <img
          className={styles.channel_icon_image}
          src="/images/IntegrationLogos/GmailLogo.png"
        /> */}
      </div>
      <p className={styles.channel_timestamp}>{timeAgo(trail.timestamp)}</p>
    </div>
  );
}

function MessageThread({
  trail,
  setSelectedTrail,
  selectedTrail,
  conversationInformation,
}) {
  return (
    <div className={styles.chat_card_box}>
      {/* {!trail.threadInfo.read && (
      <div className={styles.unread_indicator}></div>
    )} */}
      <div
        onClick={() => setSelectedTrail(trail)}
        className={
          selectedTrail?.id === trail.id
            ? styles.selected_conversation_trail
            : styles.conversation_trail
        }
      >
        <div
          className={
            trail.type === "Gmail"
              ? styles.trail_channel_content
              : styles.message_channel_content
          }
        >
          <div className={styles.trail_from}>
            {/* <div className={styles.user_image_bg}>
            {trail.image_url ? (
              <img
                className={styles.user_image}
                src={trail.image_url}
                alt="Contact"
              ></img>
            ) : (
              <UserIcon color="rgba(0, 88, 220)" />
            )}
          </div> */}
            <div className={styles.trail_contact_information}>
              <p className={styles.trail_contact_name}>
                {conversationInformation.name}
              </p>
              {/* <p className={styles.trail_contact_title}>
              {trail.role + " at " + trail.company}
            </p> */}
            </div>
          </div>

          <p className={styles.trail_preview}>{conversationInformation.text}</p>
        </div>
      </div>
      <div className={styles.channel_icon}>
        <img
          className={styles.channel_icon_image}
          src="/images/IntegrationLogos/SequenceLogos/InstagamLogo.webp"
        />
      </div>
      <p className={styles.channel_timestamp}>{timeAgo(trail.timestamp)}</p>
    </div>
  );
}

function timeAgo(unixTimestamp) {
  const now = Date.now(); // Current time in milliseconds
  const timestampInMs = unixTimestamp * 1000; // Convert Unix timestamp to milliseconds
  const diffInMs = now - timestampInMs; // Difference in milliseconds

  const minutes = Math.floor(diffInMs / (1000 * 60));
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  const months = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30));

  if (minutes < 60) {
    return `${minutes}m${minutes !== 1 ? "" : ""} ago`;
  } else if (hours < 24) {
    return `${hours}h${hours !== 1 ? "s" : ""} ago`;
  } else if (days < 30) {
    return `${days}D${days !== 1 ? "" : ""} ago`;
  } else {
    return `${months}M${months !== 1 ? "" : ""} ago`;
  }
}

const action_options = [
  {
    id: "0",
    type: "Email",
    title: "Update CRM",
    image: "/images/IntegrationLogos/SequenceLogos/HubSpotLogo.jpeg",
  },
  {
    id: "0",
    type: "Email",
    title: "Update CRM",
    image: "/images/IntegrationLogos/SequenceLogos/SalesForceLogo.webp",
  },
  {
    id: "0",
    type: "Slack",
    title: "Send Slack Message",
    image: "/images/IntegrationLogos/SequenceLogos/SlackLogo.png",
  },
  {
    id: "0",
    type: "Zapier",
    title: "Connect Zapier Hook",
    image: "/images/IntegrationLogos/SequenceLogos/ZapierLogo.png",
  },
  {
    id: "0",
    type: "LinkedIn",
    title: "Send Connection Request",
    image: "/images/IntegrationLogos/SequenceLogos/LinkedinLogo.png",
  },
  {
    id: "0",
    type: "LinkedIn",
    title: "Send Message",
    image: "/images/IntegrationLogos/SequenceLogos/LinkedinLogo.png",
  },
  {
    id: "0",
    type: "Instagram",
    title: "Send Message",
    image: "/images/IntegrationLogos/SequenceLogos/InstagamLogo.webp",
  },
];
