import React from "react";

const TemplateIcon = ({
  width = 14,
  height = 14,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_10210301"
    width={width}
    height={height}
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      style={{
        fill: "none",
        stroke: "black",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeWidth: 1.8,
      }}
    >
      <path d="m32.1 43.9-14.6 14.5-10-10 14.6-14.5z"></path>
      <path d="m63.4 12.7-19.5 19.4-10-10 19.5-19.4c1.3-1.3 3.3-1.3 4.6 0l5.4 5.4c1.3 1.3 1.3 3.3 0 4.6z"></path>
      <path d="m63.4 12.7-4.1 4.1-10-10 4.2-4.1c1.3-1.3 3.3-1.3 4.6 0l5.4 5.4c1.2 1.3 1.2 3.3-.1 4.6z"></path>
      <path d="m52.8 16.6-12.2 12.2"></path>
      <path d="m28.8 40.5-14.6 14.6"></path>
      <path d="m49.5 13.2-12.3 12.3"></path>
      <path d="m25.4 37.2-14.5 14.5"></path>
      <path d="m7.5 48.4-5.9 15.9 16-5.9z"></path>
      <path d="m4.2 57.3-2.6 7 7.1-2.6z"></path>
      <path d="m2.4 10.6 8.2-8.1c1-1 2.6-1 3.6 0l49.5 49.3c1 1 1 2.6 0 3.6l-8.2 8.1c-1 1-2.6 1-3.6 0l-49.5-49.3c-1-1-1-2.6 0-3.6z"></path>
      <path d="m61.8 49.9-2.7 2.6"></path>
      <path d="m58 46.2-4.3 4.3"></path>
      <path d="m54.3 42.4-2.7 2.6"></path>
      <path d="m50.5 38.7-4.3 4.3"></path>
      <path d="m46.7 34.9-2.6 2.7"></path>
      <path d="m43 31.2-4.3 4.3"></path>
      <path d="m39.2 27.4-2.6 2.7"></path>
      <path d="m35.4 23.7-4.3 4.3"></path>
      <path d="m31.7 20-2.6 2.6"></path>
      <path d="m27.9 16.2-4.3 4.3"></path>
      <path d="m24.2 12.5-2.7 2.6"></path>
      <path d="m20.4 8.7-4.3 4.3"></path>
      <path d="m16.6 5-2.6 2.6"></path>
      <path d="m49.2 6.8-3 3.1 10 10 3.1-3.1z"></path>
    </g>
  </svg>
);

export default TemplateIcon;
