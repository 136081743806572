import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./styles.module.css";
import AddIcon from "../../../assets/SVG/AddIcon";
import SearchIcon from "../../../assets/SVG/SearchIcon";
import ArrowIcon from "assets/SVG/GeneralIcons/ArrowIcon";

import WorkflowIcon from "assets/SVG/NewSidebarIcons/WorkflowIcon";

import HistoryIcon from "assets/SVG/GeneralIcons/HistoryIcon";
import SavedIcon from "assets/SVG/GeneralIcons/SavedIcon";
import ClearIcon from "assets/SVG/GeneralIcons/ClearIcon";

export default function GlobalDatabaseFilters() {
  return (
    <div className={styles.filter_sidebar}>
      <div className={styles.filter_sidebar_header}>
        <div className={styles.header_titles}>
          <p className={styles.page_title}>Global Database</p>
          <div className={styles.filter_buttons}>
            <div className={styles.filter_button}>
              <SavedIcon width={12} height={12} color="rgba(0, 0, 0, 0.8)" />
            </div>
            <div className={styles.filter_button}>
              <ClearIcon width={12} height={12} color="rgba(0, 0, 0, 0.8)" />
            </div>
            <div className={styles.filter_button}>
              <HistoryIcon width={12} height={12} color="rgba(0, 0, 0, 0.8)" />
            </div>
          </div>
        </div>
        {/* <div className={styles.filter_controls_layout}>
          <div className={styles.filter_controls}>
            <p className={styles.filter_control}>Clear filters</p>
            <p className={styles.filter_control}>Save filters</p>
          </div>
        </div> */}
      </div>
      {/* <div className={styles.header_titles}>
        <p className={styles.page_title}>Global Database</p>
      </div> */}

      {/* <div className={styles.search_layout}>
        <input
          className={styles.search_box}
          placeholder="Ex. CMO's of B2B companies in California"
        />
        <div className={styles.search_button}>
          <SearchIcon color="rgba(0, 0, 0, 1)" width={20} height={20} />
        </div>
      </div> */}

      <div className={styles.filter_list}>
        {filters.map((filter, index) => (
          <div className={styles.database_filter} key={index}>
            <p className={styles.database_filter_title}>{filter}</p>
            {/* <AddIcon color="rgba(0, 0, 0, 1)" width={10} height={10} /> */}
            <ArrowIcon width={8} height={8} />
          </div>
        ))}
      </div>
    </div>
  );
}

const filters = [
  "Job Title",
  "Current Company",
  "Past Company",
  "Company Size",
  "Revenue",
  "Industry",
  "Years of Experience",
  "Skills",
  "School",
  "Degree",
  "Language Spoken",
  "Location",
  "Contact Boolean Search",
];
