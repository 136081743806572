import React, { useState } from "react";
import { useNodesState, useEdgesState } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import styles from "./styles.module.css";

import SliderHeaderMenu from "../../../Common/SliderHeaderMenu/SliderHeaderMenu";
import EditorPlayground from "./EditorPlayground";
import EditorSidebar from "./EditorSidebar";
import SettingsIcon from "assets/SVG/SliderHeaderIcons/SettingsIcon";

function BlueprintEditor() {
  const menuItems = [
    {
      icon: <SettingsIcon width={12} height={12} />,
      label: "Editor",
    },
    {
      icon: <SettingsIcon width={12} height={12} />,
      label: "Activity",
    },
    {
      icon: <SettingsIcon width={12} height={12} />,
      label: "Analytics",
    },
    {
      icon: <SettingsIcon width={12} height={12} />,
      label: "Settings",
    },
    // {
    //   icon: <AnalyticsIcon width={12} height={12} />,
    //   label: "Analytics",
    // },
  ];

  const [activeTab, setActiveTab] = useState(menuItems[0]);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  const [view, setView] = useState("blueprintProperties");

  /**
   * Helper to create a node object with field names for better readability.
   */
  const createNode = ({ id, type, title, subtitle, position }) => ({
    id,
    type,
    data: { type, title, subtitle },
    position,
  });

  /**
   * Helper to create an edge object.
   */
  const createEdge = (sourceId, targetId) => ({
    id: `edge-${sourceId}-${targetId}`,
    source: sourceId,
    target: targetId,
    type: "straight",
  });

  /**
   * Add a new node and optionally connect it to the last node.
   */
  const addNewNode = (trigger) => {
    const newNode = createNode({
      id: `node-${Date.now()}`,
      type: "custom",
      title: trigger.title,
      subtitle: "New Trigger",
      position:
        nodes.length > 0
          ? {
              x: nodes[nodes.length - 1].position.x,
              y: nodes[nodes.length - 1].position.y + 120,
            }
          : { x: 0, y: 200 },
    });

    setNodes((prevNodes) => [...prevNodes, newNode]);

    if (nodes.length > 0) {
      const lastNode = nodes[nodes.length - 1];
      setEdges((prevEdges) => [
        ...prevEdges,
        createEdge(lastNode.id, newNode.id),
      ]);
    }

    setTimeout(() => {
      setSelectedNode(newNode);
      setView("nodeProperties");
    }, 0);
  };

  /**
   * Update properties of a specific node by ID.
   */
  const updateNodeProperties = (nodeId, newProperties) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, ...newProperties } }
          : node
      )
    );
  };

  const handleTriggerSelection = () => {
    setView("selectTrigger");
  };

  const handleNodeSelection = (node) => {
    setSelectedNode(node);
    setView("nodeProperties");
  };

  return (
    <div className={styles.main_layout}>
      <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={styles.page_layout}>
        <EditorPlayground
          nodes={nodes}
          setNodes={setNodes}
          selectedNode={selectedNode}
          edges={edges}
          setEdges={setEdges}
          onNodeClick={handleNodeSelection}
          handleTriggerSelection={handleTriggerSelection}
          handleNewNodeSelect={addNewNode}
        />
        <EditorSidebar
          selectedNode={selectedNode}
          setNodes={setNodes}
          setEdges={setEdges}
          setSelectedNode={setSelectedNode}
          view={view}
          setView={setView}
          handleNewNodeSelect={addNewNode}
          updateNodeProperties={updateNodeProperties}
        />
      </div>
    </div>
  );
}

export default BlueprintEditor;
