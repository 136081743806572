import React from "react";
import { Handle, Position } from "@xyflow/react";
import styles from "./node.module.css";

import TriggerIcon from "../../../../../assets/SVG/BlueprintIcons/TriggerIcon";
import AskArcherIcon from "../../../../../assets/SVG/NewSidebarIcons/AskArcherIcon";
import TemplatesIcon from "../../../../../assets/SVG/BlueprintIcons/TemplatesIcon";

export default function NewBlueprint({ data }) {
  return (
    <div className={styles.new_blueprint_node}>
      <div
        className={styles.add_triger_prompt}
        onClick={data.handleTriggerSelection}
      >
        <TriggerIcon width={16} height={16} />
        <p className={styles.new_blueprint_titles}>Select a Trigger</p>
      </div>
      <div className={styles.option_divider_layout}>
        <div className={styles.option_divider}></div>
        OR
        <div className={styles.option_divider}></div>
      </div>
      <div
        className={styles.add_triger_prompt}
        onClick={data.handleTriggerSelection}
      >
        <AskArcherIcon width={16} height={16} />
        <p className={styles.new_blueprint_titles}>Ask Archer</p>
      </div>
      {/* <div className={styles.new_blueprint_options}>
        <div className={styles.blueprint_option}>
          <AskArcherIcon width={14} height={14} />
          <p className={styles.new_blueprint_titles}>Ask Archer</p>
        </div>
        <div className={styles.blueprint_option}>
          <TemplatesIcon width={14} height={14} />
          <p className={styles.new_blueprint_titles}>Templates</p>
        </div>
      </div> */}
    </div>
  );
}
