import React from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import Toolbar from "../../Common/Toolbar/Toolbar";
import More from "../../Common/More/More";
import AccountTag from "../../Common/AccountTag/AccountTag";
import ToolbarExposed from "../../Common/ToolbarExposed/ToolbarExposed";
import QuickstartBar from "../../Common/QuickstartBar/QuickstartBar";
import CreateTemplate from "../../Modals/CreateTemplate/CreateTemplate";

import SliderHeaderMenu from "components/Common/SliderHeaderMenu/SliderHeaderMenu";

import CampaignIcon from "assets/SVG/NewSidebarIcons/CampaignIcon";
import BlueprintsIcon from "assets/SVG/SliderHeaderIcons/BlueprintsIcon";
import TemplateIcon from "assets/SVG/NewSidebarIcons/TemplateIcon";
import AnalyticsIcon from "assets/SVG/SliderHeaderIcons/AnalyticsIcon";

import IntegrationsIcon from "assets/SVG/NewSidebarIcons/IntegrationsIcon";
import CRMIcon from "assets/SVG/SliderHeaderIcons/CRMIcon";
import ProductivityIcon from "assets/SVG/SliderHeaderIcons/ProductivityIcon";
import ConversationsIcon from "assets/SVG/SliderHeaderIcons/ConversationsIcon";
import DeveloperToolsIcon from "assets/SVG/SliderHeaderIcons/DeveloperToolsIcon";

export default function Integrations() {
  const [isCreateTemplateOpen, setIsCreateTemplateOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const menuItems = [
    {
      icon: <IntegrationsIcon width={12} height={12} />,
      label: "All Connectors",
    },
    {
      icon: <CRMIcon width={12} height={12} />,
      label: "CRM",
    },
    {
      icon: <ProductivityIcon width={12} height={12} />,
      label: "Productivity",
    },
    {
      icon: <ConversationsIcon width={12} height={12} />,
      label: "Communication",
    },
    {
      icon: <DeveloperToolsIcon width={12} height={12} />,
      label: "Developer Tools",
    },
  ];
  const [activeTab, setActiveTab] = useState(menuItems[0]);

  const openCreateTemplateModal = (task = null) => {
    console.log(task);
    setSelectedTemplate(task);
    setIsCreateTemplateOpen(true);
  };

  const closeCreateTemplateModal = () => {
    setIsCreateTemplateOpen(false);
    setSelectedTemplate(null);
  };

  return (
    <div className={styles.main_layout}>
      <CreateTemplate
        isOpen={isCreateTemplateOpen}
        onClose={closeCreateTemplateModal}
        task={selectedTemplate}
      />
      <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {/* <ToolbarExposed
        feature="Connector"
        createFunction={() => openCreateTemplateModal()}
      /> */}
      <div className={styles.page_layout}>
        <div className={styles.featured_section}>
          <p className={styles.featured_section_title}>Featured Integrations</p>
          <div className={styles.tile_layout}>
            {featured_integrations.map((integration, index) => (
              <FeaturedTile key={index} integration={integration} />
            ))}
          </div>
        </div>
        <div className={styles.tile_layout}>
          {integrations.map((integration, index) => (
            <Tile key={index} integration={integration} />
          ))}
        </div>
      </div>
    </div>
  );
}

function FeaturedTile({ key, integration }) {
  return (
    <div
      className={styles.tile}
      style={{
        position: "relative",
        backgroundColor: integration.color,
        backgroundImage: `url(${integration.featured_background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        border: "none",
        color: "white",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          borderRadius: "5px",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
          zIndex: 1, // Place the overlay above the background
        }}
      />
      <div className={styles.tile_header} style={{ zIndex: 2 }}>
        <div className={styles.tile_info}>
          <img
            className={styles.tile_image}
            src={integration.logo_image}
            style={{ backgroundColor: "white", border: "none" }}
          />
          <p className={styles.tile_title}>{integration.name}</p>
        </div>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body} style={{ zIndex: 2 }}>
        <p className={styles.tile_preview}>{integration.summary}</p>
      </div>
    </div>
  );
}

function Tile({ key, integration }) {
  return (
    <div className={styles.tile}>
      <div className={styles.tile_header}>
        <div className={styles.tile_info}>
          <img
            className={styles.tile_image}
            src={integration.logo_image}
            // style={{ backgroundColor: integration.color }}
          />
          <p className={styles.tile_title}>{integration.name}</p>
        </div>
        <More width={8} height={8} />
      </div>
      <div className={styles.tile_body}>
        <p className={styles.tile_preview}>{integration.summary}</p>
      </div>
    </div>
  );
}

const featured_integrations = [
  {
    id: "2",
    name: "Hubspot",
    type: "CRM",
    summary:
      "Connect HubSpot to your Archer account for automatic contact updates and streamlined CRM management.",
    logo_image: "/IntegrationsLogos/HubspotLogo.webp",
    featured_background: "/IntegrationsLogos/HubspotFeaturedBackground.avif",
    color: "rgba(245, 119, 34, 1)",
    connected: false,
  },
  {
    id: "2",
    name: "Zapier",
    type: "Workflow",
    summary:
      "Automate workflows by linking Archer with over 2,000 apps via Zapier. Save time and improve efficiency effortlessly.",
    logo_image: "/IntegrationsLogos/ZapierLogo.svg",
    featured_background: "/IntegrationsLogos/ZapierFeaturedBackground.avif",
    color: "rgba(255, 79, 0, 1)",
    connected: false,
  },
  {
    id: "1",
    name: "Slack",
    type: "Workflow",
    summary:
      "Streamline team communication by connecting Archer with Slack. Get instant updates, notifications, and alerts.",
    logo_image: "/IntegrationsLogos/SlackLogo.png",
    featured_background: "/IntegrationsLogos/SlackFeaturedBackground.jpg",
    color: "rgba(118, 16, 120, 1)",
    connected: false,
  },
  {
    id: "1",
    name: "AirTable",
    type: "Data",
    summary:
      "Connect Archer with Airtable to organize and manage data seamlessly. Sync and streamline workflows efficiently.",
    logo_image: "/IntegrationsLogos/AirtableLogo.png",
    featured_background: "/IntegrationsLogos/AirtableFeaturedBackground.jpeg",
    color: "rgba(27, 97, 201, 1)",
    connected: false,
  },
];

const integrations = [
  // {
  //   id: "1",
  //   name: "HubSpot",
  //   type: "CRM",
  //   summary:
  //     "Connect HubSpot to your Archer account for automatic contact updates and streamlined CRM management.",
  //   logo_image: "/IntegrationsLogos/HubSpotLogo.webp",
  //   color: "rgba(245, 119, 34, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "2",
  //   name: "Airtable",
  //   type: "Data",
  //   summary:
  //     "Connect Archer with Airtable to organize and manage data seamlessly. Sync and streamline workflows efficiently.",
  //   logo_image: "/IntegrationsLogos/AirtableLogo.png",
  //   color: "rgba(27, 97, 201, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "3",
  //   name: "Zapier",
  //   type: "Workflow",
  //   summary:
  //     "Automate workflows by linking Archer with over 2,000 apps via Zapier. Save time and improve efficiency effortlessly.",
  //   logo_image: "/IntegrationsLogos/ZapierLogo.svg",
  //   color: "rgba(255, 79, 0, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "4",
  //   name: "Slack",
  //   type: "Communication",
  //   summary:
  //     "Streamline team communication by connecting Archer with Slack. Get instant updates, notifications, and alerts.",
  //   logo_image: "/IntegrationsLogos/SlackLogo.png",
  //   color: "rgba(118, 16, 120, 0.1)",
  //   connected: false,
  // },
  {
    id: "5",
    name: "Zoom",
    type: "Meetings",
    summary:
      "Schedule and manage meetings directly from Archer with Zoom integration. Set reminders, send invites, and messages.",
    logo_image: "/IntegrationsLogos/ZoomLogo.png",
    color: "rgba(45, 140, 255, 0.1)",
    connected: false,
  },
  {
    id: "6",
    name: "Salesforce",
    type: "CRM",
    summary:
      "Integrate Salesforce with Archer for seamless synchronization of customer data, enhancing your CRM capabilities.",
    logo_image: "/IntegrationsLogos/SalesforceLogo.png",
    color: "rgba(0, 107, 255, 0.1)",
    connected: false,
  },
  {
    id: "7",
    name: "Trello",
    type: "Project Management",
    summary:
      "Connect Trello with Archer to manage tasks and projects efficiently. Sync boards, cards, and updates in real-time.",
    logo_image: "/IntegrationsLogos/TrelloLogo.png",
    color: "rgba(0, 121, 191, 0.1)",
    connected: false,
  },
  {
    id: "8",
    name: "Asana",
    type: "Project Management",
    summary:
      "Integrate Asana with Archer to streamline project workflows. Track tasks, deadlines, and team collaboration effectively.",
    logo_image: "/IntegrationsLogos/AsanaLogo.png",
    color: "rgba(234, 76, 137, 0.1)",
    connected: false,
  },
  {
    id: "9",
    name: "Google Drive",
    type: "Storage",
    summary:
      "Access and manage your Google Drive files directly from Archer. Sync documents, spreadsheets, and presentations seamlessly.",
    logo_image: "/IntegrationsLogos/GoogleDriveLogo.png",
    color: "rgba(66, 133, 244, 0.1)",
    connected: false,
  },
  {
    id: "10",
    name: "Dropbox",
    type: "Storage",
    summary:
      "Integrate Dropbox with Archer to store and share files effortlessly. Keep your documents organized and accessible.",
    logo_image: "/IntegrationsLogos/DropboxLogo.png",
    color: "rgba(0, 126, 229, 0.1)",
    connected: false,
  },
  {
    id: "11",
    name: "Microsoft Teams",
    type: "Communication",
    summary:
      "Connect Microsoft Teams with Archer for enhanced collaboration. Share updates, files, and communicate with your team.",
    logo_image: "/IntegrationsLogos/MicrosoftTeamsLogo.png",
    color: "rgba(70, 136, 241, 0.1)",
    connected: false,
  },
  {
    id: "13",
    name: "Google Calendar",
    type: "Scheduling",
    summary:
      "Sync your Google Calendar with Archer to manage events and meetings efficiently. Keep your schedule up-to-date.",
    logo_image: "/IntegrationsLogos/GoogleCalendarLogo.png",
    color: "rgba(66, 133, 244, 0.1)",
    connected: false,
  },
  {
    id: "14",
    name: "Mailchimp",
    type: "Marketing",
    summary:
      "Connect Mailchimp with Archer to automate email campaigns and manage subscriber lists effectively.",
    logo_image: "/IntegrationsLogos/MailchimpLogo.webp",
    color: "rgba(255, 196, 0, 0.1)",
    connected: false,
  },
  {
    id: "15",
    name: "Stripe",
    type: "Payments",
    summary:
      "Integrate Stripe with Archer to manage payments and transactions seamlessly. Track sales and revenue in real-time.",
    logo_image: "/IntegrationsLogos/StripeLogo.png",
    color: "rgba(102, 153, 255, 0.1)",
    connected: false,
  },
  {
    id: "16",
    name: "PayPal",
    type: "Payments",
    summary:
      "Connect PayPal with Archer for secure and efficient payment processing. Manage invoices and transactions easily.",
    logo_image: "/IntegrationsLogos/PayPalLogo.png",
    color: "rgba(0, 87, 255, 0.1)",
    connected: false,
  },
  // {
  //   id: "23",
  //   name: "Jira",
  //   type: "Project Management",
  //   summary:
  //     "Integrate Jira with Archer to track issues and manage projects effectively. Streamline your development process.",
  //   logo_image: "/IntegrationsLogos/JiraLogo.png",
  //   color: "rgba(0, 82, 204, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "24",
  //   name: "Confluence",
  //   type: "Collaboration",
  //   summary:
  //     "Connect Confluence with Archer to enhance team collaboration. Share documents and knowledge efficiently.",
  //   logo_image: "/IntegrationsLogos/ConfluenceLogo.png",
  //   color: "rgba(0, 82, 204, 0.1)",
  //   connected: false,
  // },

  // {
  //   id: "26",
  //   name: "Calendly",
  //   type: "Scheduling",
  //   summary:
  //     "Sync Calendly with Archer to automate meeting scheduling and reminders, keeping your calendar organized.",
  //   logo_image: "/IntegrationsLogos/CalendlyLogo.png",
  //   color: "rgba(0, 122, 255, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "27",
  //   name: "Intercom",
  //   type: "Customer Support",
  //   summary:
  //     "Connect Intercom with Archer to manage customer communications and improve support efficiency.",
  //   logo_image: "/IntegrationsLogos/IntercomLogo.png",
  //   color: "rgba(0, 153, 255, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "28",
  //   name: "Zendesk",
  //   type: "Customer Support",
  //   summary:
  //     "Integrate Zendesk with Archer to streamline customer service operations and ticket management.",
  //   logo_image: "/IntegrationsLogos/ZendeskLogo.png",
  //   color: "rgba(0, 127, 255, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "29",
  //   name: "Notion",
  //   type: "Productivity",
  //   summary:
  //     "Connect Notion with Archer to organize notes, tasks, and projects all in one place.",
  //   logo_image: "/IntegrationsLogos/NotionLogo.png",
  //   color: "rgba(0, 0, 0, 0.1)",
  //   connected: false,
  // },
  // {
  //   id: "30",
  //   name: "Evernote",
  //   type: "Productivity",
  //   summary:
  //     "Integrate Evernote with Archer to manage your notes and ideas efficiently across devices.",
  //   logo_image: "/IntegrationsLogos/EvernoteLogo.png",
  //   color: "rgba(0, 201, 87, 0.1)",
  //   connected: false,
  // },
];
