import React from "react";
import { useState } from "react";
import styles from "./styles.module.css";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import SliderHeaderMenu from "../../../Common/SliderHeaderMenu/SliderHeaderMenu";

export default function WhiteboardEditor() {
  const menuItems = ["Editor", "Access", "Settings"];
  const [activeTab, setActiveTab] = useState(menuItems[0]);

  const editor = useEditor({
    extensions: [StarterKit],
    content: `
      <div style="position: absolute; top: 10px; right: 10px; text-align: right; pointer-events: none;">
        <h1 style="font-size: 25px; font-weight: 300; margin: 0;">New Whiteboard!</h1>
        <h2 style="font-size: 16px; font-weight: 400; margin: 5px 0;">Your rich text editor</h2>
        <p style="font-size: 14px; color: #888; margin: 5px 0;">${new Date().toLocaleDateString()}</p>
      </div>
      <p>Start editing here...</p>
    `,
  });

  return (
    <div className={styles.main_layout}>
      <SliderHeaderMenu
        menuItems={menuItems}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <div className={styles.page_layout}>
        <MenuBar editor={editor} />
        <EditorContent editor={editor} />
      </div>
    </div>
  );
}

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  // Define common styles for buttons
  const buttonStyle = {
    padding: "8px 12px",
    margin: "0 4px",
    background: "#f0f0f0",
    border: "1px solid #ccc",
    borderRadius: "4px",
    cursor: "pointer",
    fontSize: "14px",
  };

  const activeButtonStyle = {
    ...buttonStyle,
    background: "#d0d0ff",
    fontWeight: "bold",
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    background: "#e0e0e0",
    cursor: "not-allowed",
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "10px" }}>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        style={
          editor.isActive("bold")
            ? activeButtonStyle
            : !editor.can().chain().focus().toggleBold().run()
            ? disabledButtonStyle
            : buttonStyle
        }
      >
        Bold
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        style={
          editor.isActive("italic")
            ? activeButtonStyle
            : !editor.can().chain().focus().toggleItalic().run()
            ? disabledButtonStyle
            : buttonStyle
        }
      >
        Italic
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        style={
          editor.isActive("strike")
            ? activeButtonStyle
            : !editor.can().chain().focus().toggleStrike().run()
            ? disabledButtonStyle
            : buttonStyle
        }
      >
        Strike
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        style={editor.isActive("paragraph") ? activeButtonStyle : buttonStyle}
      >
        Paragraph
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        style={editor.isActive("bulletList") ? activeButtonStyle : buttonStyle}
      >
        Bullet List
      </button>
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        style={
          !editor.can().chain().focus().undo().run()
            ? disabledButtonStyle
            : buttonStyle
        }
      >
        Undo
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        style={
          !editor.can().chain().focus().redo().run()
            ? disabledButtonStyle
            : buttonStyle
        }
      >
        Redo
      </button>
    </div>
  );
};
