import React from "react";

const FormsIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    id="fi_4887462"
    enable-background="new 0 0 511.527 511.527"
    width={width}
    height={height}
    viewBox="0 0 511.527 511.527"

    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m301.721 121.316c3.15-27.094-18.663-51.481-45.957-51.307-27.292-.174-49.111 24.214-45.957 51.307h-151.304v81.884l136.026 114.594v77.738h122.469v-77.738l136.026-114.594v-81.884zm-62.195-5.071c0-8.953 7.284-16.237 16.237-16.237 10.851-.23 18.966 11.178 15.438 21.308h-30.876c-.485-1.477-.799-3.167-.799-5.071zm183.498 35.071v29.907h-334.521v-29.907zm-136.026 152.524v61.691h-62.469v-61.691l-109.939-92.617h282.348z"></path>
      <path d="m255.763 419.29c-25.495 0-46.237 20.742-46.237 46.237 2.54 61.341 89.944 61.324 92.475 0 0-25.495-20.742-46.237-46.238-46.237zm0 62.476c-8.953 0-16.237-7.284-16.237-16.238.892-21.541 31.586-21.535 32.475 0 0 8.954-7.284 16.238-16.238 16.238z"></path>
      <path d="m145.376 92.236c25.495 0 46.237-20.742 46.237-46.237-2.54-61.34-89.944-61.322-92.475 0 0 25.494 20.743 46.237 46.238 46.237zm0-62.475c8.953 0 16.237 7.284 16.237 16.237-.892 21.541-31.586 21.535-32.475 0 0-8.953 7.285-16.237 16.238-16.237z"></path>
      <path d="m366.151 92.236c25.495 0 46.237-20.742 46.237-46.237-2.54-61.34-89.944-61.322-92.475 0 .001 25.494 20.743 46.237 46.238 46.237zm0-62.475c8.953 0 16.237 7.284 16.237 16.237-.892 21.541-31.586 21.535-32.475 0 .001-8.953 7.285-16.237 16.238-16.237z"></path>
    </g>
  </svg>
);

export default FormsIcon;
