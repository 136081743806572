import React from "react";
import styles from "./styles.module.css";
import { MultipleChoiceQuestion } from "./SlideTypes/SlideTypes";

export default function FunnelPreview() {
  return (
    <div className={styles.preview_display}>
      <div className={styles.funnel_preview_tile}>
        <MultipleChoiceQuestion />
      </div>
    </div>
  );
}

//.main_layout {
//     display: flex;
//     flex-direction: column;
//     box-sizing: border-box;
//     flex: 1;
//     height: 100%;
//     width: calc(100vw - 250px);
//}
