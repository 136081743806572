import { h } from "preact";
import React from "react";

const SocialMediaDatabaseIcon = ({
  width = 17,
  height = 17,
  color = "rgba(0, 0, 0, 1)",
  strokeWidth = 1.1,
  ...props
}) => (
  <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_15911446"
    width={width}
    height={height}
  >
    <g id="Layer">
      <path
        fill={color}
        d="m27 19h-7a3 3 0 0 0 -3 3v5a3 3 0 0 0 3 3h7a3 3 0 0 0 3-3v-5a3 3 0 0 0 -3-3zm1 8a1 1 0 0 1 -1 1h-7a1 1 0 0 1 -1-1v-5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1zm-13-5v3a3 3 0 0 1 -3 3h-5.7l-2.75 1.83a1 1 0 0 1 -1.55-.83v-7a3 3 0 0 1 3-3h7a3 3 0 0 1 3 3zm2-17a3 3 0 0 0 -3-3h-9a3 3 0 0 0 -3 3v9a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3zm-2 5.13-1.45-1a1 1 0 0 0 -1.1 0l-2.32 1.54-2.42-2.38a1 1 0 0 0 -1.31-.09l-2.4 1.8v-5a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1zm12-8.13h-5a3 3 0 0 0 -3 3v9a3 3 0 0 0 3 3h5a3 3 0 0 0 3-3v-9a3 3 0 0 0 -3-3zm-1 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2zm0-3.5h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2zm0-3.5h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2zm-.5 17.5a1 1 0 0 1 -.55.89l-2 1a1 1 0 0 1 -.45.11 1 1 0 0 1 -.53-.15 1 1 0 0 1 -.47-.85v-2a1 1 0 0 1 1.5-.89l2 1a1 1 0 0 1 .5.89z"
      ></path>
    </g>
  </svg>
);

export default SocialMediaDatabaseIcon;
