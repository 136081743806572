import React from "react";
import { useState, useContext } from "react";
import styles from "./popups.module.css";

import axios from "axios";

import SliderSelect from "../../../Common//SliderSelect/SliderSelect";

import { AuthContext } from "../../../../context/AuthContext";
import { PaymentContext } from "../../../../context/PaymentContext";

const UpgradePopUp = ({ isOpen, onClose, onProviderSelect, isLoading }) => {
  const modalClass = isOpen
    ? `${styles.fullscreen_modal} ${styles.open}`
    : styles.fullscreen_modal;

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const { fetchPaymentPortalLink, customerInformation, productPlans } =
    useContext(PaymentContext);

  const { profile } = useContext(AuthContext);

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [termsCheckbox, setTermsCheckbox] = useState(false);

  const [frequency, setFrequency] = useState("monthly");

  const frequency_options = [
    { key: "monthly", name: "Monthly", billed_per: 1 },
    { key: "6_month", name: "6 Month", billed_per: 6 },
    { key: "yearly", name: "Yearly", billed_per: 12 },
  ];

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  async function createSubscriptionCheckout(planId, priceId, accountLimits) {
    try {
      const response = await axios.post(
        "https://brandlink-24432.uc.r.appspot.com/payment/create-subscription-checkout-session",
        {
          priceId,
          planId,
          customerId: profile.stripe_id,
          userId: profile.uid,
          accountLimits: accountLimits,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        console.error("Error: URL not found in the response.");
      }
    } catch (error) {
      console.error(
        "Error creating subscription checkout session:",
        error.message
      );
    }
  }

  async function handleSelect(planId, priceId, accountLimits) {
    console.log(priceId);
    await createSubscriptionCheckout(planId, priceId, accountLimits);
  }

  return (
    <div className={modalClass} onClick={onClose}>
      <div className={styles.modal_body} onClick={stopPropagation}>
        {/* <div className={styles.modal_header}>
          <p className={styles.header_title}>Connect Your Mailbox</p>
          <p className={styles.header_subtitle}>
            Link your email with Brandlink to supercharge your marketing!
          </p>
        </div> */}
        <SliderSelect
          frequency={frequency}
          frequency_options={frequency_options}
          handleFrequencyChange={handleFrequencyChange}
        />
        <div className={styles.modal_content}>
          {productPlans.map((option) => {
            const price = option.prices.find(
              (price) => price.nickname === frequency
            );

            const billedAt = frequency_options.find(
              (option) => option.key === frequency
            )?.billed_per;

            const formattedPrice = price
              ? `$${(price.unit_amount / 100 / billedAt).toFixed(2)}`
              : "N/A";

            return (
              <div
                className={`${styles.subscription_option} ${
                  selectedProvider === option.id
                    ? styles.email_option_selected
                    : ""
                }`}
                key={option.id}
                onClick={() => setSelectedProvider(option.id)}
              >
                <div className={styles.data_sources}>
                  <img
                    alt="logo"
                    src="/SocialMediaLogos/instagram.png"
                    className={styles.data_icon}
                  ></img>
                  {option.name !== "Lite Plan" && (
                    <img
                      alt="logo"
                      src="/SocialMediaLogos/tiktok.png"
                      className={styles.data_icon}
                    ></img>
                  )}
                  {option.name !== "Lite Plan" && (
                    <img
                      alt="logo"
                      src="/SocialMediaLogos/youtube.png"
                      className={styles.youtube_icon}
                    ></img>
                  )}
                </div>
                <div className={styles.tile_text}>
                  <p className={styles.email_title}>{option.name}</p>
                  <p className={styles.email_subtitle}>{option.description}</p>
                  <p className={styles.contact_limit_text}>
                    {option.metadata.contact_search_limit} contacts per month
                  </p>
                </div>
                <p className={styles.price_title}>{formattedPrice}/month</p>
                <div
                  className={styles.select_button}
                  onClick={() =>
                    handleSelect(option.id, price.id, option.metadata)
                  }
                >
                  Upgrade
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className={styles.modal_bottom}>
          <div
            className={
              canLink
                ? styles.link_button
                : `${styles.v} ${styles.link_button_disabled}`
            }
            onClick={canLink ? () => onProviderSelect(selectedProvider) : null}
          >
            {isLoading ? (
              <CircularLoader size="30%" color="rgba(0, 88, 220, 1)" />
            ) : (
              <>
                <img
                  src="/BrandLinkLogos/BrandLinkLogoBugBlue.png"
                  className={styles.link_icon}
                  alt="Link mailbox"
                />
                Link Mailbox
              </>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default UpgradePopUp;
